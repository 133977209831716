import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AppContext } from "../App";
const Logo = styled(Link)`
	${(props) => props.theme.terminaHeavy};

	font-size: ${(props) => (props.size !== 0 && props.size !== false && props.size !== null ? `${props.size}px` : `50px`)};
	color: ${(props) => props.color && props.color};
	transition: ${(props) => props.theme.transition_default};
	transition-property: color;
`;

const LogoSpecies = ({ color, size }) => {
	const { nowYear } = useContext(AppContext);
	return (
		<Logo to={`/${nowYear}`} color={color ? color : "black"} size={size}>
			Species!
		</Logo>
	);
};

export default LogoSpecies;
