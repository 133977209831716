import React from "react";
import InputWrapper from "./InputWrapper";
import styled from "styled-components";

const Wrapper = styled(InputWrapper)`
	width: 100%;
	textarea {
		${(props) => props.theme.input_default};
		border: 1px solid ${(props) => props.theme.color.input.default};
		border-radius: ${(props) => props.theme.input_borderRatio};

		outline: none;
		width: 100%;
		padding: 0.6rem 0.6rem;
		position: relative;
		resize: none;
		height: calc(1.4 * 16px * 4 + 0.6rem * 2);

		&:focus {
			border-color: ${(props) => props.theme.color.input.active};
		}
	}
`;

const InputTextarea = ({ value, placeholder, label, onChange }) => {
	return (
		<Wrapper label={label}>
			<textarea value={value} placeholder={placeholder} onChange={onChange} />
		</Wrapper>
	);
};

export default InputTextarea;
