import React, { useEffect, useRef, useContext, useState } from "react";

import styled from "styled-components";
import { Search, SearchbarClose } from "./Icon";
import { device, size } from "../Styles/Media";
import { AppContext } from "../App";

const SearchBar = styled.div`
	border-radius: 100px;
	border: 2px solid white;
	width: 1222px;
	max-width: 90%;
	height: 100px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media ${device.laptopL} {
		width: 930px;
		height: 76px;
	}

	@media ${device.tablet} {
		margin: 0;
		width: initial;
		max-width: initial;
		height: 100%;
	}
`;

const SearchBarCol = styled.div`
	&:first-child {
		flex-grow: 1;
	}
	display: flex;
	height: 100%;
	align-items: center;
`;

const IconWrapper = styled.div`
	width: 110px;
	height: 50%;
	${(props) => props.theme.centerFlex};
	${(props) => props.border && `border-right: 1px solid white;`};

	@media ${device.laptopL} {
		width: 88px;
	}

	@media ${device.tablet} {
		width: ${(props) => `calc(${props.theme.height_header_tablet} / 2)`};
	}
`;
const SearchInput = styled.input.attrs(() => ({
	type: "text",
	placeholder: "검색"
}))`
	${(props) => props.theme.initInput};
	${(props) => props.theme.notoMedium};
	font-size: 2.5rem;
	padding-left: 0.8rem;
	color: white;

	flex-grow: 1;
	flex-shrink: 1;
	width: 100%;

	&::placeholder {
		color: #898989;
	}

	@media ${device.laptopL} {
		font-size: 25px;
		padding-left: 32px;
	}

	@media ${device.tablet} {
		font-size: 1.6rem;
		padding-left: 16px;
	}
`;

export default ({ value, onChange, setValue }) => {
	const input = useRef();

	const [iconSize, setIconSize] = useState();
	const { nowMedia } = useContext(AppContext);

	useEffect(() => {
		if (nowMedia && nowMedia <= size.laptopL) {
			setIconSize(28);
		} else {
			setIconSize(38);
		}
	}, [nowMedia]);
	useEffect(() => {
		input.current.focus();
	}, [input]);

	const onSearchCloseClick = () => {
		setValue("");
		input.current.focus();
	};
	return (
		<SearchBar>
			<SearchBarCol>
				<IconWrapper border={true}>
					<Search color={"white"} size={iconSize} />
				</IconWrapper>
				<SearchInput required id={"test"} ref={input} value={value} onChange={onChange} />
			</SearchBarCol>
			<SearchBarCol>
				<IconWrapper width={105}>
					<SearchbarClose size={iconSize} onClick={onSearchCloseClick} />
				</IconWrapper>
			</SearchBarCol>
		</SearchBar>
	);
};
