import React from "react";
import FullHeight from "../Components/FullHeight";
import styled from "styled-components";
import img_notfound from "Assets/image/img_notFound.png";
import { device } from "../Styles/Media";

const FullHeightInit = styled(FullHeight)`
	position: relative;
	z-index: ${(props) => props.theme.z_notfound};
	background-color: white;
	${(props) => props.theme.centerFlex};
	flex-direction: column;
	text-align: center;
`;
const Title = styled.h1`
	${(props) => props.theme.terminaBlack};
	font-size: 9rem;

	@media ${device.laptopL} {
		font-size: 5.375rem;
	}

	@media ${device.tablet} {
		font-size: 5rem;
	}
`;
const SubTitle = styled.h2`
	${(props) => props.theme.terminaLight};
	font-size: 2.5625rem;
	letter-spacing: 0.1rem;
	line-height: 1.2;
	margin-top: -1rem;

	@media ${device.laptopL} {
		font-size: 25px;
		margin-top: 0;
	}

	@media ${device.tablet} {
		font-size: 1.4rem;
		margin-top: 0;
	}
`;
const Text = styled.p`
	${(props) => props.theme.terminaLight};
	font-size: 1.25rem;
	margin-top: 3.125rem;
	margin-bottom: 3.625rem;

	@media ${device.laptopL} {
		font-size: 15px;
	}

	@media ${device.tablet} {
		font-size: 1rem;
		width: 80%;
		margin-left: auto;
		line-height: 1.6;
		margin-right: auto;
	}
`;
const NotfoundImage = styled.img`
	max-width: 80%;

	@media ${device.laptopL} {
		width: 100px;
	}
`;
export default () => {
	return (
		<FullHeightInit>
			<Title>404</Title>
			<SubTitle>NOT FOUNDED</SubTitle>
			<Text>The requested URL was no found on this server.</Text>
			<NotfoundImage src={img_notfound} />
		</FullHeightInit>
	);
};
