import React from "react";
import styled from "styled-components";
import { device } from "../Styles/Media";
import logo from "Assets/logo.png";

const Header = styled.div`
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+30 */
	background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */

	position: absolute;
	z-index: ${(props) => props.theme.z_Header};
	width: 100vw;
	padding: 86px 100px;
	left: 0;
	top: 0;
	display: flex;
	justify-content: space-between;
	${(props) => props.theme.preventEvent}

	.left {
		display: flex;
		align-self: flex-start;
		justify-content: center;
		align-items: flex-end;
	}

	/* media */
	@media ${device.laptopL} {
		${(props) => props.theme.activeEvent};
		padding: ${(props) => props.theme.offset_screen_labtapL};
	}
	@media ${device.tablet} {
		padding: ${(props) => props.theme.offset_screen_tablet};
	}
	@media ${device.mobileL} {
		padding: ${(props) => props.theme.offset_screen_mobileL};
	}
`;

const Logo = styled.div`
	position: fixed;
	width: ${(props) => props.theme.width_mainlogo_pc};
	top: 86px;
	left: 102px;

	img {
		width: 100%;

		@media ${device.laptopL} {
			transition: ${(props) => props.theme.transition_default};
			filter: ${(props) => props.isMenuClick && `invert(1)`};
		}
	}

	@media ${device.laptopL} {
		width: ${(props) => props.theme.width_mainlogo_laptopL};
	}

	@media ${device.tablet} {
		width: ${(props) => props.theme.width_mainlogo_tablet};
		left: ${(props) => props.theme.offset_screen_tablet};
		top: ${(props) => props.theme.offset_screen_tablet};
	}
	@media ${device.mobileL} {
		width: ${(props) => props.theme.width_mainlogo_mobileL};
		left: ${(props) => props.theme.offset_screen_mobileL};
		top: ${(props) => props.theme.offset_screen_mobileL};
	}
`;

const Info = styled.div`
	text-align: right;

	p {
		font-size: 1.125rem;
		&:first-child {
			${(props) => props.theme.notoExtraBold};
			line-height: 1.4;
			margin-bottom: 2rem;
		}

		&:last-child {
			${(props) => props.theme.notoKrDemiLight}
		}
	}

	@media ${device.laptopL} {
		p {
			transition: ${(props) => props.theme.transition_default};
			color: ${(props) => (props.isMenuClick ? `white` : `black`)};
		}
	}

	@media ${device.tablet} {
	}

	@media ${device.mobileL} {
		display: none;
	}
`;

const MainHeader = () => {
	return (
		<Header>
			<div className="left">
				<Logo>
					<img src={logo} alt="logo" />
				</Logo>
			</div>

			<Info>
				<p>
					Konkuk University
					<br />
					Industrial Design Graduation Show Archive
				</p>
				<p>건국대학교 산업디자인학과 졸업전시회 아카이브</p>
			</Info>
		</Header>
	);
};

export default MainHeader;
