import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { device } from "../Styles/Media";

const Cover = styled.div`
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	position: relative;
	margin-bottom: -80px;
	width: 100%;

	div {
		${(props) => props.theme.bgContain}
		${(props) => props.theme.fullAbs};
		transition: ${(props) => props.theme.transition_default};
	}

	a {
		display: block;

		position: absolute;
		top: 17%;
		left: 2%;
		bottom: 8%;
		width: 100%;

		@media ${device.tablet} {
			${(props) => props.theme.centerAbs}
			bottom: 0;
			width: 70%;
			height: calc(100% - 110px * 2);
		}
		@media ${device.mobileL} {
			height: calc(100% - 88px * 2);
		}
	}
`;

const CoverImage = ({ nowYearData, image }) => {
	const imageRef = useRef();
	const [load, setLoad] = useState(false);

	useEffect(() => {
		const src = image;
		const dom = document.createElement("img");
		dom.src = src;

		const setStateLoad = () => {
			setLoad(true);
		};
		dom.addEventListener("load", setStateLoad);
		return () => dom.removeEventListener("load", setStateLoad);
	}, [image, imageRef]);

	return (
		<Cover>
			<a href={nowYearData.link} target="_blank" rel="noopener noreferrer">
				<div
					ref={imageRef}
					id={"coverId"}
					style={{
						backgroundImage: `url('${image}')`,
						opacity: load ? 1 : 0
					}}
				></div>
			</a>
		</Cover>
	);
};

export default CoverImage;
