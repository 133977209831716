import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Instagram, LogoFooter } from "./Icon";
import { device } from "Styles/Media";
import { AppContext } from "../App";
import { size } from "../Styles/Media";

const Footer = styled.footer`
	width: 100%;
	height: 300px;
	background-color: black;

	@media ${device.laptopL} {
		height: 200px;
	}
	@media ${device.tablet} {
		height: inherit;
	}
`;

const Con = styled.div`
	${(props) => props.theme.offset_default};

	padding-bottom: 46px;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	@media ${device.laptopL} {
		padding-bottom: 46px;
	}

	@media ${device.tablet} {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding-top: ${(props) => props.theme.offset_screen_tablet};
		padding-bottom: ${(props) => props.theme.offset_screen_tablet};
	}

	@media ${device.mobileL} {
		padding-top: ${(props) => props.theme.offset_screen_mobileL};
		padding-bottom: ${(props) => props.theme.offset_screen_mobileL};
	}
`;

const FooterCol = styled.div`
	color: white;
	@media ${device.tablet} {
		width: 100%;
		&:first-child {
			margin-bottom: ${(props) => props.theme.offset_screen_tablet};
		}
	}

	@media ${device.mobileL} {
		&:first-child {
			margin-bottom: ${(props) => props.theme.offset_screen_tablet};
		}
	}
`;

const Contacts = styled.ul`
	@media ${device.tablet} {
		display: flex;
		width: 100%;
	}
`;
const Contact = styled.li`
	display: flex;
	flex-direction: row;
	align-items: center;
	${(props) => props.theme.terminaMedium};
	font-size: 1.12rem;
	margin-top: 1.25rem;
	color: white;
	> svg {
		margin-right: 1.125rem;
	}
	@media ${device.laptopL} {
		align-items: flex-start;
		font-size: 10px;
		margin-top: 18px;
		> svg {
			position: relative;
			top: -2px;
		}
	}
	@media ${device.tablet} {
		width: 50%;
		flex-direction: column;
		margin-top: 0;
		> svg {
			top: 0;
			margin-bottom: 1rem;
		}
		font-size: 1rem;
	}

	@media ${device.mobileL} {
	}
`;
const LinkWrapper = styled.div`
	> a {
		margin-right: 0.8rem;
	}

	@media ${device.laptopL} {
		display: flex;
		> a {
			line-height: 1.6;
		}
	}

	@media ${device.tablet} {
		flex-direction: column;
		a {
			word-break: break-all;
		}
	}
`;
const LogoWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	@media ${device.tablet} {
		flex-direction: row-reverse;
		justify-content: flex-end;
	}
`;

const Logo = styled(LogoFooter)`
	margin-left: 2.5rem;

	@media ${device.laptopL} {
		margin-left: 2rem;
	}

	@media ${device.tablet} {
		margin-left: 0;
		margin-right: 2rem;
	}
`;

const LogoText = styled.p`
	text-align: right;
	${(props) => props.theme.terminaMedium};
	font-size: 1.125rem;
	line-height: 1.7;
	span {
		display: block;
	}
	@media ${device.laptopL} {
		font-size: 10px;
	}
	@media ${device.tablet} {
		text-align: left;
		font-size: 0.8rem;
	}
`;
const Copyright = styled.p`
	${(props) => props.theme.terminaLight};
	margin-top: 2.18rem;
	font-size: 1rem;
	text-align: right;
	line-height: 1.6;
	@media ${device.laptopL} {
		font-size: 10px;
	}

	@media ${device.tablet} {
		text-align: left;
		margin-top: ${(props) => props.theme.offset_screen_tablet};
		font-size: 0.8rem;
	}
	@media ${device.mobileL} {
		margin-top: ${(props) => props.theme.offset_screen_tablet};
	}
`;

export default () => {
	const { nowMedia } = useContext(AppContext);
	const [iconSize, setIconSize] = useState();
	const [logoSize, setLogoSize] = useState(73);

	useEffect(() => {
		if (nowMedia && nowMedia <= size.laptopL) {
			setLogoSize(36);
		} else {
			setLogoSize(73);
		}
	}, [nowMedia]);

	useEffect(() => {
		if (nowMedia && nowMedia <= size.laptopL) {
			setIconSize(20);
		} else {
			setIconSize(38);
		}
	}, [nowMedia]);

	return (
		<Footer>
			<Con>
				<FooterCol>
					<Contacts>
						<Contact>
							<Instagram color={"white"} size={iconSize} />
							<LinkWrapper>
								<a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/kuid_graduation/">
									@kuid_graduation
								</a>
							</LinkWrapper>
						</Contact>
					</Contacts>
				</FooterCol>
				<FooterCol>
					<LogoWrapper>
						<LogoText>
							<span>Konkuk University</span>
							<span>Industrial Design</span>
							<span>Graduation show 2020</span>
						</LogoText>
						<Logo color={"white"} size={logoSize} />
					</LogoWrapper>
					<Copyright>©2020 Preparatory Committee of Konkuk University Student’s Graduation All rights are reserved.</Copyright>
				</FooterCol>
			</Con>
		</Footer>
	);
};
