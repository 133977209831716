import project from './project';
import profile from './profile';
import gnb from './gnb';

const getProjectWithUser = () => {
  const mapData = project.map((project) => {
    return {
      ...project,
      users: Array.isArray(project.users) ? project.users.map((id) => {
        return profile.find((el) => el.id === id);
      }) : profile.find(el => el.id === project.users)
    };
  });
  return mapData;
}

export const getProjectByLab = (lab) => {
  const filterData = project.filter((project) => project.lab === lab);
  const mapData = filterData.map((project) => {
    return {
      ...project,
      users: Array.isArray(project.users) ? project.users.map((id) => {
        return profile.find((el) => el.id === id);
      }) : profile.find((el) => el.id === project.users)
    };
  });
  return mapData;
}

export const getProfile = () => {
  return profile.map((p) => {
    return {
      ...p,
      project: Array.isArray(p.project) ? p.project.map((id) => project.find((el) => el.id === id)) : project.find(el => el.id === p.project)
    };
  })
}

export const searchProject = (term) => {
  const data = getProjectWithUser();

  if (!term) return data

  const termLowercase = term.toLowerCase();

  // const filter_projectName = data.filter(el => el.name.toLowerCase().includes(termLowercase));
  const filter_projectName = data.filter(el => el.name.toLowerCase().includes(termLowercase) ||
    el.users.some(user => user.name_kr.toLowerCase().includes(termLowercase) || user.name_en.toLowerCase().includes(termLowercase))
  );

  return filter_projectName;
}


export const searchProfile = (term) => {
  const data = getProfile();

  if (!term) return data

  const termLowercase = term.toLowerCase();

  const filter_profile = data.filter(el => el.name_kr.toLowerCase().includes(termLowercase) ||
    el.name_en.toLowerCase().includes(termLowercase)
  );

  return filter_profile;
}

export const getProjectById = (id) => {

  const find = project.find(el => el.id === id)
  if (!find) return false;

  const result = {
    ...find,
    users: find.users.map((id) => profile.find((el) => el.id === id))
  }


  if (result) {
    return result
  } else {
    return false;
  }
}


export const getProfileById = (id) => {
  const find = profile.find(el => el.id === id)
  if (!find) return false;

  const result = {
    ...find,
    project: Array.isArray(find.project) ? find.project.map((id) => project.find((el) => el.id === id)) : [find.project]
  }


  if (result) {
    return result
  } else {
    return false;
  }
}

export {
  project,
  profile,
  gnb,
};