import React, { useContext, useState, useRef } from "react";
import { AppContext } from "../App";
import styled from "styled-components";
// FILED
import { species } from "Data/exhibition";
import MarqueeAnimation from "../Components/MarqueeAnimation";
import { PosterUp } from "../Components/Icon";
import { device, size } from "../Styles/Media";
import FullHeight from "../Components/FullHeight";
import { useImageLoad } from "../Hooks/hooks";

// STYLED
const FullHeightInst = styled(FullHeight)`
	width: 100%;
	top: 0;
	position: relative;
`;

const Wrapper = styled.section`
	position: absolute;
	width: 100%;
	bottom: 0;
	height: ${(props) => `calc(100% - ${props.theme.height_header_pc})`};
	@media ${device.laptopL} {
		height: ${(props) => `calc(100% - ${props.theme.height_header_laptopL})`};
	}
	@media ${device.tablet} {
		height: ${(props) => `calc(100% - ${props.theme.height_header_tablet})`};
	}
	@media ${device.mobileL} {
		height: ${(props) => `calc(100% - ${props.theme.height_header_mobileL})`};
	}
`;

const Background = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	bottom: ${(props) => props.theme.height_posterUp_default};
	width: 100%;
	overflow: hidden;
	transition: ${(props) => props.theme.transition_default};
	opacity: ${(props) => (props.active ? 1 : 0)};

	@media ${device.tablet} {
		bottom: ${(props) => props.theme.height_posterUp_tablet};
	}
	@media ${device.mobileL} {
		bottom: ${(props) => props.theme.height_posterUp_mobileL};
	}
`;

const DescriptWrapper = styled.div`
	position: relative;
	height: ${(props) => `calc(100% - ${props.theme.height_posterUp_default})`};
	@media ${device.laptopL} {
		height: ${(props) => `calc(100% - ${props.theme.height_posterUp_laptopL})`};
	}
	@media ${device.tablet} {
		height: ${(props) => `calc(100% - ${props.theme.height_posterUp_tablet})`};
	}
	@media ${device.mobileL} {
		height: ${(props) => `calc(100% - ${props.theme.height_posterUp_mobileL})`};
	}
`;

const DescriptInner = styled.div`
	position: absolute;
	top: 8%;
	left: 50px;
	width: 518px;
	bottom: 4%;
	background-color: white;
	padding: 40px 50px;
	${(props) => props.theme.centerFlex};
	align-items: flex-start;
	overflow: scroll;
	overflow-x: hidden;

	/* width */
	&::-webkit-scrollbar {
		width: 32px;

		@media ${device.laptopL} {
			width: 16px;
		}
	}
	/* Track */
	&::-webkit-scrollbar-track {
		background: white;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		border: 10px solid white;
		border-radius: 100px;
		background: black;
		@media ${device.laptopL} {
			border: 5px solid white;
		}
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	@media ${device.laptopL} {
		left: ${(props) => props.theme.offset_screen_laptopL};
		top: 23%;
		bottom: 6%;
		width: 293px;
		padding: 28px 24px;
	}

	@media ${device.tablet} {
		width: ${(props) => `calc(100% - ${props.theme.offset_screen_tablet} * 2)`};
		height: ${(props) => `calc(100% - ${props.theme.offset_screen_tablet} )`};
		top: 0;
		left: ${(props) => props.theme.offset_screen_tablet};
		background-color: inherit;
		backdrop-filter: blur(30px);
		padding: ${(props) => `${props.theme.offset_screen_tablet}`};

		/* width */
		&::-webkit-scrollbar {
			width: 12px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			border: none;
			border-radius: 100px;
			background: black;
		}
	}

	@media ${device.mobileL} {
		width: ${(props) => `calc(100% - ${props.theme.offset_screen_mobileL} * 2)`};
		left: ${(props) => props.theme.offset_screen_mobileL};
		height: ${(props) => `calc(100% - ${props.theme.offset_screen_mobileL})`};
		padding: ${(props) => `${props.theme.offset_screen_mobileL}`};
	}
`;

const Descript = styled.div`
	.h2 {
		${(props) => props.theme.notoKrBold};
		font-size: 24px;
		margin-bottom: 3.6rem;

		@media ${device.laptopL} {
			font-size: 14px;
		}

		@media ${device.tablet} {
			font-size: 16px;
		}
	}
	.title {
		${(props) => props.theme.notoKrBlack};
		font-size: 18px;
		line-height: 2;
		margin-bottom: 3rem;

		@media ${device.laptopL} {
			font-size: 10px;
		}
		@media ${device.tablet} {
			font-size: 15px;
		}
	}
	.text {
		${(props) => props.theme.notoKrMedium};
		font-size: 18px;
		line-height: 2;

		@media ${device.laptopL} {
			font-size: 10px;
		}
		@media ${device.tablet} {
			font-size: 15px;
		}
	}
`;

// posters
const PosterWrapper = styled.div`
	background-color: black;
	transition: ${(props) => props.theme.transition_default};
	transition-duration: 0.6s;
	position: absolute;
	z-index: ${(props) => props.theme.z_poster};
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	height: ${(props) => (props.active ? `calc(100% - ${props.theme.height_header_pc})` : props.theme.height_posterUp_default)};

	@media ${device.laptopL} {
		height: ${(props) => (props.active ? `calc(100% - ${props.theme.height_header_laptopL})` : props.theme.height_posterUp_laptopL)};
	}

	@media ${device.tablet} {
		height: ${(props) => (props.active ? `calc(100%)` : props.theme.height_posterUp_tablet)};
	}
	@media ${device.mobileL} {
		height: ${(props) => (props.active ? `calc(100%)` : props.theme.height_posterUp_mobileL)};
	}
`;

const PosterBtn = styled.div`
	position: absolute;
	top: 0;
	background-color: black;
	height: ${(props) => props.theme.height_posterUp_default};
	width: 100%;
	padding-top: 26px;
	display: flex;
	justify-content: center;

	svg {
		cursor: pointer;
		transform: ${(props) => props.active && `rotate(180deg);`};
	}

	@media ${device.laptopL} {
		height: ${(props) => props.theme.height_posterUp_laptopL};
	}
	@media ${device.tablet} {
		padding: 0;
		${(props) => props.theme.centerFlex};
		height: ${(props) => props.theme.height_posterUp_tablet};
	}
	@media ${device.mobileL} {
		height: ${(props) => props.theme.height_posterUp_mobileL};
	}
`;
const Posters = styled.div`
	width: 100%;
	transition: ${(props) => props.theme.transition_default};
	display: flex;
	overflow: hidden;
	position: relative;
	height: ${(props) => `calc(100% - ${props.theme.height_posterUp_default})`};

	@media ${device.tablet} {
		display: block;
		overflow: scroll;
		> img {
			width: 50%;
		}
		height: ${(props) => `calc(100% - ${props.theme.height_posterUp_tablet})`};
	}

	@media ${device.mobileL} {
		> img {
			width: 100%;
		}
		height: ${(props) => `calc(100% - ${props.theme.height_posterUp_mobileL})`};
	}
`;

const Poster = styled.div`
	transform: translateY(-50%);
	top: 50%;
	left: ${(props) => `${(100 / props.count) * props.index}%`};
	position: absolute;
	height: ${(props) => `calc(100vh - ${props.theme.height_header_pc} - 200px)`};
	width: ${(props) => `${100 / props.count}%`};
	background-image: ${(props) => `url('${props.src}');`};
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center 0;
`;

export default () => {
	const { nowMedia } = useContext(AppContext);
	const scrollel = useRef();
	const [isBtnClick, setIsBtnClick] = useState(false);
	const { load } = useImageLoad(species.cover_exhibition);

	const onBtnClick = () => {
		setIsBtnClick((n) => !n);
	};

	return (
		<FullHeightInst>
			<Wrapper>
				{species && (
					<>
						<Background active={load}>{species && <MarqueeAnimation src={species.cover_exhibition} />}</Background>
						<DescriptWrapper>
							<DescriptInner>
								<Descript ref={scrollel}>
									{species && (
										<>
											<h2 className="h2">전시 소개</h2>
											<h3 className="title">{species.text_exhibition.title}</h3>
											<p className="text">{species.text_exhibition.text}</p>
										</>
									)}
								</Descript>
							</DescriptInner>
						</DescriptWrapper>
					</>
				)}
			</Wrapper>

			<PosterWrapper active={isBtnClick}>
				<PosterBtn active={isBtnClick}>
					<PosterUp size={nowMedia <= size.laptopL && nowMedia ? 24 : 30} onClick={onBtnClick} color="white" />
				</PosterBtn>
				<Posters>
					{!nowMedia || nowMedia > size.tablet
						? species &&
						  species.posters.map((el, index) => {
								return <Poster index={index} src={el} key={index} count={species.posters.length} />;
						  })
						: species &&
						  species.posters.map((el, index) => {
								return <img alt={`poster ${index + 1}`} src={el} key={index} />;
						  })}
				</Posters>
			</PosterWrapper>
		</FullHeightInst>
	);
};
