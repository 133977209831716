import React from "react";
import styled from "styled-components";
import Ionicons from "../Assets/icon/Ionicons";
const Wrapper = styled.div`
	width: calc(50% - 8px);

	border: 1px solid ${(props) => props.theme.color.input.default};
	border-radius: ${(props) => props.theme.input_borderRatio};
	margin-bottom: 16px;
	position: relative;

	&:hover {
		border-color: ${(props) => props.theme.color.input.active};
	}
`;

const Inner = styled.div`
	padding: 12px;
	cursor: pointer;
`;

const Title = styled.p`
	font-weight: 700;
`;
const SubTitle = styled.p`
	font-weight: 400;
	margin-top: 6px;
`;
const Address = styled.p`
	font-weight: 400;
	margin-top: 12px;
	line-height: 1.5;
	color: ${(props) => props.theme.color.input.default};
`;

const BtnDelete = styled(Ionicons).attrs({ color: "lightgray" })`
	position: absolute;
	top: 6px;
	right: 6px;
	cursor: pointer;
	&:hover {
		background-color: ${(props) => props.theme.color.dim};
		border-radius: 100%;
	}
`;

const LabList = ({ className, lab, onClick, onDeleteClick }) => {
	const handleClick = () => {
		onClick();
	};

	const handleDelteClick = () => {
		onDeleteClick();
	};

	return (
		<Wrapper className={className}>
			<BtnDelete name="close" onClick={handleDelteClick} />
			<Inner onClick={handleClick}>
				<Title>{lab.title}</Title>
				<SubTitle>{lab.subtitle}</SubTitle>
				<Address>{lab.address}</Address>
			</Inner>
		</Wrapper>
	);
};

export default LabList;
