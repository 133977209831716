import React, { useState, useContext, useEffect } from "react";
import MainPresentor from "./MainPresentor";
import { scrollToTarget } from "Utility";
import { AppContext } from "App";
import { size as mediaSize } from "Styles/Media";
// HOOKS
import { useScrollDirection } from "Hooks/hooks";

const MainContainer = () => {
	const { dir, value: scrollValue } = useScrollDirection();
	const [hideYear, setHideYear] = useState(false);

	const { nowMedia, isPortrait, data } = useContext(AppContext);
	const [year, setYear] = useState();
	const [nowYearData, setNowYearData] = useState();

	useEffect(() => {
		if (data) {
			setNowYearData(data[0]);
			setYear(data.reverse());
		}
	}, [data]);

	useEffect(() => {
		setHideYear(scrollValue > window.innerHeight / 2 && nowMedia <= mediaSize.tablet);
	}, [nowMedia, dir, scrollValue]);

	const onYearClick = (y) => {
		setNowYearData(y);
		scrollToTarget("body", "auto");
	};

	const onDownClick = () => {
		const gap = () => {
			if (nowMedia) {
				if (nowMedia <= mediaSize.mobileL) {
					return 120;
				} else if (nowMedia <= mediaSize.tablet) {
					return 200;
				} else if (nowMedia <= mediaSize.laptopL) {
					return 208;
				}
			} else {
				return 315;
			}
		};
		scrollToTarget("#description", "smooth", gap());
	};
	return <MainPresentor nowYearData={nowYearData} years={year} isPortrait={isPortrait} onYearClick={onYearClick} onDownClick={onDownClick} hideYear={hideYear} />;
};

export default MainContainer;
