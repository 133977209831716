import React from "react";

import styled from "styled-components";
import { device } from "../Styles/Media";
const ListTitle = styled.div`
	margin-top: 0.75rem;
	padding-right: 1rem;
	.title {
		${(props) => props.theme.notoBold};
		font-size: 1.25rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1.74;
	}

	@media ${device.laptopL} {
		margin-top: 6px;
		.title {
			font-size: 14px;
		}
	}
`;
export default ({ text }) => {
	return (
		<ListTitle>
			<p className="title">{text}</p>
		</ListTitle>
	);
};
