import React from "react";
import styled from "styled-components";

const FocusLine = styled.div`
	width: 0%;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 2px;
	background-color: black;
	transition: ${(props) => props.theme.transition_default};
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	position: relative;

	input[type="text"],
	input[type="email"],
	input[type="password"] {
		&:focus {
			~ ${FocusLine} {
				width: 100%;
			}
		}
	}
`;

const Label = styled.p`
	margin-bottom: 0.5rem;
	font-size: 14px;
	font-style: italic;
`;

const InputWrapper = ({ children, label, className }) => {
	return (
		<Wrapper className={className}>
			<label>
				<Label>{label}</Label>
				{children}
				<FocusLine />
			</label>
		</Wrapper>
	);
};

export default InputWrapper;
