import {
  createGlobalStyle
} from "styled-components";

import reset from "styled-reset";
import {
  device
} from "./Media";

export default createGlobalStyle `
  ${reset}
  /* RESET */

  *{
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    img{
      vertical-align: top;
    }
  }
  a{
    color: inherit;
  }
  html{
    font-size: 16px;
    font-family: noto-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
    @media ${device.tablet} {
      font-size: 12px;
    }
  }

  html, body{
    ${props => props.theme.hideScroll};
    width: 100vw;
    position: relative;
    overflow-x: hidden;
  }

`;