import React, { useState, useEffect, useContext } from "react";
import ProfilePresenter from "./ProfilePresenter";
import profile from "Data/profile";
import { useHistory } from "react-router-dom";
import { getProfile } from "../../Data";
import { AppContext } from "App";
import { scrollToTop } from "../../Utility";
export default () => {
	const [profiles, setProfiles] = useState(profile);
	const usehistory = useHistory();
	const { nowYear } = useContext(AppContext);

	useEffect(() => {
		setProfiles(getProfile());
	}, []);

	useEffect(() => {
		scrollToTop();
	}, []);

	const onProfileClciked = (id) => {
		usehistory.push(`/${nowYear}/profile/${id}`);
	};

	return <ProfilePresenter data={profiles} onProfileClciked={onProfileClciked} />;
};
