import React from "react";
import styled from "styled-components";
import ProjectList from "../../Components/ProjectList";
import ListContainerDefulat from "../../Components/ListContainerDefulat";
import ProjectDetail from "../../Components/ProjectDetail";
import FullHeight from "../../Components/FullHeight";
import { device } from "../../Styles/Media";

// FIELD
const Project = styled(FullHeight)`
	padding-top: ${(props) => props.theme.height_header_pc};
	@media ${device.laptopL} {
		padding-top: ${(props) => props.theme.height_header_laptopL};
	}
	@media ${device.tablet} {
		padding-top: ${(props) => props.theme.height_header_tablet};
	}
	@media ${device.mobileL} {
		padding-top: ${(props) => props.theme.height_header_mobileL};
	}
`;
const Wrapper = styled(ListContainerDefulat)``;
const ProjectWrapper = styled.ul`
	${(props) => props.theme.offset_default};
	flex-wrap: wrap;
	display: flex;
`;

export default ({ data, nowProject, onProjectClicked, setNowProject }) => {
	return (
		<Project>
			<Wrapper>
				<ProjectWrapper>
					{data &&
						data.map((project) => {
							return <ProjectList key={project.id} project={project} onClick={() => onProjectClicked(project)} />;
						})}
				</ProjectWrapper>

				{nowProject && <ProjectDetail data={nowProject} setNowProject={setNowProject} />}
			</Wrapper>
		</Project>
	);
};
