import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
// COMPONENT
import { Search, Menu, Close } from "./Icon";
import { device, size } from "../Styles/Media";
import { AppContext } from "../App";
import LogoSpecies from "./LogoSpecies";
import Gnb from "./Gnb";
import { preventBodyScroll, defaultBodyScroll } from "../Utility";

const WrapHeader = styled.header`
	position: fixed;
	width: 100%;
	background-color: #fff;
	left: 0;
	top: 0;
	padding: 0 100px;
	height: ${(props) => props.theme.height_header_pc};
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: ${(props) => props.theme.z_Header};

	@media ${device.laptopL} {
		height: ${(props) => props.theme.height_header_laptopL};
		padding: ${(props) => `0 ${props.theme.offset_screen_laptopL}`};
	}

	@media ${device.tablet} {
		height: ${(props) => props.theme.height_header_tablet};
		padding: ${(props) => `0 ${props.theme.offset_screen_tablet}`};
	}

	@media ${device.mobileL} {
		height: ${(props) => props.theme.height_header_mobileL};
		padding: ${(props) => `0 ${props.theme.offset_screen_mobileL}`};
	}
`;
const HeaderLeft = styled.div`
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	height: 100%;
	z-index: 10;

	.wrap-logo {
		height: 60px;
		flex-grow: 0;
		flex-shrink: 0;
		img {
			height: 100%;
		}
	}

	@media ${device.laptopL} {
		margin-right: ${(props) => props.theme.offset_screen_labtapL};
		.wrap-logo {
			height: 40px;
		}
	}
`;

const WrapperLogo = styled.div``;

const GnbWrapper = styled.div`
	${(props) => props.theme.hideScroll}
	flex-grow: 1;
	flex-shrink: 1;
	position: relative;
	margin-left: 8.75rem;
	height: 100%;
	overflow: scroll;
	@media ${device.laptopL} {
		margin-left: ${(props) => props.theme.offset_screen_laptopL};
	}
	@media ${device.tablet} {
		display: none;
	}
`;

const HeaderRight = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	z-index: 5;

	svg {
		cursor: pointer;
	}

	@media ${device.laptopL} {
		> svg,
		> div {
			margin-left: ${(props) => props.theme.offset_screen_labtapL};
			path {
				transition: ${(props) => props.theme.transition_default};
			}
		}
	}
	@media ${device.laptopL} {
		> svg,
		> div,
		> div svg {
			width: 24px;
			height: 24px;
			margin-left: ${(props) => props.theme.offset_screen_mobileL};
			&:first-child {
				margin-left: 0;
			}
		}
	}
`;

const MenuBtn = styled.div`
	display: none;
	position: relative;
	@media ${device.tablet} {
		display: inline-block;
		.btn-menu {
			opacity: ${(props) => (props.isMenuClick ? 0 : 1)};
		}
		.btn-close {
			position: absolute;
			right: 0;
			top: 0;
			opacity: ${(props) => (props.isMenuClick ? 1 : 0)};
		}
	}
`;

const MenuSlide = styled.div`
	display: none;

	@media ${device.tablet} {
		display: block;
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: black;
		left: 0;
		top: 0;
		transition: ${(props) => props.theme.transition_default};
		transform: ${(props) => (props.show ? `translateX(0%)` : `translateX(100%)`)};
	}
`;

const Header = () => {
	const { nowMedia, setIsSearchClick } = useContext(AppContext);
	const [isMenuClick, setIsMenuClick] = useState(false);
	const [logoSize, setLogoSize] = useState();

	const { listen } = useHistory();

	useEffect(() => {
		if (isMenuClick) {
			preventBodyScroll();
		} else {
			defaultBodyScroll();
		}
	}, [isMenuClick]);

	useEffect(() => {
		listen(() => {
			setIsMenuClick(false);
		});
	}, [listen]);

	useEffect(() => {
		if (nowMedia >= size.tablet) {
			setIsMenuClick(false);
		}
	}, [nowMedia]);

	const onMenuClick = () => {
		setIsMenuClick((n) => !n);
	};

	useEffect(() => {
		if (nowMedia === size.laptopL) {
			setLogoSize(32);
		} else if (nowMedia === size.tablet) {
			setLogoSize(28);
		} else if (nowMedia === size.mobileL) {
			setLogoSize(20);
		} else {
			setLogoSize(50);
		}
	}, [nowMedia]);

	const onSearchClick = () => {
		setIsSearchClick(true);
	};

	return (
		<WrapHeader>
			<HeaderLeft>
				<WrapperLogo>
					<LogoSpecies color={isMenuClick && "white"} size={logoSize} />
				</WrapperLogo>
				<GnbWrapper>
					<Gnb />
				</GnbWrapper>
			</HeaderLeft>

			<HeaderRight>
				<Search onClick={onSearchClick} color={isMenuClick && "white"} />
				<MenuBtn isMenuClick={isMenuClick}>
					<Menu className="btn-menu" onClick={onMenuClick} color={isMenuClick && "white"} />
					<Close className="btn-close" onClick={onMenuClick} color={isMenuClick && "white"} />
				</MenuBtn>
			</HeaderRight>

			<MenuSlide show={isMenuClick && true}>
				<Gnb />
			</MenuSlide>
		</WrapHeader>
	);
};

export default Header;
