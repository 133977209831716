import React from "react";
import styled from "styled-components";
import { device } from "../Styles/Media";

const ListDefault = styled.li`
	${(props) => props.theme.width_list};
	margin-bottom: 28px;
	position: relative;
	cursor: pointer;

	margin-right: ${(props) => props.theme.list_gap.default};

	opacity: ${(props) => (props.active ? 1 : 0)};
	transition: ${(props) => props.theme.transition_default};

	&:nth-child(4n) {
		margin-right: 0;
	}
	@media ${device.laptopL} {
		margin-right: ${(props) => props.theme.list_gap.laptopL};
	}

	@media ${device.tablet} {
		margin-right: ${(props) => props.theme.list_gap.tablet};
		&:nth-child(2n) {
			margin-right: 0;
		}
	}

	@media ${device.mobileL} {
		margin-right: 0;
		&:nth-child(2n) {
			margin-right: 0px;
		}
	}
`;

export default ({ children, className, onClick, active }) => (
	<ListDefault onClick={onClick} className={className} active={active}>
		{children}
	</ListDefault>
);
