export const scrollToTarget = (dom, type, gap, parent) => {
	if (!type) type = "smooth";

	const targetId = document.querySelector(`${dom}`);
	const targetY = gap ? targetId.offsetTop - gap : targetId.offsetTop;

	if (!parent) {
		window.scrollTo({
			top: targetY,
			behavior: type
		});
	} else {
		const parentId = document.querySelector(`${parent}`);
		parentId.scrollTo({
			top: targetY,
			behavior: type
		});
	}

};

export const scrollToTop = () => {
	scrollToTarget("#root", "auto", 0);
}

export const isTouchDevice = () => {
	return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

export const checkDevice = () => {
	var filter = "win16|win32|win64|mac|macintel";

	var vWebType = "";

	if (navigator.platform) {
		if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
			vWebType = "MOBILE";
		} else {
			vWebType = "PC";
		}
	}
	return vWebType;
};

export const makeFullHeight = () => {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);

	// change 100vh browser height change

	if (checkDevice() === "PC") {
		window.addEventListener("resize", () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		});
	}
};

export const preventBodyScroll = () => {
	document.body.style.top = `-${window.scrollY}px`;
	document.body.style.position = 'fixed';

	// When the modal is hidden, we want to remain at the top of the scroll position
}

export const defaultBodyScroll = () => {
	const scrollY = document.body.style.top;
	document.body.style.position = '';
	document.body.style.top = '';
	window.scrollTo(0, parseInt(scrollY || '0') * -1);
}

export const checkBrowser = () => {
	var word;
	var versionOrType = "another";
	var ieName = navigator.appName;
	var agent = navigator.userAgent.toLowerCase();

	/*** 1. IE 버전 체크 ***/
	// IE old version ( IE 10 or Lower )
	if (ieName === "Microsoft Internet Explorer") {
		word = "msie ";
	} else {
		// IE 11
		if (agent.search("trident") > -1) word = "trident/.*rv:";
		// IE 12  ( Microsoft Edge )
		else if (agent.search("edge/") > -1) word = "edge/";
	}

	var reg = new RegExp(word + "([0-9]{1,})(\\.{0,}[0-9]{0,1})");
	if (reg.exec(agent) !== null)
		versionOrType = RegExp.$1 + RegExp.$2;

	/*** 2. IE가 아닌 경우 브라우저 체크 ***/
	if (versionOrType === "another") {
		if (agent.indexOf("chrome") !== -1) versionOrType = "Chrome";
		else if (agent.indexOf("opera") !== -1) versionOrType = "Opera";
		else if (agent.indexOf("firefox") !== -1) versionOrType = "Firefox";
		else if (agent.indexOf("safari") !== -1) versionOrType = "Safari";
	}
	return versionOrType;
};

export const sortByOrder = (a, b) => {
	if (a.order < b.order) {
		return -1;
	}
	if (a.order > b.order) {
		return 1;
	}
	return 0;
}