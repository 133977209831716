import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";
import styled from "styled-components";

const Wrapper = styled.div`
	width: 100%;
	${(props) => props.theme.afterRatio};
	&:after {
		padding-top: ${(props) => `${props.ratio * 100}%`};
	}
	position: relative;
`;
const VideoWrapper = styled(YouTube)`
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
`;
const YoutubeVideo = ({ videoId }) => {
	const [ratio, setRatio] = useState(0);

	useEffect(() => {
		const getRatio = async () => {
			const res = await fetch(`https://noembed.com/embed?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D${videoId}`);
			const data = await res.json();

			const { width, height } = data;
			setRatio(height / width);
		};
		getRatio();
	}, [videoId]);
	return (
		<Wrapper ratio={ratio}>
			<VideoWrapper
				videoId={videoId} // defaults -> null
				// id={string}                       // defaults -> null
				// className={string}                // defaults -> null
				// containerClassName={string}       // defaults -> ''
				// opts={obj}                        // defaults -> {}
				// onReady={func}                    // defaults -> noop
				// onPlay={func}                     // defaults -> noop
				// onPause={func}                    // defaults -> noop
				// onEnd={func}                      // defaults -> noop
				// onError={func}                    // defaults -> noop
				// onStateChange={func}              // defaults -> noop
				// onPlaybackRateChange={func}       // defaults -> noop
				// onPlaybackQualityChange={func}    // defaults -> noop
			/>
		</Wrapper>
	);
};

export default YoutubeVideo;
