import React from "react";
import styled from "styled-components";
import { device } from "../Styles/Media";
const ListContainerDefulat = styled.section`
	padding-top: 14.25rem;
	padding-bottom: 10.375rem;

	@media ${device.laptopL} {
		padding-top: 187px;
		padding-bottom: 6rem;
	}

	@media ${device.tablet} {
		padding-top: 0rem;
		padding-bottom: 4rem;
	}
`;

export default ({ children, className }) => {
	return <ListContainerDefulat className={className}>{children}</ListContainerDefulat>;
};
