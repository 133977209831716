import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { device, size } from "../Styles/Media";
import { DetailClose, DetailUp } from "./Icon";
import { scrollToTarget, defaultBodyScroll } from "../Utility";
import { AppContext } from "App";
import { Link } from "react-router-dom";
import YoutubeVideo from "./YoutubeVideo";

const Dim = styled.div`
	${(props) => props.theme.fullAbs};
	position: fixed;
	background-color: rgba(0, 0, 0, 0.6);
	overflow: scroll;
	z-index: ${(props) => props.theme.z_detail};
	${(props) => props.theme.offset_default};
	padding-top: ${(props) => props.theme.height_header_pc};
	${(props) => props.theme.hideScroll};
	opacity: ${(props) => (props.active ? 1 : 0)};
	transition: ${(props) => props.theme.transition_detail};

	@media ${device.laptopL} {
		padding-top: ${(props) => props.theme.height_header_laptopL};
	}

	@media ${device.laptop} {
		padding: 0;
		background-color: white;
	}
`;

const Wrapper = styled.div`
	background-color: #fff;
	padding: 6rem 4.625rem 8.625rem 4.625rem;
	position: relative;
	margin-bottom: ${(props) => props.theme.height_header_pc};

	transform: ${(props) => (props.active ? `translateY(-32px)` : `translateY(0px)`)};
	transition: ${(props) => props.theme.transition_detail};

	@media ${device.laptopL} {
		padding: 55px 45px 83px;
		margin-top: 80px;
		margin-bottom: ${(props) => props.theme.height_header_laptopL};
	}

	@media ${device.laptop} {
		margin-top: 0px;
	}

	@media ${device.tablet} {
		padding: 4rem ${(props) => props.theme.offset_screen_tablet};
	}

	@media ${device.mobileL} {
		padding: 4rem ${(props) => props.theme.offset_screen_mobileL};
	}
`;

const Row = styled.div`
	&:nth-child(2) {
		margin-top: 5rem;
		margin-bottom: 7.75rem;

		@media ${device.laptopL} {
			margin-top: 36px;
			margin-bottom: 60px;
		}

		@media ${device.laptop} {
			margin-top: 4rem;
			margin-bottom: 4rem;
		}

		@media ${device.tablet} {
		}
	}
`;

// HEADER
const RowHeader = styled(Row)`
	display: flex;
	flex-direction: column;
	position: relative;
`;

const Title = styled.p`
	${(props) => props.theme.notoBlack};
	font-size: 3.125rem;
	line-height: 1.2;
	margin-bottom: 1.5625rem;
	width: 50%;

	@media ${device.laptopL} {
		font-size: 35px;
		line-height: 1.6;
		margin-bottom: 6px;
	}

	@media ${device.tablet} {
		width: 80%;
		font-size: 1.5rem;
		margin-bottom: 2rem;
	}
`;

const CloseBtn = styled(DetailClose)`
	position: absolute;
	cursor: pointer;
	top: 0;
	right: 0;
`;

const Names = styled.ul`
	display: flex;
	flex-wrap: wrap;

	@media ${device.mobileL} {
		display: block;
	}
`;

const NameText = styled.li`
	font-size: 1.875rem;
	${(props) => props.theme.notoKrRegular};
	width: 400px;
	margin-bottom: 0.8rem;

	/* english name */
	span {
		margin-left: 1rem;
		${(props) => props.theme.notoRegular};
	}

	@media ${device.laptopL} {
		width: 300px;
		font-size: 1.25rem;
		line-height: 1.6;
		margin-bottom: 10px;
		span {
			margin-left: 0.5rem;
		}
	}
	@media ${device.laptop} {
		width: 50%;
	}
	@media ${device.mobileL} {
		width: 100%;
	}
`;

// CONTENTS
const Description = styled.p`
	${(props) => props.theme.notoKrRegular};
	margin-bottom: 5.25rem;
	line-height: 1.8;
	font-size: 1.8rem;

	@media ${device.laptopL} {
		margin-bottom: 33px;
		font-size: 18px;
		line-height: 1.6;
	}

	@media ${device.laptop} {
		margin-bottom: 1rem;
	}
`;

const Files = styled.ul``;
const File = styled.li`
	img {
		width: 100%;
	}
`;

// CONTACT
const Contacts = styled.ul`
	display: flex;
	flex-wrap: wrap;
`;

const Contact = styled.li`
	width: ${(props) => (props.full ? `100%` : `calc(50% - 4px)`)};
	padding: 3.125rem;
	border: 1px solid black;
	margin-right: 8px;
	margin-bottom: 8px;
	&:nth-child(2n) {
		margin-right: 0;
	}

	@media ${device.laptopL} {
		padding: 26px 32px;
	}

	@media ${device.tablet} {
		width: 100%;
		margin-right: 0;
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.name {
		margin-bottom: 2rem;
		font-size: 1.8rem;
		${(props) => props.theme.notoKrRegular};

		span {
			margin-left: 1rem;
			${(props) => props.theme.notoLight};

			@media ${device.laptopL} {
				margin-left: 48px;
			}

			@media ${device.laptop} {
				margin-left: 1rem;
			}
		}
	}

	.contacts {
		li {
			position: relative;

			${(props) => props.theme.notoRegular};
			margin-bottom: 1rem;
			font-size: 1.56rem;
			line-height: 1.36;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				/* width: 100%; */
				/* display: block; */
				word-break: break-all;
			}
		}
	}

	@media ${device.laptopL} {
		padding: 2rem 1.5rem;
		.name {
			font-size: 20px;
			margin-bottom: 1.4rem;
		}
		.contacts li {
			font-size: 18px;
			line-height: 1;
			margin-bottom: 1rem;
		}
	}

	@media ${device.tablet} {
		.name {
			font-size: 1.2rem;
		}
		.contacts li {
			font-size: 1.2rem;
		}
	}

	@media ${device.mobileL} {
		padding: ${(props) => props.theme.offset_screen_mobileL};
	}
`;

const BtnWrapper = styled.div`
	margin-top: 2rem;
	display: flex;
	flex-direction: row-reverse;
`;

const DetailUpBtn = styled(DetailUp)`
	position: absolute;
	bottom: 0;
	right: 1.875rem;
	transform: translateY(50%);
	cursor: pointer;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 100%;

	@media ${device.laptopL} {
		right: 3rem;
	}

	@media ${device.laptop} {
		position: relative;
		display: flex;
		right: 0;
		transform: none;
	}
`;

export default ({ data, setNowProject }) => {
	const [active, setActive] = useState(false);
	const { nowMedia, nowYear } = useContext(AppContext);
	const [sizeBtn, setSizeBtn] = useState();
	const [sizeIcon, setIconSize] = useState();
	const [users, setUsers] = useState([]);
	const [images, setImages] = useState([]);

	useEffect(() => {
		setActive(true);
		return () => setActive(false);
	}, []);

	useEffect(() => {
		if (data) {
			setImages(Array.isArray(data.files) ? data.files : [data.files]);
			const newUser = Array.isArray(data.users) ? data.users : [data.users];
			const contactArrayUser = newUser.map((el) => (Array.isArray(el.contact) ? el : { ...el, contact: [el.contact] }));
			setUsers(contactArrayUser);
		}
	}, [data]);

	const onCloseClicked = () => {
		closeDetailProject();
	};

	const onUpClicked = () => {
		scrollToTarget("#root", "smooth", 0, "#detailWrapper");
	};

	useEffect(() => {
		if (nowMedia <= size.tablet) {
			setSizeBtn(64);
		} else if (nowMedia <= size.laptopL) {
			setSizeBtn(80);
		} else {
			setSizeBtn(128);
		}
	}, [nowMedia]);

	useEffect(() => {
		if (nowMedia && nowMedia <= size.tablet) {
			setIconSize(24);
		} else {
			setIconSize(38);
		}
	}, [nowMedia]);

	const closeDetailProject = () => {
		setNowProject(null);
		defaultBodyScroll();
	};

	const onDimClicked = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.target.id === "detailWrapper") {
			closeDetailProject();
		}
	};

	const onNameClicked = () => {
		closeDetailProject();
		scrollToTarget("#root", "auto", 0);
	};

	const openLink = (link) => {
		window.open(link);
	};

	return (
		<Dim id={"detailWrapper"} onClick={onDimClicked} active={active}>
			<Wrapper active={active}>
				{/* Detail header */}
				<RowHeader>
					<CloseBtn onClick={onCloseClicked} size={sizeIcon} />
					<Title>{data.name}</Title>
					<Names>
						{users.map((user) => {
							return (
								<NameText key={user.id}>
									<Link to={`/${nowYear}/profile/${user.id}`} onClick={onNameClicked}>
										{user.name_kr} <span>{user.name_en}</span>
									</Link>
								</NameText>
							);
						})}
					</Names>
				</RowHeader>

				{/* Detail contents */}
				<Row>
					<Description>{data.descript}</Description>
					<Files>
						<File>
							{images.map((el, index) => {
								const isVideo = el.includes("YOUTUBE_EMBED");
								let videoId = "";
								if (isVideo) {
									videoId = el.split("/")[1];
								}
								return isVideo ? <YoutubeVideo key={index} videoId={videoId} /> : <img src={`/static${el}`} key={index} alt="project detail file" />;
							})}
						</File>
					</Files>
				</Row>

				{/* Profile Contacts */}
				<Row>
					<Contacts>
						{users.map((el) => {
							return (
								<Contact key={el.id} full={data.users.length === 1 ? true : false}>
									<p className="name">
										<Link to={`/${nowYear}/profile/${el.id}`} onClick={onNameClicked}>
											{el.name_kr} <span>{el.name_en}</span>
										</Link>
									</p>
									<ul className="contacts">
										{el.contact.map((link, index) => {
											const href = link.includes("http") ? link : `https://${link}`;
											return (
												<li key={index}>
													{link.includes("http") || link.includes("www.") ? (
														<a target="_blank" href={href} rel="noopener noreferrer" onClick={() => openLink(href)}>
															{link}
														</a>
													) : (
														link
													)}
												</li>
											);
										})}
									</ul>
								</Contact>
							);
						})}
					</Contacts>
					<BtnWrapper>
						<DetailUpBtn onClick={onUpClicked} size={sizeBtn} />
					</BtnWrapper>
				</Row>
			</Wrapper>
		</Dim>
	);
};
