import React from "react";
import styled from "styled-components";
import { device } from "../Styles/Media";

import InputText from "Components/Form/InputText";
import InputTextarea from "Components/Form/InputTextarea";
import InputNumber from "Components/Form/InputNumber";
import Submit from "Components/Form/Submit";

const Popup = styled.div`
	${(props) => props.theme.centerFlex};
	${(props) => props.acitve && props.theme.preventEvent};
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: ${(props) => props.theme.z_popup};
	background-color: ${(props) => props.theme.color.dim};
`;

const FormContainer = styled.div`
	background-color: white;
	${(props) => props.theme.shadow.default};
	${(props) => props.theme.containerAdmin};
	padding: 24px 16px;
`;
const Form = styled.form``;

const FormHeader = styled.div`
	margin-bottom: 24px;
	padding-bottom: 6px;
	border-bottom: 1px solid black;
	display: flex;
	justify-content: space-between;
`;

const FormTitle = styled.p`
	font-size: 18px;
	font-weight: 700;
`;

const BtnCancle = styled.p`
	cursor: pointer;
	color: ${(props) => props.theme.color.input.default};
	@media ${device.hover} {
		&:hover {
			color: ${(props) => props.theme.color.input.active};
		}
	}
`;

const PopupLabList = ({ active, onCancleClick, lab, submitType, onChange, onAddSubmit, onEditSubmit }) => {
	const handleSubmit = (e) => {
		e.preventDefault();

		if (submitType === "add") {
			onAddSubmit(lab);
		} else if (submitType === "edit") {
			onEditSubmit(lab);
		} else {
			return;
		}
	};
	return (
		<Popup active={active}>
			<FormContainer>
				<Form onSubmit={handleSubmit}>
					<FormHeader>
						<FormTitle>랩 추가하기</FormTitle>
						<BtnCancle onClick={onCancleClick}>닫기</BtnCancle>
					</FormHeader>
					<InputText label={"랩 이름"} value={lab.title} placeholder="Meta Design Lab" onChange={(e) => onChange("title", e)} />
					<InputNumber label={"우선순위"} value={lab.order} placeholder="우선순위" onChange={(e) => onChange("order", e)} />
					<InputText label={"부 제목"} value={lab.subtitle} placeholder="online" onChange={(e) => onChange("subtitle", e)} />
					<InputTextarea label={"설명"} value={lab.address} placeholder="www.naver.com" onChange={(e) => onChange("address", e)} />
					<Submit value="추가" />
				</Form>
			</FormContainer>
		</Popup>
	);
};

export default PopupLabList;
