import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ListDefault from "./ListDefault";
import Thumbnail from "./Thumbnail";
import ListTitle from "./ListTitle";
import { useImageLoad } from "../Hooks/hooks";
import placeholder from "Assets/image/placeholder_list.png";

const ProfileList = styled(ListDefault)``;
const ListContainer = styled.div``;

export default ({ profile, onClick }) => {
	const [image, setImage] = useState(`/static${profile.image}`);

	useEffect(() => {
		if (!profile.image) {
			setImage(placeholder);
		}
	}, [profile]);

	const { load } = useImageLoad(image);

	return (
		<ProfileList onClick={() => onClick(profile.id)} active={load}>
			<ListContainer>
				<Thumbnail src={image} hover={false} />
				<ListTitle text={profile.name_en} />
			</ListContainer>
		</ProfileList>
	);
};
