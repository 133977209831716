import React from "react";
// ASSETS

// LIB
import styled from "styled-components";
import { Down } from "Components/Icon";

import { device } from "Styles/Media";
// CONTEXT
import MainHeader from "Components/MainHeader";
import Loading from "../../Components/Loading";
import CoverImage from "../../Components/CoverImage";

const BlockYears = styled.ul`
	position: fixed;
	z-index: 200;
	left: 102px;
	top: 310px;
	width: 100px;

	@media ${device.laptopL} {
		width: 80px;
		top: 200px;
		left: ${(props) => props.theme.offset_screen_labtapL};
	}

	@media ${device.tablet} {
		width: inherit;
		top: 160px;
		left: ${(props) => props.theme.offset_screen_tablet};
		transition: ${(props) => props.theme.transition_default};
		transform: ${(props) => props.hide && `translateX(-100%)`};
		opacity: ${(props) => props.hide && `0`};
	}

	@media ${device.mobileL} {
		top: 100px;
		left: ${(props) => props.theme.offset_screen_mobileL};
	}
`;

const Year = styled.li`
	text-align: right;
	${(props) => props.theme.notoBold}
	font-size: 1.75rem;
	margin-bottom: 1.8rem;
	color: black;
	opacity: ${(props) => (props.active ? 1 : 0.5)};
	cursor: pointer;

	@media ${device.hover} {
		&:hover {
			opacity: 0.7;
		}
	}

	@media ${device.tablet} {
		font-size: 1.6rem;
		text-align: left;
	}
`;

const BlockExhibition = styled.div`
	width: 100%;
	position: relative;
	z-index: 10;
	overflow-x: hidden;
`;

const Container = styled.div`
	${(props) => props.theme.containerDefault};
	max-width: calc(100% - 500px);

	margin: 0 auto;
	position: relative;

	@media ${device.laptopL} {
		max-width: ${(props) => `calc(100% - ${props.theme.offset_screen_labtapL} * 4 - ${props.theme.width_mainlogo_laptopL}*2)`};
	}
	@media ${device.tablet} {
		max-width: ${(props) => `calc(100% - ${props.theme.offset_screen_tablet} * 2 )`};
	}
	@media ${device.mobileL} {
		max-width: ${(props) => `calc(100% - ${props.theme.offset_screen_mobileL} * 2 )`};
	}
`;

const WrapperBtn = styled.div`
	display: flex;
	align-items: center;

	position: relative;
	z-index: 20;
	flex-direction: column;
	cursor: pointer;
	margin-bottom: 90px;
	> div {
		${(props) => props.theme.centerFlex}
		flex-direction: column;
	}
	p {
		${(props) => props.theme.terminaMedium}
	}
`;

const WrapperInfo = styled.div`
	position: relative;
	margin: auto;
	padding-bottom: 7.5rem;
`;

const Discrip = styled.div``;

const Labs = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

const Lab = styled.div`
	/* padding-right: 64px; */
	width: calc(50% - 32px);
	margin-bottom: 5rem;

	&:last-child,
	&:nth-child(3) {
		margin-bottom: 0px;
	}

	@media ${device.tablet} {
		width: calc(50% - 8px);
	}

	@media ${device.mobileL} {
		width: 100%;
		margin-bottom: 3rem;
		&:nth-child(3) {
			margin-bottom: 5rem;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const Title = styled.h2`
	${(props) => props.theme.notoBold};
	font-size: 1.25rem;
	margin-bottom: 2.5rem;
	margin-top: 4.3rem;
	${(props) => props.type === "professor" && `margin-top: 114px;`}
`;

const SubTitle = styled.h3`
	${(props) => props.theme.notoMedium};
	font-size: 1.25rem;
	line-height: 1.4;
`;

const Text = styled.p`
	${(props) => props.theme.notoRegular};
	font-size: 1.25rem;
	line-height: 1.4;
	text-transform: ${(props) => props.uppercase && `uppercase`};
	span,
	a {
		display: inline-block;

		min-height: calc(1.25rem * 1.4);
		color: black;
		word-break: break-all;
	}
	${(props) =>
		props.type === "type" &&
		`
    margin-bottom: 20px;
    margin-top: 3.7px;
  `}
	${(props) =>
		props.type === "prefessor" &&
		`
		span{
			margin-right: 6px;
		}
	`};
`;

const DescriptionText = styled(Text)`
	> span {
		display: block;
	}
`;

export default ({ nowYearData, isPortrait, years, onYearClick, onDownClick, hideYear }) => {
	return (
		<>
			<MainHeader />

			<Loading active={!years && !nowYearData} />
			{years && nowYearData && (
				<>
					<BlockYears hide={hideYear}>
						{years.map((el, index) => {
							return (
								<Year index={index} key={el.id} active={nowYearData.year === el.year} onClick={() => onYearClick(el)}>
									{el.year}
								</Year>
							);
						})}
					</BlockYears>

					<BlockExhibition>
						<Container>
							<CoverImage isPortrait={isPortrait} nowYearData={nowYearData} image={isPortrait ? nowYearData.graphic_portrait.fileUrl : nowYearData.graphic_landscape.fileUrl} />

							<WrapperBtn>
								<div onClick={onDownClick}>
									<p>Scroll</p>
									<Down />
								</div>
							</WrapperBtn>
							<WrapperInfo id={"description"}>
								<Discrip>
									<Title>{nowYearData.title}</Title>
									<DescriptionText>
										{nowYearData.text.split("\n").map((el, index) => {
											return <span key={index}>{el}</span>;
										})}
									</DescriptionText>
								</Discrip>

								<div>
									<Title>EXHIBITION COMPOSITION</Title>
									<Labs>
										{nowYearData.lab &&
											nowYearData.lab.map((el) => {
												return (
													<Lab key={el.id}>
														<SubTitle>{el.title}</SubTitle>
														<Text type={"type"} uppercase={true}>
															{el.subtitle}
														</Text>

														<Text>
															{el.address.split("\n").map((t, index) => {
																return t.includes("http://") || t.includes("https://") ? (
																	<span key={index}>
																		<a href={t} target="_blank" rel="noopener noreferrer">
																			{t}
																		</a>
																	</span>
																) : (
																	<span key={index}>{t}</span>
																);
															})}
														</Text>
													</Lab>
												);
											})}
									</Labs>
								</div>

								<Discrip>
									<Title type={"professor"}>지도 교수</Title>
									<Text type={"prefessor"}>
										{nowYearData.professor.map((el, index) => (
											<span key={index}>{`${el}${nowYearData.professor.length - 1 !== index ? "," : ""}`} </span>
										))}
									</Text>
								</Discrip>
							</WrapperInfo>
						</Container>
					</BlockExhibition>
				</>
			)}
		</>
	);
};
