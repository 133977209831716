import React, { useEffect, useState } from "react";
import ProfileDetailPresentor from "./ProfileDetailPresentor";
import { withRouter } from "react-router-dom";
import { getProfileById, getProjectById } from "../../Data";
import { defaultBodyScroll, preventBodyScroll } from "../../Utility";

export default withRouter(
	({
		match: {
			params: { id, year }
		}
	}) => {
		const [nowProfile, setNowProfile] = useState();
		const [nowProject, setNowProject] = useState();

		useEffect(() => {
			defaultBodyScroll();
		}, []);

		useEffect(() => {
			if (id) {
				setNowProfile(getProfileById(+id));
			}
		}, [id]);

		const onProjectClicked = (id) => {
			const test = getProjectById(id);
			setNowProject(test);
			preventBodyScroll();
		};

		return nowProfile ? <ProfileDetailPresentor nowProject={nowProject} setNowProject={setNowProject} nowProfile={nowProfile} onProjectClicked={onProjectClicked} /> : null;
	}
);
