import React from "react";
import styled from "styled-components";
import ListContainerDefulat from "../../Components/ListContainerDefulat";
import ProfileList from "../../Components/ProfileList";
import FullHeight from "../../Components/FullHeight";
import { device } from "../../Styles/Media";

const Profile = styled(FullHeight)`
	padding-top: ${(props) => props.theme.height_header_pc};
	@media ${device.laptopL} {
		padding-top: ${(props) => props.theme.height_header_laptopL};
	}
	@media ${device.tablet} {
		padding-top: ${(props) => props.theme.height_header_tablet};
	}
	@media ${device.mobileL} {
		padding-top: ${(props) => props.theme.height_header_mobileL};
	}
`;
const Wrapper = styled(ListContainerDefulat)``;
const ProfileWrapper = styled.div`
	${(props) => props.theme.offset_default};
	flex-wrap: wrap;
	display: flex;
`;

export default ({ data, onProfileClciked }) => {
	return (
		<Profile>
			<Wrapper>
				<ProfileWrapper>
					{data &&
						data.map((profile) => {
							return <ProfileList key={profile.id} profile={profile} onClick={onProfileClciked} />;
						})}
				</ProfileWrapper>
			</Wrapper>
		</Profile>
	);
};
