import {
  device
} from 'Styles/Media';
// font
const FONT_NOTOSANS = `noto-sans `;
const FONT_TERMINA = `termina `;
const FONT_NOTOSANSKR = `Noto Sans KR`;

const OFFSET = {
  pc: "100px",
  laptopL: `60px`,
  tablet: `32px`,
  mobileL: `16px`,
}

export const GAP_LIST = {
  default: "16px",
  laptopL: "8px",
  tablet: "16px",
  mobileL: "16px",
}

const WIDTH_LIST = {
  default: `calc((100% - ${GAP_LIST.default} * 3)/4)`,
  laptopL: `calc((100% - ${GAP_LIST.laptopL} * 3)/4)`,
  tablet: `calc((100% - ${GAP_LIST.tablet} * 1)/2)`,
  mobileL: `100%`,
}



export default {

  // COLOR
  colorPlacehoder: "#C4C4C4",
  color: {
    dim: "rgba(0,0,0,0.5)",
    input: {
      default: "#cdcdcd",
      active: "black",
    }
  },


  // FONT
  notoKrDemiLight: `font-family: ${FONT_NOTOSANSKR} sans-serif;
  font-weight: 300`,
  notoKrRegular: `font-family: ${FONT_NOTOSANSKR} sans-serif;
  font-weight: 400`,
  notoKrMedium: `font-family: ${FONT_NOTOSANSKR} sans-serif;
  font-weight: 500`,
  notoKrBold: `font-family: ${FONT_NOTOSANSKR} sans-serif;
  font-weight: 700`,
  notoKrBlack: `font-family: ${FONT_NOTOSANSKR} sans-serif;
  font-weight: 900`,


  notoLight: `font-family: ${FONT_NOTOSANS}, sans-serif;
  font-weight: 300;
  font-style: normal;`,
  notoRegular: `font-family: ${FONT_NOTOSANS}, sans-serif;
  font-weight: 400;
  font-style: normal;`,
  notoMedium: `font-family: ${FONT_NOTOSANS}, sans-serif;
  font-weight: 500;
  font-style: normal;`,
  notoBold: `font-family: ${FONT_NOTOSANS}, sans-serif;
  font-weight: 700;
  font-style: normal;`,
  notoExtraBold: `font-family: ${FONT_NOTOSANS}, sans-serif;
  font-weight: 800;
  font-style: normal;`,
  notoBlack: `font-family: ${FONT_NOTOSANS}, sans-serif;
  font-weight: 900;
  font-style: normal;`,

  terminaLight: `font-family: ${FONT_TERMINA}, sans-serif;
  font-weight: 300;
  font-style: normal;`,
  terminaMedium: `font-family: ${FONT_TERMINA}, sans-serif;
  font-weight: 500;
  font-style: normal;`,
  terminaBold: `font-family: ${FONT_TERMINA}, sans-serif;
  font-weight: 700;
  font-style: normal;`,
  terminaHeavy: `font-family: ${FONT_TERMINA}, sans-serif;
  font-weight: 800;
  font-style: normal;`,
  terminaBlack: `font-family: ${FONT_TERMINA}, sans-serif;
  font-weight: 900;
  font-style: normal;`,

  input_default: `
    font-family: ${FONT_NOTOSANS}, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.4;
  `,

  // EVENT
  preventEvent: `pointer-events: none;
  user-select: none;`,
  activeEvent: `pointer-events: auto; user-select: auto;`,

  // Zindex
  z_Header: 150,
  z_menu: 100,
  z_poster: 150,
  z_search: 200,
  z_detail: 200,
  z_notfound: 300,
  z_popup: 300,
  z_loading: 400,

  // SHADOW
  shadow: {
    default: `box-shadow: 0 6px 8px -4px rgba(0, 0, 0, 0.3);
    &:hover {
        box-shadow: 0 8px 8px -2px rgba(0, 0, 0, 0.2);
      }`
  },


  // LAYOUT
  mainWidth: `64%;`,
  bgFull: `background-size: cover;
  background-position: center;
  background-repeat: no-repeat`,
  bgContain: `background-size: contain;
  background-position: center;
  background-repeat: no-repeat;`,
  containerDefault: `width: 1200px; max-width: 90%; margin: 0 auto`,
  containerAdmin: `
    width: 560px;
    margin: 0 auto;
    max-width: 90%;
  `,
  hideScroll: `
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  fullAbs: `
  position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
  `,
  afterRatio: `&:after {
		content: "";
		display: block;
		padding-top: 100%;
  }`,
  initInput: `
  background-color: transparent;
	outline: none;
	border: none;`,


  // POSITION
  centerFlex: `display: flex;
  align-items: center;
  justify-content:center;`,
  centerAbs: `position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);`,


  // SIZE
  // - offset
  offset_screen_pc: OFFSET.pc,
  offset_screen_laptopL: OFFSET.laptopL,
  offset_screen_tablet: OFFSET.tablet,
  offset_screen_mobileL: OFFSET.mobileL,

  offset_default: `
    padding: 0 ${OFFSET.pc};
    @media ${device.laptopL}{
      padding: 0 ${OFFSET.laptopL}
    };
    @media ${device.tablet}{
      padding: 0 ${OFFSET.tablet}
    };
    @media ${device.mobileL}{
      padding: 0 ${OFFSET.mobileL}
    };
  `,

  // - logo
  width_mainlogo_pc: `120px`,
  width_mainlogo_laptopL: `80px`,
  width_mainlogo_tablet: `64px`,
  width_mainlogo_mobileL: `40px`,

  // - header
  height_header_pc: `210px`,
  height_header_desktop: `140px`,
  height_header_laptopL: `140px`,
  height_header_tablet: `120px`,
  height_header_mobileL: `80px`,

  // -poster up button
  height_posterUp_default: "125px",
  height_posterUp_laptopL: "82px",
  height_posterUp_tablet: "80px",
  height_posterUp_mobileL: "60px",

  // -icon
  size_icon_default: "38px",
  size_icon_sm: "24px",

  // -List width
  width_list_default: `${WIDTH_LIST.default}`,
  width_list_laptopL: `${WIDTH_LIST.laptopL}`,
  width_list_tablet: `${WIDTH_LIST.tablet}`,
  width_list_mobileL: `${WIDTH_LIST.mobileL}`,
  width_list: `
    width: ${WIDTH_LIST.default};
    @media ${device.laptopL}{
      width: ${WIDTH_LIST.laptopL};
    };
    @media ${device.tablet}{
      width: ${WIDTH_LIST.tablet};
    };
    @media ${device.mobileL}{
      width: ${WIDTH_LIST.mobileL};
    };
  `,
  input_borderRatio: "6px",

  // -List gap
  list_gap: GAP_LIST,

  // -Admin container


  // -Thumbnail ratio
  ratio_thumbnail: '76%',

  // TRANSITION
  transition_default: `all .3s ease-in-out;`,
  transition_list: `opacity .2s ease-in-out;`,
  transition_dropdown: `height .2s cubic-bezier(0.79,-0.02, 0.16, 0.99);`,
  transition_detail: `all .6s cubic-bezier(0.79, 0.01, 0.26, 1.01)`


};