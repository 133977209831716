import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import SearchBar from "./SearchBar";
import { SearchClose } from "./Icon";
import { AppContext } from "../App";
import { device, size } from "../Styles/Media";
import { useInput } from "../Hooks/hooks";
import { useHistory } from "react-router-dom";

// STYLED
const SearchOverlay = styled.section`
	${(props) => props.theme.fullAbs};
	background-color: rgba(0, 0, 0, 0.85);
	z-index: ${(props) => props.theme.z_search};
	position: fixed;
	${(props) => props.theme.centerFlex};

	@media ${device.tablet} {
		&:after {
			content: "";
			display: block;
			z-index: -1;
			${(props) => props.theme.fullAbs};
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.85+0,0+100 */
			background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
		}

		align-items: flex-start;
	}
`;

const SearchWrapper = styled.div`
	width: 100%;

	@media ${device.tablet} {
		height: ${(props) => props.theme.height_header_tablet};
		padding-left: ${(props) => props.theme.offset_screen_tablet};
		padding-right: ${(props) => props.theme.offset_screen_tablet};

		display: flex;
		align-items: center;
	}

	@media ${device.mobileL} {
		height: ${(props) => props.theme.height_header_mobileL};
		padding-left: ${(props) => props.theme.offset_screen_mobileL};
		padding-right: ${(props) => props.theme.offset_screen_mobileL};
	}
`;

const SearchForm = styled.form`
	position: relative;
	width: 100%;
	height: 60%;
`;

const Close = styled(SearchClose)`
	cursor: pointer;
	position: absolute;
	top: ${(props) => `calc(${props.theme.height_header_pc}/2)`};
	right: ${(props) => props.theme.offset_screen_pc};
	transform: translateY(-50%);

	@media ${device.laptopL} {
		top: ${(props) => `calc(${props.theme.height_header_laptopL}/2)`};
		right: ${(props) => props.theme.offset_screen_laptopL};
	}

	@media ${device.tablet} {
		position: relative;
		transform: none;
		top: initial;
		right: initial;
		margin-left: ${(props) => props.theme.offset_screen_tablet};
	}

	@media ${device.mobileL} {
		margin-left: ${(props) => props.theme.offset_screen_mobileL};
	}
`;

export default () => {
	const { setIsSearchClick, nowMedia, nowYear } = useContext(AppContext);
	const [iconSize, setIconSize] = useState();

	const { value, onChange, setValue } = useInput("");
	const { push } = useHistory();

	useEffect(() => {
		if (nowMedia <= size.laptopL) {
			setIconSize(24);
		} else {
			setIconSize(null);
		}
	}, [nowMedia]);

	const handleOnSearchSubmit = (e) => {
		e.preventDefault();

		pushSearch();
	};

	const pushSearch = () => {
		if (value) {
			setValue("");
			setIsSearchClick(false);
			push(`/${nowYear}/search?term=${value}`);
		} else {
			return;
		}
	};

	const onCloseClick = () => {
		setIsSearchClick(false);
	};

	return (
		<SearchOverlay>
			<SearchWrapper>
				<SearchForm onSubmit={handleOnSearchSubmit}>
					<SearchBar value={value} onChange={onChange} setValue={setValue} />
				</SearchForm>
				<Close onClick={onCloseClick} size={iconSize} />
			</SearchWrapper>
		</SearchOverlay>
	);
};
