export default [{
    "id": 1,
    "thumbnail": "/data/project/1/thumbnail.png",
    "thumbnail_larg": "/data/project/1/thumbnail_larg.png",
    "name": "Mythy",
    "lab": "meta",
    "descript": "Mythy는 하나의 물음에서 시작됩니다. 스스로를 표현할 수 있도록 질문을 던집니다. '당신은 어떤 컵인가요?'와 같은 질문입니다. '나’에 대한 나의 이미지 표현은 다양한 형태로 네트워크 안에서 운반되어 나와 유사한 선택을 한 사람, 나를 궁금해하는 사람, 나를 알려주고 싶은 사람들을 만나 새로운 교류의 가능성을 만들어냅니다.",
    "files": [
      "/data/project/1/detail_01.png",
      "/data/project/1/detail_02.png",
      "/data/project/1/detail_03.png",
      "/data/project/1/detail_04.png",
      "/data/project/1/detail_05.png",
      "/data/project/1/detail_06.png",
      "/data/project/1/detail_07.png",
      "/data/project/1/detail_08.png",
      "/data/project/1/detail_09.png",
      "/data/project/1/detail_10.png",
      "/data/project/1/detail_11.png",
      "/data/project/1/detail_12.png",
      "/data/project/1/detail_13.png",
      "/data/project/1/detail_14.png"
    ],
    "users": [
      48,
      25
    ]
  },
  {
    "id": 2,
    "thumbnail": "/data/project/2/thumbnail.jpg",
    "thumbnail_larg": "/data/project/2/thumbnail_larg.jpg",
    "name": "물성을 넘어서",
    "lab": "meta",
    "descript": "모든 것이 하루만 지나도 옛 것이 되는 격변의 시대 21세기.\n산업혁명 당시 기계의 등장으로 사라질 위기에 처했었던 공예는 두 번째 위기를 맞이하였다.\n더 이상 변하지 않는 것은 도태되고 살아남지 못하면 사라진다.\n미디어와 SNS, 4차 산업혁명 그리고 코로나 바이러스가 일으킨 변화의 물결을 공예는 어떻게 마주해야 할까.",
    "files": [
      "YOUTUBE_EMBED/IvGBVGOWHW4",
      "/data/project/2/01.jpg",
      "/data/project/2/02.jpg",
      "/data/project/2/03.jpg",
      "/data/project/2/04.jpg",
      "/data/project/2/05.jpg"
    ],
    "users": [
      39
    ]
  },
  {
    "id": 3,
    "thumbnail": "/data/project/3/thumbnail.jpg",
    "name": "14-24",
    "lab": "meta",
    "descript": null,
    "files": [
      "/data/project/3/01.jpg"
    ],
    "users": [
      49
    ]
  },
  {
    "id": 4,
    "thumbnail": "/data/project/4/thumbnail.png",
    "thumbnail_larg": "/data/project/4/thumbnail_larg.jpg",
    "name": "사사로이, 서울",
    "lab": "meta",
    "descript": null,
    "files": [
      "/data/project/4/01.jpg",
      "/data/project/4/02.jpg",
      "/data/project/4/03.png"
    ],
    "users": [
      5
    ]
  },
  {
    "id": 5,
    "thumbnail": "/data/project/5/thumbnail.png",
    "name": "Design RGB",
    "lab": "meta",
    "descript": "10년, 20년 뒤에는 디자인이 무엇인가, 라는 물음에 정확한 답을 내놓는 사람이 나타날까? 우리는 디자이너로서 디자인을 명확히 정의하려 노력할 필요가 있다. 이 질문에 답하기 위해 1년간 각종 도서, 기사에 묻혀 19~20년 국내의 모든 디자인 사건을 파헤쳐보았다. Design RGB는 위처럼 진행된 연구와 그 결과를 주제에 맞게 3권으로 나눈 서적이다.",
    "files": [
      "/data/project/5/01.png",
      "/data/project/5/02.png",
      "/data/project/5/03.png",
      "/data/project/5/04.png",
      "/data/project/5/05.png",
      "/data/project/5/06.png",
      "/data/project/5/07.png",
      "/data/project/5/08.gif",
      "/data/project/5/09.png",
      "/data/project/5/10.png",
      "/data/project/5/11.png",
      "/data/project/5/12.png",
      "/data/project/5/13.png",
      "/data/project/5/14.png",
      "/data/project/5/15.png",
      "/data/project/5/16.png",
      "/data/project/5/17.png",
      "/data/project/5/18.png",
      "/data/project/5/19.png",
      "/data/project/5/20.png",
      "/data/project/5/21.png"
    ],
    "users": [
      3,
      4,
      14,
      20,
      24,
      38,
      43
    ]
  },
  {
    "id": 6,
    "thumbnail": "/data/project/6/thumbnail.jpg",
    "name": "세탁기와 문화 그리고 아카이브",
    "lab": "meta",
    "descript": null,
    "files": [
      "/data/project/6/01.jpg"
    ],
    "users": [
      37
    ]
  },
  {
    "id": 7,
    "thumbnail": "/data/project/7/thumbnail.png",
    "name": "covid-19 archive",
    "lab": "meta",
    "descript": "코로나19 아카이브' 프로젝트는 바이러스와 공존하는 새로운 일상을 준비하기 위해, 사회에 침투한 바이러스에 대한 \n\n우리의 경험을 면밀히 기록하고 공유하며 사회문화적으로 생겨나는 새로운 변화를 발견하려는 아카이브 프로젝트입니다.",
    "files": [
      "/data/project/7/01.gif",
      "/data/project/7/02.png",
      "YOUTUBE_EMBED/N2My9Gsvmf4"
    ],
    "users": [
      1,
      6,
      8,
      11
    ]
  },
  {
    "id": 8,
    "thumbnail": "/data/project/8/thumbnail.jpg",
    "name": "90년대, 나의 집",
    "lab": "meta",
    "descript": "90년대, 4개의 집에 존재했던 물건들의 사연과 시대적 배경 이야기",
    "files": [
      "/data/project/8/01.jpg",
      "/data/project/8/02.jpg",
      "/data/project/8/03.gif",
      "/data/project/8/04.jpg",
      "/data/project/8/05.gif",
      "/data/project/8/06.jpg",
      "/data/project/8/07.gif",
      "/data/project/8/08.jpg",
      "/data/project/8/09.gif",
      "/data/project/8/10.jpg"
    ],
    "users": [
      17
    ]
  },
  {
    "id": 9,
    "thumbnail": "/data/project/9/thumbnail.jpg",
    "thumbnail_larg": "/data/project/9/thumbnail_larg.jpg",
    "name": "코로나 기행사전",
    "lab": "meta",
    "descript": "본 프로젝트의 목적은 2020년 전세계를 휩쓴 코로나 바이러스가 사람들에게 어떠한 영향을 끼쳤는지 코로나로 인해 생긴 기행에 대한 기사들을 모아 그 흐름을 읽어보는데 있다. \n\n각국의 정부에서 코로나를 다루는 방식이 대한 기사를 강조하기 위한 지면에서 정부의 대응 방식을 비교하면서 볼 수 있도록 하였다.",
    "files": [
      "/data/project/9/01.jpg",
      "/data/project/9/02.jpg",
      "/data/project/9/03.jpg",
      "/data/project/9/04.jpg",
      "/data/project/9/05.jpg"
    ],
    "users": [
      30,
      51
    ]
  },
  {
    "id": 10,
    "thumbnail": "/data/project/10/thumbnail_larg.jpg",
    "thumbnail_larg": "/data/project/10/thumbnail_larg.jpg",
    "name": "SNS의 역사 그리고 예술",
    "lab": "meta",
    "descript": "본 프로젝트는 SNS의 역사에 대하여 시대적으로 분석하고 구체적 일화를 통해 소개한다.\nSNS는 어떤 외부요인에 의하여 변화하였으며 SNS가 우리 삶과 예술 영역에 어떻게 영향을 주고 받았는지 다양한 방면에 대한 서술을 통해 그 흐름을 밝히는데 목적이 있다,",
    "files": [
      "/data/project/10/01.jpg"
    ],
    "users": [
      2
    ]
  },
  {
    "id": 11,
    "thumbnail": "/data/project/11/thumbnail.jpg",
    "name": "Ideanarrative",
    "lab": "meta",
    "descript": "플라톤이 언급한 순수하고 참된 실재 '이데아'와\n스크린이 토해내는 욕망을 조직하는 '내러티브'에서\n영감을 받은 <이데아티브> 입니다.\n\n우리의 주변에서 발견되는 이상적인 내러티브 장치를\n필자의 주관대로 모아, 기록 및 편집하였습니다.",
    "files": [
      "/data/project/11/01.jpg",
      "/data/project/11/02.jpg",
      "/data/project/11/03.jpg",
      "/data/project/11/04.jpg",
      "/data/project/11/05.jpg",
      "/data/project/11/06.jpg",
      "/data/project/11/07.jpg",
      "/data/project/11/08.jpg",
      "/data/project/11/09.jpg",
      "/data/project/11/10.jpg",
      "/data/project/11/11.jpg",
      "/data/project/11/12.jpg"
    ],
    "users": [
      44
    ]
  },
  {
    "id": 12,
    "thumbnail": "/data/project/12/thumbnail.png",
    "name": "문예진의 죽음",
    "lab": "meta",
    "descript": "본 프로젝트는 작가가 자신과 동명이인의 사망자를 찾아가는 6개월간의 여정에서 마주친 죽음들에 대한 기록물이다. 작가는 '이름'을 매개로 완벽한 타인에게서 나의 죽음을 경험한다.",
    "files": [
      "/data/project/12/01.png",
      "/data/project/12/02.png",
      "/data/project/12/03.png",
      "/data/project/12/04.gif",
      "/data/project/12/05.png",
      "/data/project/12/06.png",
      "/data/project/12/07.png",
      "/data/project/12/08.png",
      "/data/project/12/09.png",
      "/data/project/12/10.png",
      "/data/project/12/11.png",
      "/data/project/12/12.png",
      "/data/project/12/13.png",
      "/data/project/12/14.png",
      "/data/project/12/15.png",
      "/data/project/12/16.png"
    ],
    "users": [
      15
    ]
  },
  {
    "id": 13,
    "thumbnail": "/data/project/13/thumbnail.png",
    "thumbnail_larg": "/data/project/13/thumbnail_larg.png",
    "name": "그냥 집에 있었어",
    "lab": "meta",
    "descript": "\"주말 동안 뭐 했어?\"라는 질문을 들었을 때, 그 짧은 질문의 순간에도 내 머리는 금방 복잡해진다. \r\n하지만 복잡해지는 머리와는 반대로 내 입에서는 매번 정해진 대답만 내뱉는다.\r\n\r\n\r\n\"그냥 집에 있었어.\"",
    "files": [
      "YOUTUBE_EMBED/0sXWFnWbrvg",
      "/data/project/13/01.jpg",
      "/data/project/13/02.jpg"
    ],
    "users": [
      7
    ]
  },
  {
    "id": 14,
    "thumbnail": "/data/project/14/thumbnail.jpg",
    "thumbnail_larg": "/data/project/14/thumbnail_larg.jpg",
    "name": "CAMO",
    "lab": "future",
    "descript": null,
    "files": [
      "/data/project/14/01.jpg",
      "YOUTUBE_EMBED/g6nQp5AkmN0",
      "/data/project/14/02.jpg"
    ],
    "users": [
      12,
      29
    ]
  },
  {
    "id": 15,
    "thumbnail": "/data/project/15/thumbnail.jpg",
    "thumbnail_larg": "/data/project/15/thumbnail_larg.jpg",
    "name": "시나브로",
    "lab": "future",
    "descript": null,
    "files": [
      "YOUTUBE_EMBED/_xc4CuUFb_g",
      "/data/project/15/01.jpg",
      "/data/project/15/02.jpg"
    ],
    "users": [
      22
    ]
  },
  {
    "id": 16,
    "thumbnail": "/data/project/16/thumbnail.jpg",
    "name": "Parcours",
    "lab": "future",
    "descript": "캠핑을 떠나고 싶지만, 경제적으로 여유가 있지 않은 뉴비와 아마추어들을 위한 저렴한 가격으로 캠핑을 즐길 수 있도록 기획한  1인용 공유 캠핑차량이다. 차량의 사이즈를 도시와 캠핑 시 다르게 하여 상황에 따른 공간 활용에 초점을 맞췄으며 차량을 대여할 때, 캠핑장비까지 함께 대여하도록 하여 가볍고 간단하게 캠핑을 할 수 있도록 지원한다.",
    "files": [
      "/data/project/16/01.jpg",
      "/data/project/16/02.jpg",
      "/data/project/16/03.jpg",
      "/data/project/16/04.gif",
      "YOUTUBE_EMBED/UTi8zJA_Vlg",
      "/data/project/16/05.jpg",
      "/data/project/16/06.jpg",
      "/data/project/16/07.jpg",
      "/data/project/16/08.jpg",
      "/data/project/16/09.jpg",
      "YOUTUBE_EMBED/gAvOt9vIZi8",
      "/data/project/16/10.jpg",
      "/data/project/16/11.jpg",
      "/data/project/16/12.jpg",
      "/data/project/16/13.jpg"
    ],
    "users": [
      13
    ]
  },
  {
    "id": 17,
    "thumbnail": "/data/project/17/thumbnail.png",
    "name": "Ola",
    "lab": "future",
    "descript": "아동 정신분석의 거장이자 대상 관계 이론가인 도널드 위니 컷은 ‘놀이는 행함이다’라고 정의하였을 만큼 아이들은 자신의 삶 속에서 관찰에서의 ‘놀이(play)’가 아닌, 행동에서의 ‘놀이하기(playing)’를 선호합니다. Ola는 목욕이라는 싫어하는 감각을 좋아할 수 있도록 물과 비누 거품들이 아이가 좋아하는 VR 영상과 상호작용할 수 있는 제품입니다.",
    "files": [
      "/data/project/17/01.png",
      "/data/project/17/02.jpg",
      "/data/project/17/03.jpg",
      "/data/project/17/04.png",
      "/data/project/17/05.png",
      "/data/project/17/06.png"
    ],
    "users": [
      47
    ]
  },
  {
    "id": 18,
    "thumbnail": "/data/project/18/thumbnail.jpg",
    "thumbnail_larg": "/data/project/18/thumbnail_larg.jpg",
    "name": "Chromaffice",
    "lab": "future",
    "descript": "올해 고용노동부에서 실시한 ‘재택근무 활용실태 설문조사’에 따르면 전체 활용 근로자의 91.3%는 재택근무에 만족하였고 업무효율이 높아졌다는 응답이 66.7%로 나타났다. 불가능이라 여겨졌던 재택 근무는 포스트 코로나 시대에 실현가능한 근무 방식이 되었다. 'Chromaffice'는 이에 발 맞춰 재택 근무환경 개선에 대한 새로운 경험과 방식을 제안한다.",
    "files": [
      "YOUTUBE_EMBED/oyTtWyIptuI",
      "/data/project/18/01.jpg",
      "/data/project/18/02.gif",
      "/data/project/18/03.gif",
      "/data/project/18/04.jpg",
      "/data/project/18/05.jpg",
      "/data/project/18/06.jpg",
      "/data/project/18/07.jpg"
    ],
    "users": [
      39
    ]
  },
  {
    "id": 19,
    "thumbnail": "/data/project/19/thumbnail.jpg",
    "name": "즐거운 나의 집",
    "lab": "future",
    "descript": "전자제품은 가구들과 어우러지지 못하고 사용하지 않을 때는 둘 곳이 없습니다. 또한 여러 불편한 점을 가지고 있습니다. 이러한 기기에게 모자람을 채워주고 따뜻한 보금자리될 '가전의 집'을 지어주는 프로젝트 '즐거운 나의 집'입니다.",
    "files": [
      "/data/project/19/01.jpg",
      "YOUTUBE_EMBED/PFW4X8Pi72Q",
      "/data/project/19/02.jpg",
      "/data/project/19/03.jpg",
      "/data/project/19/04.gif",
      "/data/project/19/05.jpg",
      "YOUTUBE_EMBED/AM2z2m_dQIU",
      "/data/project/19/06.jpg",
      "/data/project/19/07.jpg",
      "/data/project/19/08.jpg",
      "/data/project/19/09.jpg"
    ],
    "users": [
      40
    ]
  },
  {
    "id": 20,
    "thumbnail": "/data/project/20/thumbnail.jpg",
    "name": "VOID",
    "lab": "future",
    "descript": "우리 주위에는 기능을 하지 않는, 어떤 것에도 사용되지 않는 틈이 있다. 부족한 틈새에 작은 사용성을 부여하여 특별한 분위기를 만들 수 있다.",
    "files": [
      "YOUTUBE_EMBED/UZNcFCm5PlY",
      "/data/project/20/01.jpg"
    ],
    "users": [
      23
    ]
  },
  {
    "id": 21,
    "thumbnail": "/data/project/21/thumbnail.jpg",
    "name": "Takeit",
    "lab": "future",
    "descript": "나이가 들면서 기억력 감퇴를 겪는 노인들이 늘어나면서 생활 중에 많은 문제가 생긴다. \nTakeit 은 노인들이 소지품을 두고 가는 문제를 줄이려고 새로운 제안한다.",
    "files": [
      "YOUTUBE_EMBED/iyoqfmwInig",
      "/data/project/21/01.jpg",
      "/data/project/21/02.jpg",
      "/data/project/21/03.jpg"
    ],
    "users": [
      45
    ]
  },
  {
    "id": 22,
    "thumbnail": "/data/project/22/thumbnail.png",
    "name": "P01",
    "lab": "future",
    "descript": "가구는 장소를 변화시킨다.가구의 변화를 통해 자연스러운 움직임을 유발하고 새로운 장소성을 만든다.",
    "files": [
      "YOUTUBE_EMBED/RgAOtCdZxbA",
      "/data/project/22/01.jpg",
      "/data/project/22/02.gif",
      "/data/project/22/03.jpg",
      "/data/project/22/04.gif",
      "/data/project/22/05.jpg"
    ],
    "users": [
      21
    ]
  },
  {
    "id": 23,
    "thumbnail": "/data/project/23/thumbnail.png",
    "name": "P02",
    "lab": "future",
    "descript": "가구는 장소를 변화시킨다.가구의 변화를 통해 자연스러운 움직임을 유발하고 새로운 장소성을 만든다.",
    "files": [
      "YOUTUBE_EMBED/mnf28PSW0Ac",
      "/data/project/23/01.jpg",
      "/data/project/23/02.gif",
      "/data/project/23/03.jpg",
      "/data/project/23/04.jpg",
      "/data/project/23/05.gif",
      "/data/project/23/06.jpg",
      "/data/project/23/07.jpg"
    ],
    "users": [
      46
    ]
  },
  {
    "id": 24,
    "thumbnail": "/data/project/24/thumbnail.png",
    "name": "P03",
    "lab": "future",
    "descript": "가구는 장소를 변화시킨다.가구의 변화를 통해 자연스러운 움직임을 유발하고 새로운 장소성을 만든다.",
    "files": [
      "YOUTUBE_EMBED/sORDIgFXTQE",
      "/data/project/24/01.jpg",
      "/data/project/24/02.jpg"
    ],
    "users": [
      50
    ]
  },
  {
    "id": 25,
    "thumbnail": "/data/project/25/thumbnail.jpg",
    "thumbnail_larg": "/data/project/25/thumbnail_larg.png",
    "name": "Avoidog",
    "lab": "future",
    "descript": "노화로 시력을 점점 잃어가는 노견에게 매일 생활하는 집일지라도 길찾기란 쉽지 않다. 하지만 길을 잃는 상황이 반복되면서, 노견은 자신감도 함께 잃게 되고 결국 움직이는 것을 두려워하게 되면서 움직임을 최소화하기 시작합니다. 눈이 아닌 다른 감각으로 길을 찾는 것은 어떨까?",
    "files": [
      "/data/project/25/01.gif",
      "/data/project/25/02.gif",
      "YOUTUBE_EMBED/09maSpoNlsg",
      "/data/project/25/03.jpg",
      "/data/project/25/04.gif",
      "/data/project/25/05.jpg",
      "/data/project/25/06.gif",
      "/data/project/25/07.jpg",
      "/data/project/25/08.gif",
      "/data/project/25/09.jpg",
      "/data/project/25/10.gif",
    ],
    "users": [
      7
    ]
  },
  {
    "id": 26,
    "thumbnail": "/data/project/26/thumbnail.jpg",
    "name": "FLUX",
    "lab": "future",
    "descript": "부모세대가 되어가는 밀레니얼 세대는 자신의 라이프 스타일을 살아갑니다. 이들을 대표적으로 드러내는 것은 ‘미니멀리즘’ 진정한 행복을 위해 덜어냄을 추구하는 미니멀리스트는 공간 안에서 간결함을 추구합니다. 하지만 육아를 하는 데 필요한 교구를 장만하며 어쩔수 없이 미니멀 라이프를 포기하게 됩니다. 새로운 사고방식의 부모들에게 새로운 기준의 양육을 제안합니다.",
    "files": [
      "/data/project/26/01.jpg",
      "YOUTUBE_EMBED/Tcf7HEYvw9I",
      "/data/project/26/02.jpg"
    ],
    "users": [
      9
    ]
  },
  {
    "id": 27,
    "thumbnail": "/data/project/27/thumbnail.jpg",
    "thumbnail_larg": "/data/project/27/thumbnail_larg.png",
    "name": "SISO",
    "lab": "future",
    "descript": null,
    "files": [
      "/data/project/27/01.jpg",
      "YOUTUBE_EMBED/GyfeUKCc7FI",
    ],
    "users": [
      19,
      34
    ]
  },
  {
    "id": 28,
    "thumbnail": "/data/project/28/thumbnail.png",
    "thumbnail_larg": "/data/project/28/thumbnail_larg.png",
    "name": "THE RAIN",
    "lab": "future",
    "descript": null,
    "files": [
      "YOUTUBE_EMBED/8ijaJZ33zFA",
      "/data/project/28/01.jpg",
      "/data/project/28/02.jpg",
      "/data/project/28/03.jpg"
    ],
    "users": [
      41
    ]
  },
  {
    "id": 29,
    "thumbnail": "/data/project/29/thumbnail.png",
    "name": "FATZ",
    "lab": "future",
    "descript": null,
    "files": [
      "/data/project/29/01.jpg",
      "YOUTUBE_EMBED/DOL3BrDs1Hs",
    ],
    "users": [
      37,
      42
    ]
  },
  {
    "id": 30,
    "thumbnail": "/data/project/30/thumbnail_larg.jpg",
    "thumbnail_larg": "/data/project/30/thumbnail_larg.jpg",
    "name": "Oasis",
    "lab": "future",
    "descript": "눈이 잘 보이지 않아도 노견의 당연했던 일상은 계속되어야 한다.",
    "files": [
      "/data/project/30/01.png",
      "/data/project/30/02.jpg",
      "/data/project/30/03.jpg",
      "/data/project/30/04.jpg",
      "/data/project/30/05.jpg",
      "/data/project/30/06.jpg",
      "/data/project/30/07.jpg",
      "/data/project/30/08.png",
      "/data/project/30/09.jpg",
      "/data/project/30/10.jpg",
      "YOUTUBE_EMBED/8EdU5H7RtK0"
    ],
    "users": [
      52
    ]
  },
  {
    "id": 31,
    "thumbnail": "/data/project/31/thumbnail.png",
    "name": "Loose.lab",
    "lab": "future",
    "descript": "상판이 실로 만들어진 책상입니다.\r\n\r\n사용하는 의도에 맞게 옆에 있는 손잡이로 실의 텐션을 조절할 수 있습니다.",
    "files": [
      "/data/project/31/01.png",
      "/data/project/31/02.png",
      "/data/project/31/03.png",
      "/data/project/31/04.png",
      "/data/project/31/05.png"
    ],
    "users": [
      35
    ]
  },
  {
    "id": 32,
    "thumbnail": "/data/project/32/thumbnail.jpg",
    "name": "ISATAD",
    "lab": "future",
    "descript": "더 이상 운전할 필요가 없는 자율주행차 안에서 우리는 무얼 할 수 있을까. 여행을 하는 동안 시간이 제일 많이 걸리는 부분은 공항에서의 수속과정입니다. 공항으로 이동하는 시간동안 차 안에서 수속과정을 마친다면 우리의 여행은 더욱 편리해질 것입니다.",
    "files": [
      "YOUTUBE_EMBED/7OsoDODnDvU",
      "/data/project/32/01.jpg",
      "/data/project/32/02.jpg",
      "/data/project/32/03.jpg"
    ],
    "users": [
      16
    ]
  },
  {
    "id": 33,
    "thumbnail": "/data/project/33/thumbnail.jpg",
    "name": "Spielraum",
    "lab": "space",
    "descript": "sns를 사용하는 사람이 점점 많아졌습니다.  비록 쉽고 편리하게 기록할 수 있다는 장점이 있지만,  sns에 과시적 기록이 넘쳐나고 있습니다. 더 잘 보여야 하고, 더 멋진 모습이어야 하며 다른 사람과 비교하다 보면 자신에게 집중하기는 힘들어집니다. 이런 상황에 자존감을 회복하고 싶은 이들에게 다시, 자신에게 집중할 수 있는 기회를 제공하려 합니다.",
    "files": [
      "/data/project/33/01.jpg",
      "/data/project/33/02.jpg",
      "/data/project/33/03.jpg"
    ],
    "users": [
      9,
      26,
      33
    ]
  },
  {
    "id": 34,
    "thumbnail": "/data/project/34/thumbnail.jpg",
    "name": "우리다무기력",
    "lab": "space",
    "descript": "무기력함을 긍정적으로 바라보기. 하늘을 보며 차 한잔으로 무기력에 빠질 수 있는 공간입니다.",
    "files": [
      "/data/project/34/01.jpg",
      "/data/project/34/02.jpg",
      "/data/project/34/03.jpg",
      "/data/project/34/04.jpg",
      "/data/project/34/05.jpg",
      "/data/project/34/06.jpg",
      "/data/project/34/07.jpg",
      "/data/project/34/08.jpg"
    ],
    "users": [
      28
    ]
  },
  {
    "id": 35,
    "thumbnail": "/data/project/35/thumbnail.jpg",
    "name": "격",
    "lab": "space",
    "descript": "이 프로젝트는 사회적이나 정서적 고통을 받고 있는 청년들이 불안함과 스트레스 등 정신 관련 문제가 증가로 인해 격이 있는 공간을 중심으로 출발하고 혼자만 머무할 수 있는 호텔을 제안을 했다.",
    "files": [
      "/data/project/35/01.jpg",
      "/data/project/35/02.jpg"
    ],
    "users": [
      45,
      52
    ]
  },
  {
    "id": 36,
    "thumbnail": "/data/project/36/thumbnail.jpg",
    "thumbnail_larg": "/data/project/36/thumbnail_larg.jpg",
    "name": "Tempou",
    "lab": "space",
    "descript": null,
    "files": [
      "/data/project/36/01.jpg",
      "/data/project/36/02.gif",
      "/data/project/36/03.jpg",
      "/data/project/36/04.jpg",
      "/data/project/36/05.gif",
      "/data/project/36/06.jpg",
      "/data/project/36/07.gif",
      "/data/project/36/08.jpg",
      "/data/project/36/09.gif",
      "/data/project/36/10.jpg",
      "/data/project/36/11.gif",
      "/data/project/36/12.jpg"
    ],
    "users": [
      21,
      46,
      50
    ]
  },
  {
    "id": 37,
    "thumbnail": "/data/project/37/thumbnail.png",
    "name": "Savour and",
    "lab": "space",
    "descript": null,
    "files": [
      "/data/project/37/01.jpg",
      "/data/project/37/02.jpg"
    ],
    "users": [
      42
    ]
  },
  {
    "id": 38,
    "thumbnail": "/data/project/38/thumbnail.jpg",
    "name": "Fungi",
    "lab": "space",
    "descript": null,
    "files": [
      "/data/project/38/01.jpg"
    ],
    "users": [
      49
    ]
  },
  {
    "id": 39,
    "thumbnail": "/data/project/39/thumbnail.jpg",
    "name": "Noirb",
    "lab": "space",
    "descript": null,
    "files": [
      "/data/project/39/01.jpg",
      "/data/project/39/02.jpg"
    ],
    "users": [
      10,
      22
    ]
  },
  {
    "id": 40,
    "thumbnail": "/data/project/40/thumbnail.jpg",
    "name": "Around islands",
    "lab": "space",
    "descript": null,
    "files": [
      "/data/project/40/01.jpg",
      "/data/project/40/02.jpg",
      "/data/project/40/03.jpg",
      "/data/project/40/04.jpg",
      "/data/project/40/05.jpg",
      "/data/project/40/06.jpg",
      "/data/project/40/07.jpg",
      "/data/project/40/08.jpg",
      "/data/project/40/09.jpg",
      "/data/project/40/10.jpg",
      "/data/project/40/11.jpg",
      "/data/project/40/12.jpg",
      "/data/project/40/13.jpg",
      "/data/project/40/14.jpg",
      "/data/project/40/15.jpg",
      "/data/project/40/16.jpg",
      "/data/project/40/17.jpg",
      "/data/project/40/18.jpg"
    ],
    "users": [
      47
    ]
  },
  {
    "id": 41,
    "thumbnail": "/data/project/41/thumbnail.jpg",
    "thumbnail_larg": "/data/project/41/thumbnail_larg.jpg",
    "name": "서행장",
    "lab": "space",
    "descript": null,
    "files": [
      "/data/project/41/01.jpg",
      "/data/project/41/02.jpg",
      "/data/project/41/03.jpg"
    ],
    "users": [
      5
    ]
  },
  {
    "id": 42,
    "thumbnail": "/data/project/42/thumbnail.png",
    "thumbnail_larg": "/data/project/42/thumbnail_larg.png",
    "name": "BLUEMOMENT\nPROJECT",
    "lab": "space",
    "descript": null,
    "files": [
      "/data/project/42/01.jpg",
      "/data/project/42/02.jpg",
      "/data/project/42/03.jpg",
      "/data/project/42/04.gif",
      "/data/project/42/05.jpg",
      "/data/project/42/06.jpg"
    ],
    "users": [
      41
    ]
  },
  {
    "id": 43,
    "thumbnail": "/data/project/43/thumbnail.jpg",
    "thumbnail_larg": "/data/project/43/thumbnail_larg.jpg",
    "name": "Come and calm",
    "lab": "space",
    "descript": "작은 주머니에서 시작된 향신료점.\n음식은 우리의 감정과 태도에 영향을 미치며 그에 따라 감정이 해소되는 것을 감각과 감정의 불가분 관계로 설명할 수 있습니다. 그중에서도 오감을 한 번에 자극하는 '향신료'라는 매개체로 감정을 다루는 가게를 제안합니다.\n'come and calm'은 작은 주머니에서 시작된 향신료 점으로 요리사가 식당을 운영하면서, 향신료에 대한 궁금증을 가진 손님들에게 작은 주머니에 넣어주면서 이야기가 시작됩니다.",
    "files": [
      "/data/project/43/01.jpg",
      "/data/project/43/02.gif",
      "/data/project/43/03.jpg",
      "/data/project/43/04.gif",
      "/data/project/43/05.jpg",
      "/data/project/43/06.jpg",
      "/data/project/43/07.gif",
      "/data/project/43/08.png"
    ],
    "users": [
      18,
      36
    ]
  },
  {
    "id": 44,
    "thumbnail": "/data/project/44/thumbnail.png",
    "thumbnail_larg": "/data/project/44/thumbnail_larg.png",
    "name": "Kino eye",
    "lab": "space",
    "descript": "파편화된 서울의 다양한 공간들을 이용하는 새로운 방식, Kino eye",
    "files": [
      "/data/project/44/01.jpg",
      "/data/project/44/02.jpg"
    ],
    "users": [
      23,
      27
    ]
  },
  {
    "id": 45,
    "thumbnail": "/data/project/45/thumbnail.jpg",
    "thumbnail_larg": "/data/project/45/thumbnail_larg.png",
    "name": "Refugio Sereno",
    "lab": "space",
    "descript": "내가 진실로 진실로 너희에게 이르노니 나를 믿는 자는 내가 하는 일을 그도 할 것이요\n또한 그보다 큰 일도 하리니 이는 내가 아버지께로 감이라 (요한복음 14:12)\nInstagram_@refugiosereno",
    "files": [
      "/data/project/45/01.png",
      "/data/project/45/02.png",
      "/data/project/45/03.png",
      "/data/project/45/04.png",
      "/data/project/45/05.png"
    ],
    "users": [
      31
    ]
  },
  {
    "id": 46,
    "thumbnail": "/data/project/46/thumbnail.jpg",
    "name": "Soli",
    "lab": "space",
    "descript": "Soli는 1인 목욕 공간으로, 도시에서 잃어가고 있는 빛, 바람 등의 자연 감각을 공간에 끌어들여 공간 속의 감각적 단서만을 통해 사람들의 감정과 행동을 이끌어냅니다.",
    "files": [
      "/data/project/46/01.jpg",
      "/data/project/46/02.jpg",
      "/data/project/46/03.jpg",
      "/data/project/46/04.jpg",
      "/data/project/46/05.jpg",
      "/data/project/46/06.jpg",
      "/data/project/46/07.jpg",
      "/data/project/46/08.jpg",
      "/data/project/46/09.jpg",
      "/data/project/46/10.jpg"
    ],
    "users": [
      17
    ]
  },
  {
    "id": 47,
    "thumbnail": "/data/project/47/thumbnail.jpg",
    "name": "기억박물관",
    "lab": "space",
    "descript": "당신의 추억은 어떠했나요? \n\n기억 박물관에서, 당신은 추억 탐색자로써 기억 속 순간을 찾기 위한 여정을 떠나거나 추억 발견자로써 소중한 기억을 보관할 수 있습니다.",
    "files": [
      "/data/project/47/01.jpg",
      "/data/project/47/02.jpg"
    ],
    "users": [
      51
    ]
  },
  {
    "id": 48,
    "thumbnail": "/data/project/48/thumbnail.jpg",
    "name": "Oddments",
    "lab": "space",
    "descript": "여러 면모를 모으고 조합하면  깊이 있는 입체를 만들 수 있습니다. oddments는 여러 면모가 담긴 사소한 이야기에 대한 애정으로부터 탄생했습니다. 텍스타일을 누구나 가깝게 느낄 수 있도록 애정을 쌓아가는 공간으로 왜곡을 통한 패턴의 여러 모습을 볼 수 있는 텍스타일 샵입니다.",
    "files": [
      "/data/project/48/01.jpg",
      "/data/project/48/02.jpg",
      "/data/project/48/03.jpg",
      "/data/project/48/04.jpg"
    ],
    "users": [
      40
    ]
  },
  {
    "id": 49,
    "thumbnail": "/data/project/49//thumbnail.png",
    "name": "IMPLANT",
    "lab": "space",
    "descript": "비어있는 공간이나 가게를 습격해서 활기를 띄는 뮤직 펍을 오픈하는 팝업 프로젝트",
    "files": [
      "/data/project/49/01.png",
      "/data/project/49/02.png",
      "/data/project/49/03.png",
      "/data/project/49/04.png",
      "/data/project/49/05.png",
      "/data/project/49/06.png",
      "YOUTUBE_EMBED/2xTwTjMCA7E",
      "YOUTUBE_EMBED/tA6RjAb0spM",
      "YOUTUBE_EMBED/i878tOALanI"
    ],
    "users": [
      35
    ]
  },
  {
    "id": 50,
    "thumbnail": "/data/project/50/thumbnail.png",
    "name": "MOYO",
    "lab": "ux",
    "descript": "현대사회에는 방대한 양의 정보가 존재하며, 그 사실 여부를 판단하기 어렵습니다. MOYO는  당신이 관심 있는 분야의 정책 및 이슈를 큐레이팅을 통해 신속하게 제공해주고, 다양한 방식으로 지속적인 관심을 가질 수 있도록 도와줍니다. MOYO를 통해 편향된 가치관에서 벗어나 사회를 다양한 시각으로 바라볼 수 있는 건강한 시민이 될 수 있습니다.",
    "files": [
      "/data/project/50/01.png",
      "/data/project/50/02.gif",
      "/data/project/50/03.png",
      "/data/project/50/04.gif",
      "YOUTUBE_EMBED/lcC1omaj4uM"
    ],
    "users": [
      11,
      36
    ]
  },
  {
    "id": 51,
    "thumbnail": "/data/project/51/thumbnail.jpg",
    "thumbnail_larg": "/data/project/51/thumbnail_larg.jpg",
    "name": "Poster",
    "lab": "ux",
    "descript": "우리는 우리 주변의 실제 세계에서 일어나는 일들에 대해 얼마나 알고 있나요? 자신이 전달하고 싶은 다양한 정보를 마치 '포스터' 붙이듯 위치 기반으로 홍보할 수 있는 방법은 없을까요? 지역 안에서 흐르는 정보에 의해 우리의 삶은 얼마나 달라질 수 있을까요? 포스터 서비스는 위치를 기반으로 지역화된 정보를 교류할 수 있는 네트워크 환경을 제공합니다.",
    "files": [
      "/data/project/51/01.jpg",
      "/data/project/51/02.jpg",
      "/data/project/51/03.jpg",
      "/data/project/51/04.jpg",
      "/data/project/51/05.jpg",
      "/data/project/51/06.jpg",
      "/data/project/51/07.jpg",
      "/data/project/51/08.jpg",
      "/data/project/51/09.jpg",
      "/data/project/51/10.jpg",
      "/data/project/51/11.jpg",
      "/data/project/51/12.jpg"
    ],
    "users": [
      48
    ]
  },
  {
    "id": 52,
    "thumbnail": "/data/project/52/thumbnail.jpg",
    "name": "Parcours",
    "lab": "ux",
    "descript": "캠핑을 떠나고 싶지만, 경제적으로 여유가 있지 않은 뉴비와 아마추어들을 위한 저렴한 가격으로 1인용 캠핑차량과 용품을 공유 및 제공하는 서비스이다.\n최소한의 과정으로 다양한 정보 제공과 경험 공유하여 혼자 차박을 하고 싶거나 혼자 캠핑을 해야하는 상황에서 즐거운 경험을 할 수 있도록 지원한다.",
    "files": [
      "/data/project/52/01.jpg",
      "/data/project/52/02.jpg",
      "/data/project/52/03.jpg",
      "/data/project/52/04.jpg",
      "YOUTUBE_EMBED/awAv0d9Cl08",
      "/data/project/52/05.jpg",
      "YOUTUBE_EMBED/iVgeSTcTY_Q",
      "/data/project/52/06.jpg",
      "YOUTUBE_EMBED/NQUi5mDHA5I",
      "/data/project/52/07.jpg",
      "/data/project/52/08.jpg",
      "/data/project/52/09.jpg",
      "/data/project/52/10.jpg",
      "/data/project/52/11.jpg",
      "/data/project/52/12.jpg"
    ],
    "users": [
      13
    ]
  },
  {
    "id": 53,
    "thumbnail": "/data/project/53/thumbnail.jpg",
    "thumbnail_larg": "/data/project/53/thumbnail_larg.png",
    "name": "so-simple",
    "lab": "ux",
    "descript": "우리는 의사소통이 원활한 느슨한 연대에서 살아갑니다. 하지만 소심한 사람들은 눈치 보기 바쁘고, 사회에서는 부정적인 인식 탓에 격차가 발생하기도 합니다. 그들에게 제안하는 방법들은 많지만, 직접 겪지 않는 이상해 나가기 어렵습니다.  더 눈치 보지 않고 당당하게 살아가고픈 소심한 자들에게 대범한 사람이 되어보게 하는 가상 현실 체험 서비스를 제안합니다.",
    "files": [
      "/data/project/53/01.jpg",
      "/data/project/53/02.gif",
      "/data/project/53/03.jpg",
      "/data/project/53/04.gif",
      "/data/project/53/05.gif",
      "/data/project/53/06.gif",
      "/data/project/53/07.jpg",
      "/data/project/53/08.gif",
      "/data/project/53/09.jpg"
    ],
    "users": [
      18
    ]
  },
  {
    "id": 54,
    "thumbnail": "/data/project/54/thumbnail.jpg",
    "name": "DANAN",
    "lab": "ux",
    "descript": "이 프로젝트는 새로운 방식의 카페를 제시한다. 어플을 이용한 체크리스트를 통해 유저의 취향에 맞는 레시피를 추천하고, 그 레시피를 직접 만들어 볼 수 있도록 카페를 연결시켜준다. 그리고 스마트 안경을 어플과 연동시켜 레시피를 AR로 안내 받을 수 있으며, 어플 내의 커뮤니티를 통해 다른 유저들과 레시피를 공유하고 소통할 수 있는 서비스를 제공한다.",
    "files": [
      "/data/project/54/01.jpg",
      "/data/project/54/02.gif",
      "/data/project/54/03.gif",
      "/data/project/54/04.jpg",
      "YOUTUBE_EMBED/OFE-lRl5DhQ",
      "YOUTUBE_EMBED/j7ME7kUP4Pk"
    ],
    "users": [
      29
    ]
  },
  {
    "id": 55,
    "thumbnail": "/data/project/55/thumbnail.jpg",
    "thumbnail_larg": "/data/project/55/thumbnail_larg.jpg",
    "name": "TRIPOP",
    "lab": "ux",
    "descript": "자신이 원하는 혹은 속한 그룹-'Pool'을 선택하고 그 Pool안에 들어가 다양하게 존재하는 여행거리 -'Bubble'를 탐험하며 구경할 수 있습니다. 또한 자신이 만든 여러 개의 Pool을 다양한 방법으로 조합하여 새로운 Bubble 피드를 볼 수 있습니다. 각 Pool에 설정된 세부옵션 -'#해쉬태그'들이 모여 새로운 피드를 사용자에게 보여줍니다.",
    "files": [
      "/data/project/55/01.jpg",
      "/data/project/55/02.jpg",
      "/data/project/55/03.jpg",
      "/data/project/55/04.jpg",
      "/data/project/55/05.jpg",
      "/data/project/55/06.jpg",
      "/data/project/55/07.jpg",
      "/data/project/55/08.gif",
      "/data/project/55/09.gif",
      "/data/project/55/10.gif",
      "/data/project/55/11.gif",
      "YOUTUBE_EMBED/8nmLAF8nI-c",
      "/data/project/55/12.gif",
      "/data/project/55/13.jpg"
    ],
    "users": [
      53
    ]
  },
  {
    "id": 56,
    "thumbnail": "/data/project/56/thumbnail.png",
    "thumbnail_larg": "/data/project/56/thumbnail_larg.png",
    "name": "COGEAR",
    "lab": "ux",
    "descript": "COGEAR는 공유주거 사용자들의 관계의 지속성을 위한 스마트 홈 어플리케이션으로 서로 다른 환경에서 살아온 사용자들의 자율성과 프라이버시를 보호하면서도 이들의 관계의 지속시키기 위한 프로젝트입니다. 서비스는 사용자들의 규칙과 역할을 관리하고 불편함을 최소화 시켜주는 중재자가 되어 함께 사는 이들을 위한 편안하고 안전한 라이프스타일을 제안합니다.",
    "files": [
      "/data/project/56/01.png",
      "/data/project/56/02.png",
      "/data/project/56/03.gif",
      "/data/project/56/04.gif",
      "/data/project/56/05.png",
      "/data/project/56/06.gif",
      "/data/project/56/07.png",
      "YOUTUBE_EMBED/GtrjgyEV63w",
      "/data/project/56/08.jpg",
      "/data/project/56/09.png"
    ],
    "users": [
      31
    ]
  },
  {
    "id": 57,
    "thumbnail": "/data/project/57/thumbnail.jpg",
    "thumbnail_larg": "/data/project/57/thumbnail_larg.jpg",
    "name": "오리동네",
    "lab": "ux",
    "descript": "같은 동네 사람들과 도움을 주고받으며 든든하고, 외롭지 않은 1인가구 라이프를 즐길 수 있는 온라인 플랫폼입니다.",
    "files": [
      "/data/project/57/01.jpg",
      "/data/project/57/02.jpg",
      "/data/project/57/03.jpg",
      "/data/project/57/04.jpg",
      "/data/project/57/05.jpg",
      "/data/project/57/06.gif",
      "/data/project/57/07.jpg",
      "/data/project/57/08.jpg",
      "/data/project/57/09.jpg",
      "/data/project/57/10.jpg"
    ],
    "users": [
      28
    ]
  },
  {
    "id": 58,
    "thumbnail": "/data/project/58/thumbnail.png",
    "name": "워닛",
    "lab": "ux",
    "descript": "워닛은 판매 시기가 지나 시장에 더 이상 생산되지 않는 물건들에 대한 소비자의 수요를 다시 모아 제품의 재생산을 도와주는 플랫폼입니다. 워닛에서 당신의 놓쳐버린 기회를 향해 다 같이 외쳐보세요.",
    "files": [
      "/data/project/58/01.gif",
      "/data/project/58/02.gif",
      "/data/project/58/03.png",
      "/data/project/58/04.gif",
      "/data/project/58/05.gif",
      "/data/project/58/06.png",
      "/data/project/58/07.gif",
      "/data/project/58/08.gif",
      "/data/project/58/09.png",
      "/data/project/58/10.png",
      "/data/project/58/11.png",
      "/data/project/58/12.gif",
      "/data/project/58/13.gif",
      "/data/project/58/14.png",
      "/data/project/58/15.gif",
      "/data/project/58/16.gif",
      "/data/project/58/17.gif",
      "/data/project/58/18.gif",
      "/data/project/58/19.png",
      "/data/project/58/20.png",
      "/data/project/58/21.png",
      "/data/project/58/22.gif",
      "/data/project/58/23.png",
      "/data/project/58/24.gif"
    ],
    "users": [
      15
    ]
  },
  {
    "id": 59,
    "thumbnail": "/data/project/59/thumbnail.png",
    "name": "LINEMATE",
    "lab": "ux",
    "descript": null,
    "files": [
      "/data/project/59/01.png",
      "/data/project/59/02.jpg",
      "/data/project/59/03.jpg",
      "/data/project/59/04.png",
      "YOUTUBE_EMBED/3synIWnbmeg",
      "/data/project/59/05.png",
      "/data/project/59/06.png",
      "/data/project/59/07.png",
      "/data/project/59/08.png",
      "/data/project/59/09.jpg",
      "/data/project/59/10.png"
    ],
    "users": [
      4
    ]
  },
  {
    "id": 60,
    "thumbnail": "/data/project/60/thumbnail.jpg",
    "name": "Avery",
    "lab": "ux",
    "descript": "Avery는 자율주행차 시스템을 윤반으로 한 여행 플랫폼입니다. 자율주행 시대가 가까워질수록 그에 맞게 디자인된 서비스가 필요할것입니다. 여행 중에는 이동에 불편함이 많은데, 상황에 따라 유동적으로 움직일 수 있는 자율주행차를 이용하여 문제를 해결하고 새로운 경험을 제안하고자 합니다. 노동 같은 여행을 편안하고 새롭게 만드는 것이 이 서비스의 목표입니다.",
    "files": [
      "/data/project/60/01.jpg",
      "/data/project/60/02.gif",
      "/data/project/60/03.jpg",
      "/data/project/60/04.jpg",
      "/data/project/60/05.jpg",
      "/data/project/60/06.gif",
      "/data/project/60/07.jpg",
      "/data/project/60/08.gif",
      "/data/project/60/09.jpg",
      "/data/project/60/10.gif",
      "/data/project/60/11.jpg",
      "/data/project/60/12.gif",
      "/data/project/60/13.jpg",
      "/data/project/60/14.jpg",
      "/data/project/60/15.jpg"
    ],
    "users": [
      16
    ]
  },
  {
    "id": 61,
    "thumbnail": "/data/project/61/thumbnail.png",
    "thumbnail_larg": "/data/project/61/thumbnail_larg.png",
    "name": "Cabinet",
    "lab": "ux",
    "descript": null,
    "files": [
      "/data/project/61/01.png",
      "/data/project/61/02.gif",
      "/data/project/61/03.png",
      "/data/project/61/04.gif",
      "/data/project/61/05.png",
      "/data/project/61/06.png",
      "/data/project/61/07.png",
      "/data/project/61/08.png",
      "/data/project/61/09.png",
      "/data/project/61/10.gif",
      "/data/project/61/11.png",
      "/data/project/61/12.png",
      "/data/project/61/13.png",
      "/data/project/61/14.png",
      "/data/project/61/15.png",
      "/data/project/61/16.png",
      "/data/project/61/17.png",
      "/data/project/61/18.png"
    ],
    "users": [
      38
    ]
  },
  {
    "id": 62,
    "thumbnail": "/data/project/62/thumbnail.png",
    "name": "Grida",
    "lab": "ux",
    "descript": null,
    "files": [
      "/data/project/62/01.png",
      "/data/project/62/02.jpg",
      "/data/project/62/03.png",
      "/data/project/62/04.gif",
      "/data/project/62/05.gif",
      "/data/project/62/06.jpg",
      "/data/project/62/07.gif",
      "/data/project/62/08.gif",
      "/data/project/62/09.gif",
      "/data/project/62/10.gif",
      "/data/project/62/11.gif",
      "/data/project/62/12.jpg"
    ],
    "users": [
      12
    ]
  },
  {
    "id": 63,
    "thumbnail": "/data/project/63/thumbnail.png",
    "thumbnail_larg": "/data/project/63/thumbnail_larg.png",
    "name": "phew",
    "lab": "ux",
    "descript": null,
    "files": [
      "/data/project/63/01.png",
      "/data/project/63/02.png",
      "/data/project/63/03.png",
      "/data/project/63/04.png",
      "/data/project/63/05.png",
      "/data/project/63/06.gif",
      "/data/project/63/07.gif",
      "/data/project/63/08.png",
      "/data/project/63/09.png"
    ],
    "users": [
      3,
      43
    ]
  },
  {
    "id": 64,
    "thumbnail": "/data/project/64/thumbnail.jpg",
    "name": "Animore",
    "lab": "ux",
    "descript": "상대적으로 동물권이 낮은 가축들의 인식개선을 위한 시뮬레이션+스토리 게임",
    "files": [
      "/data/project/64/01.jpg",
      "YOUTUBE_EMBED/S3t37sWomSk",
      "/data/project/64/02.jpg",
      "YOUTUBE_EMBED/u59UHe5Bfug",
      "/data/project/64/03.jpg"
    ],
    "users": [
      26,
      33
    ]
  },
  {
    "id": 65,
    "thumbnail": null,
    "name": "Squarekilometer",
    "lab": "ux",
    "descript": "이 프로젝트는 소비자에게 보다 원활한 도서를 유통하고, 컨텐츠 생산자에게도 안정적인 수익원을 제공하고자 하는 취지로 기획된 프로젝트입니다. Squarekilometer는 출판유통과정에 속해있는 모든 이들에게 그 이윤을 최대한 균등하게 분배하며 플랫폼 내부에서 지속가능한 생태계를 구축함으로써, 이해관계자들간의 긍정적 상호순환을 만드는것이 궁극적인 목표입니다.",
    "files": [
      "/data/project/65/01.png",
      "/data/project/65/02.png",
      "/data/project/65/03.png",
      "/data/project/65/04.png",
      "/data/project/65/05.png",
      "/data/project/65/06.png",
      "/data/project/65/07.png",
      "/data/project/65/08.png",
      "/data/project/65/09.png",
      "/data/project/65/10.png",
      "/data/project/65/11.png",
      "/data/project/65/12.gif",
      "/data/project/65/13.gif",
      "/data/project/65/14.gif"
    ],
    "users": [
      32
    ]
  },
  {
    "id": 66,
    "thumbnail": "/data/project/66/thumbnail.png",
    "thumbnail_larg": "/data/project/66/thumbnail_larg.png",
    "name": "Foodmarble",
    "lab": "ux",
    "descript": "푸드마블 서비스를 통해 취향에 맞는 맛집을 추천받고, 맛집 빙고판을 채우며 새로운 여행경험을 즐겨 보세요. Taste your Taste!",
    "files": [
      "/data/project/66/01.png"
    ],
    "users": [
      27
    ]
  },
  {
    "id": 67,
    "thumbnail": "/data/project/67/thumbnail.png",
    "name": "PLOT",
    "lab": "ux",
    "descript": "플롯은 기존 전시 시장이 가지고 있던 다양한 콘텐츠를 온라인 서비스로 재구축하여 플랫폼 내에 새로운 생태계를 만들어내고, 온라인 전시를 기획, 관람하는 사람들에게 더욱 편리한 비즈니스 활동 및 마케팅을 할수있는 서비스이다. 궁극적으로 웹과 앱의 기능을 활용하여 기존 전시의 한계를 넘어 접근성을 더욱 향상시키고 향유문화의 새로운 장을 여는 데에 목적이 있다.",
    "files": [
      "/data/project/67/01.png",
      "/data/project/67/02.png",
      "/data/project/67/03.png",
      "/data/project/67/04.png",
      "/data/project/67/05.png",
      "/data/project/67/06.png",
      "/data/project/67/08.gif",
      "/data/project/67/09.gif",
      "/data/project/67/10.png",
      "/data/project/67/11.png",
      "YOUTUBE_EMBED/vgbbB4En-FI",
      "/data/project/67/12.png"
    ],
    "users": [
      14,
      24
    ]
  },
  {
    "id": 68,
    "thumbnail": "/data/project/68/thumbnail.png",
    "name": "Synergy",
    "lab": "ux",
    "descript": "다이어터들에게 자신의 생활 사이클에 맞는 메이트를 찾아주고, 지속가능한 다이어트를 도와주는 앱 서비스입니다. 마음이 맞는 친구들를 찾아 다이어트 목표를 이뤄보세요!",
    "files": [
      "/data/project/68/01.png",
      "/data/project/68/02.png",
      "/data/project/68/03.png",
      "/data/project/68/04.png",
      "/data/project/68/05.png",
      "/data/project/68/06.png",
      "/data/project/68/07.png",
      "/data/project/68/08.png",
      "/data/project/68/09.png",
      "/data/project/68/10.png",
      "/data/project/68/11.png",
      "/data/project/68/12.png",
      "/data/project/68/13.png",
      "/data/project/68/14.png",
      "/data/project/68/15.png",
      "/data/project/68/16.png",
      "/data/project/68/17.png",
      "/data/project/68/18.png",
      "/data/project/68/19.png",
      "YOUTUBE_EMBED/AV2CEYrxUHY"
    ],
    "users": [
      20,
      25
    ]
  },
  {
    "id": 69,
    "thumbnail": "/data/project/69/thumbnail.png",
    "thumbnail_larg": "/data/project/69/thumbnail_larg.png",
    "name": "meaningout",
    "lab": "ux",
    "descript": "\"meaning out, mean more\", \"내가 언제, 어떤 것에 관심을 가졌고, 어떻게 관심의 표현을 했는지, 행동누적을 기반으로 나의 영향력을 실감하고 공유하자\"후원자의 ‘내 신념에 기여하고자 하는 심리’에 집중해 그 행동을 누적해 나와 생각이 맞는 브랜드의 리워드로 이어질 수 있는 서비스를 제공하고자 한다.",
    "files": [
      "/data/project/69/01.gif",
      "/data/project/69/02.png",
      "/data/project/69/03.png",
      "/data/project/69/04.png",
      "/data/project/69/05.gif",
      "/data/project/69/06.gif",
      "/data/project/69/07.png",
      "/data/project/69/08.png",
      "/data/project/69/09.png",
      "/data/project/69/10.png"
    ],
    "users": [
      8
    ]
  },
  {
    "id": 70,
    "thumbnail": "/data/project/70/thumbnail.png",
    "thumbnail_larg": "/data/project/70/thumbnail_larg.png",
    "name": "Apick",
    "lab": "ux",
    "descript": "에이픽은 앱 기획부터 출시까지 진행하는 실전 프로젝트로, 일상에서 우리가 느꼈던 온라인 쇼핑의 불편함을 모아 '실패 없이 즐길 수 있는 새로운 쇼핑 방식'을 제안합니다. 개인의 스타일과 체형에 맞게 패션 피플의 코디를 추천하는 큐레이션 쇼핑 플랫폼 에이픽은, 솔직한 후기와 현실감 있는 영상으로 소비자에게 신뢰할 수 있는 쇼핑 환경을 제공하고자 합니다.",
    "files": [
      "/data/project/70/01.gif",
      "/data/project/70/02.png",
      "YOUTUBE_EMBED/4gfPzhjiWwI"
    ],
    "users": [
      1,
      6
    ]
  },
  {
    "id": 71,
    "thumbnail": "/data/project/71/thumbnail.png",
    "thumbnail_larg": "/data/project/71/thumbnail_larg.png",
    "name": "wagle",
    "lab": "ux",
    "descript": null,
    "files": [
      "/data/project/71/01.jpg",
      "YOUTUBE_EMBED/LuujATJ3P44"
    ],
    "users": [
      19,
      34
    ]
  },
  {
    "id": 72,
    "thumbnail": "/data/project/72/thumbnail.png",
    "name": "move",
    "lab": "ux",
    "descript": "도망가지 않고 피하지 않고 다른 행동도 하지 않고 잠시 움직임을 멈추는 외로움을 해소하는 산책",
    "files": [
      "/data/project/72/01.jpg",
      "YOUTUBE_EMBED/45OJqzKJ4dQ",
    ],
    "users": [
      10
    ]
  },
  {
    "id": 73,
    "thumbnail": "/data/project/73/thumbnail.jpg",
    "thumbnail_larg": "/data/project/73/thumbnail_larg.jpg",
    "name": "FARE",
    "lab": "ux",
    "descript": "이 서비스는 레트로 패션을 매개로 사용자들이 서로서로 정보를 공유하면서 레트로 패션에 대한 지식을 발전시키는데 있다. 사용자들이 자신과 비슷한 전문성을 가진 사용자와 매칭이 될 수도 있고, 보다 고급 정보, 자세한 정보를 공유받기를 원한다면, 전문성이 높은 사용자와 매칭이 되어서 레트로 패션에 대한 본인의 사고를 발전시킬 수가 있다.",
    "files": [
      "/data/project/73/01.jpg",
      "/data/project/73/02.jpg",
      "/data/project/73/03.jpg",
      "/data/project/73/04.jpg",
      "/data/project/73/05.jpg",
      "/data/project/73/06.jpg",
      "/data/project/73/07.jpg",
      "YOUTUBE_EMBED/xqaRMSR--oM"

    ],
    "users": [
      30
    ]
  }, {
    "id": 74,
    "thumbnail": "/data/project/74/thumbnail.jpg",
    "name": "PENTHOUSE",
    "lab": "future",
    "descript": "먼 미래에 존재하게 될 운송기기의 모습은 어떠할까. 고정된 Penthouse와 이동하는 Vehicle의 만남이 만들어 내는 AUDI의 새로운 제안을 보여드립니다.",
    "files": [
      "/data/project/74/01.jpg",
      "/data/project/74/02.jpg",
      "/data/project/74/03.jpg",
      "/data/project/74/04.jpg",
      "/data/project/74/05.jpg",
      "/data/project/74/06.jpg",
      "/data/project/74/07.jpg",
      "/data/project/74/08.jpg",
      "/data/project/74/09.jpg",
      "/data/project/74/10.jpg",
      "/data/project/74/11.jpg",
      "YOUTUBE_EMBED/aTGMxJjF3FQ"
    ],
    "users": [
      53
    ]
  }
]