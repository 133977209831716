import cover_exhi_2020 from "Assets/main/2020/cover_exhibition.png";
import poster_01 from "Assets/main/2020/posters/poster01.png";
import poster_02 from "Assets/main/2020/posters/poster02.png";
import poster_03 from "Assets/main/2020/posters/poster03.png";
import poster_04 from "Assets/main/2020/posters/poster04.png";

export const species = {
  cover_exhibition: cover_exhi_2020,
  text_exhibition: {
    title: '당신의 종은 무엇인가?',
    text: `현재 우리는 어떤 대상을 단편적으로 정의할 수 없는 다양성의 시대에 살고 있다. 이는 '건국대학교 산업디자인학과'도 마찬가지이다. '건국대학교 산업디자인학과'는 퓨쳐, UX, 공간, 메타디자인 4가지 랩에서 다양한 배움을 추구한다. 이번 전시 'Species!'는 우리 학과만의 4가지 랩 정체성을 4종류의 ‘종’으로 구분해본다. 자신을 분류하고 세분화하는 이번 전시를 통해 졸업생들은 각자 자신만의 고유성을 발견하여 사회로 자신 있는 첫발을 내디뎌 보고자 한다.`,
  },
  posters: [
    poster_01,
    poster_02,
    poster_03,
    poster_04,
  ],
};