export default [{
		"id": 1,
		"name_kr": "강지은",
		"name_en": "JiEun Kang",
		"contact": [
			"wldmsdl6985@gmail.com",
			"www.instagram.com/1128.kk",
			"www.behance.net/1128kk",
			"https://apick.kr",
		],
		"image": "/data/profile/800/jiEunKang.jpg",
		"bio": null,
		"project": [
			7,
			70
		]
	},
	{
		"id": 2,
		"name_kr": "강혜빈",
		"name_en": "HyeBeen Kang",
		"contact": [
			"iiia_@naver.com",
			"www.instagram.com/wisd0m_shine"
		],
		"image": "/data/profile/800/hyeBeenKang.jpg",
		"bio": null,
		"project": [
			10
		]
	},
	{
		"id": 3,
		"name_kr": "김다빈",
		"name_en": "DaBin Kim",
		"contact": [
			"dgim0928@gmail.com"
		],
		"image": "/data/profile/800/daBinKim.jpg",
		"bio": null,
		"project": [
			5,
			63
		]
	},
	{
		"id": 4,
		"name_kr": "김민지",
		"name_en": "MinJi Kim",
		"contact": [
			"maskavole@konkuk.ac.kr"
		],
		"image": "/data/profile/800/minJiKim.jpg",
		"bio": null,
		"project": [
			5,
			59
		]
	},
	{
		"id": 5,
		"name_kr": "김선진",
		"name_en": "SunJin Kim",
		"contact": [
			"ksj506202@gmail.com"
		],
		"image": "/data/profile/800/sunJinKim.jpg",
		"bio": null,
		"project": [
			4,
			41
		]
	},
	{
		"id": 6,
		"name_kr": "김아빈",
		"name_en": "AhBin Kim",
		"contact": [
			"dkqls2005@nate.com",
			"www.instagram.com/apick_official/",
			"https://apick.kr",
		],
		"image": "/data/profile/800/ahBinKim.jpg",
		"bio": null,
		"project": [
			7,
			70
		]
	},
	{
		"id": 7,
		"name_kr": "김유진",
		"name_en": "YuJin Kim",
		"contact": [
			"wls67290@naver.com"
		],
		"image": "/data/profile/800/yuJinKim.jpg",
		"bio": null,
		"project": [
			13,
			25
		]
	},
	{
		"id": 8,
		"name_kr": "김윤아",
		"name_en": "YunA Kim",
		"contact": [
			"yoona0704@naver.com",
			"www.instagram.com/yunakim_m/",
			"www.behance.net/brightfull194a",
		],
		"image": "/data/profile/800/yunAKim.jpg",
		"bio": null,
		"project": [
			7,
			69
		]
	},
	{
		"id": 9,
		"name_kr": "김종승",
		"name_en": "JongSeung Kim",
		"contact": [
			"yosuba@naver.com"
		],
		"image": "/data/profile/800/jongSeungKim.jpg",
		"bio": null,
		"project": [
			26,
			33
		]
	},
	{
		"id": 10,
		"name_kr": "김지수",
		"name_en": "JiSu Kim",
		"contact": [
			"ssian025@gmail.com",
			"www.behance.net/lalalalolyf0fc"
		],
		"image": "/data/profile/800/jiSuKim.jpg",
		"bio": "마음+잇다+디자인",
		"project": [
			39,
			72
		]
	},
	{
		"id": 11,
		"name_kr": "김지해",
		"name_en": "JiHae Kim",
		"contact": [
			"orcide@naver.com",
			"www.instagram.com/ground_sun01",
			"www.behance.net/orcide"
		],
		"image": "/data/profile/800/jiHaeKim.jpg",
		"bio": null,
		"project": [
			7,
			50
		]
	},
	{
		"id": 12,
		"name_kr": "김호윤",
		"name_en": "HoYun Kim",
		"contact": [
			"hoyun1128@gmail.com",
			"www.instagram.com/ho_ove_ly"
		],
		"image": "/data/profile/800/hoYunKim.jpg",
		"bio": "​안녕하세요 밝은 에너지를 전파하고싶은 디자이너 김호윤입니다! 어두운 밤을 밝히는 달 처럼 밝은 에너지를 담은 디자인을 통해 사람들에게 즐거움을 선물하고싶습니다.",
		"project": [
			14,
			62
		]
	},
	{
		"id": 13,
		"name_kr": "김형진",
		"name_en": "Hyungjin Kim",
		"contact": [
			"sotoddlf75@gmail.com",
			"www.instagram.com/joseph_bro.jin",
			"www.behance.net/Hyungjin Kim"
		],
		"image": "/data/profile/800/hyungjinKim.jpg",
		"bio": null,
		"project": [
			16,
			52
		]
	},
	{
		"id": 14,
		"name_kr": "모한울",
		"name_en": "HanOul Mo",
		"contact": [
			"big3158@naver.com"
		],
		"image": "/data/profile/800/hanOulMo.jpg",
		"bio": null,
		"project": [
			5,
			67
		]
	},
	{
		"id": 15,
		"name_kr": "문예진",
		"name_en": "YeJin Moon",
		"contact": [
			"yejinsm7@gmail.com"
		],
		"image": "/data/profile/800/yeJinMoon.jpg",
		"bio": null,
		"project": [
			12,
			58
		]
	},
	{
		"id": 16,
		"name_kr": "박수지",
		"name_en": "SuJi Park",
		"contact": [
			"egoista982@gmail.com",
			"www.instagram.com/egoista2999"
		],
		"image": "/data/profile/800/suJiPark.jpg",
		"bio": "안녕하세요 :) 올바른 가치를 선물하고 싶은 예비디자이너 박수지입니다. 눈에 보이지 않는 본질과 가치를 찾아 사람들에게 보여주기 위해 고민하고 더 나은 세상을 꿈꾸고 있습니다.",
		"project": [
			32,
			60
		]
	},
	{
		"id": 17,
		"name_kr": "박세은",
		"name_en": "SeEun Park",
		"contact": [
			"enya336@gmail.com"
		],
		"image": "/data/profile/800/seEunPark.jpg",
		"bio": null,
		"project": [
			8,
			46
		]
	},
	{
		"id": 18,
		"name_kr": "박지은",
		"name_en": "JiEun Park",
		"contact": [
			"jieun961227@gmail.com",
			"www.instagram.com/_staccato__"
		],
		"image": "/data/profile/800/jiEunPark.jpg",
		"bio": null,
		"project": [
			43,
			53
		]
	},
	{
		"id": 19,
		"name_kr": "박지현",
		"name_en": "JiHyun Park",
		"contact": [
			"qkrwlgus471300@gmail.com",
			"www.instagram.com/merry_may_",
			"www.behance.net/jihyun_park_"
		],
		"image": "/data/profile/800/jiHyunPark.jpg",
		"bio": null,
		"project": [
			27,
			71
		]
	},
	{
		"id": 20,
		"name_kr": "방지원",
		"name_en": "JiWon Bang",
		"contact": [
			"bjw.d.studio@gmail.com"
		],
		"image": "/data/profile/800/jiWonBang.jpg",
		"bio": null,
		"project": [
			5,
			68
		]
	},
	{
		"id": 21,
		"name_kr": "방진영",
		"name_en": "JinYoung Bang",
		"contact": [
			"qkdwlsdud97@naver.com",
			"www.instagram.com/bb_vian"
		],
		"image": "/data/profile/800/jinYoungBang.jpg",
		"bio": null,
		"project": [
			22,
			36
		]
	},
	{
		"id": 22,
		"name_kr": "변다혜",
		"name_en": "DaHye Byeon",
		"contact": [
			"qusekgp0123@gmail.com"
		],
		"image": "/data/profile/800/daHyeByeon.jpg",
		"bio": "안녕하세요, 공간/ 제품디자인으로 졸업프로젝트를 진행한 변다혜입니다. ‘빠름’ 보다는 ‘느린’이 잘 어울리는 사람으로 천천히 제 흔적을 디자인에 녹여내고 싶습니다.",
		"project": [
			15,
			39
		]
	},
	{
		"id": 23,
		"name_kr": "백진우",
		"name_en": "JinWoo Baek",
		"contact": [
			"jinwoobaek27@gmail.com"
		],
		"image": "/data/profile/800/jinWooBaek.jpg",
		"bio": null,
		"project": [
			20,
			44
		]
	},
	{
		"id": 24,
		"name_kr": "서혜림",
		"name_en": "Hyerim Seo",
		"contact": [
			"sunshi0203@gmail.com",
			"www.instagram.com/haerim_m_",
			"www.behance.net/sunshl0203"
		],
		"image": "/data/profile/800/hyerimSeo.jpg",
		"bio": null,
		"project": [
			5,
			67
		]
	},
	{
		"id": 25,
		"name_kr": "서혜인",
		"name_en": "HaeIn Seo",
		"contact": [
			"shi3719@naver.com",
			"www.behance.net/haein3719e28a"
		],
		"image": "/data/profile/800/haeInSeo.jpg",
		"bio": null,
		"project": [
			1,
			68
		]
	},
	{
		"id": 26,
		"name_kr": "손지호",
		"name_en": "JiHo Son",
		"contact": [
			"on_in@naver.com"
		],
		"image": "/data/profile/800/jiHoSon.jpg",
		"bio": null,
		"project": [
			33,
			64
		]
	},
	{
		"id": 27,
		"name_kr": "양홍주",
		"name_en": "HongJu Yang",
		"contact": [
			"manofclassic@naver.com",
			"www.behance.net/Freeman Y"
		],
		"image": "/data/profile/800/hongJuYang.jpg",
		"bio": null,
		"project": [
			44,
			66
		]
	},
	{
		"id": 28,
		"name_kr": "오유미",
		"name_en": "YuMi Oh",
		"contact": [
			"voix3769@naver.com"
		],
		"image": "/data/profile/800/yuMiOh.jpg",
		"bio": null,
		"project": [
			34,
			57
		]
	},
	{
		"id": 29,
		"name_kr": "유현하",
		"name_en": "HyeonHa Yu",
		"contact": [
			"yuhh325@gmail.com"
		],
		"image": "/data/profile/800/hyeonHaYu.jpg",
		"bio": null,
		"project": [
			14,
			54
		]
	},
	{
		"id": 30,
		"name_kr": "유현희",
		"name_en": "HyunHee Yoo",
		"contact": [
			"kyoo5018@naver.com",
			"www.instagram.com/bstlove1995"
		],
		"image": "/data/profile/800/hyunHeeYoo.jpg",
		"bio": null,
		"project": [
			9,
			73
		]
	},
	{
		"id": 31,
		"name_kr": "유효정",
		"name_en": "HyoJeong Yu",
		"contact": [
			"123123jyk@gmail.com",
			"www.behance.net/123123jyk4"
		],
		"image": "/data/profile/800/hyoJeongYu.jpg",
		"bio": null,
		"project": [
			45,
			56
		]
	},
	{
		"id": 32,
		"name_kr": "윤태웅",
		"name_en": "TaeWoong Yoon",
		"contact": [
			"ibt1816@gmail.com"
		],
		"image": "/data/profile/800/taeWoongYoon.jpg",
		"bio": null,
		"project": [
			65
		]
	},
	{
		"id": 33,
		"name_kr": "이병현",
		"name_en": "ByungHyun Lee",
		"contact": [
			"vibaldi200@gmail.com"
		],
		"image": "/data/profile/800/byungHyunLee.jpg",
		"bio": null,
		"project": [
			33,
			64
		]
	},
	{
		"id": 34,
		"name_kr": "이윤희",
		"name_en": "YoonHee Lee",
		"contact": [

			"yunhee2997@gmail.com",
			"www.instagram.com/unnniing_",
			"www.behance.net/yunhee2997"
		],
		"image": "/data/profile/800/yoonHeeLee.jpg",
		"bio": null,
		"project": [
			27,
			71
		]
	},
	{
		"id": 35,
		"name_kr": "이창화",
		"name_en": "ChangHwa Lee",
		"contact": [
			"cjswodlckdgh@naver.com"
		],
		"image": "/data/profile/800/changHwaLee.jpg",
		"bio": null,
		"project": [
			31,
			49
		]
	},
	{
		"id": 36,
		"name_kr": "이현진",
		"name_en": "HyeonJin Lee",
		"contact": [
			"svs4545@gmail.com",
			"www.instagram.com/bloommms"
		],
		"image": "/data/profile/800/hyeonJinLee.jpg",
		"bio": null,
		"project": [
			43,
			50
		]
	},
	{
		"id": 37,
		"name_kr": "임두희",
		"name_en": "DooHee Lim",
		"contact": [
			"dooheelim@naver.com",
			"www.behance.net/ddoit",
			"www.instagram.com/ddoit_1112/"
		],
		"image": "/data/profile/800/dooHeeLim.jpg",
		"bio": null,
		"project": [
			6,
			29
		]
	},
	{
		"id": 38,
		"name_kr": "임소영",
		"name_en": "SoYoung Ihm",
		"contact": [
			"dlasy102@gmail.com",
			"www.instagram.com/ihmzyong"
		],
		"image": "/data/profile/800/soYoungIhm.jpg",
		"bio": null,
		"project": [
			5,
			61
		]
	},
	{
		"id": 39,
		"name_kr": "임재현",
		"name_en": "JaeHyeon Lim",
		"contact": [
			"imjh0927@gmail.com",
			"www.instagram.com/j.hand27"
		],
		"image": "/data/profile/800/jaeHyeonLim.jpg",
		"bio": [
			"Craft",
			"Directing"
		],
		"project": [
			2,
			18
		]
	},
	{
		"id": 40,
		"name_kr": "임채은",
		"name_en": "ChaeEun Im",
		"contact": [
			"shillakid@naver.com",
			"www.instagram.com/iceceiii"
		],
		"image": "/data/profile/800/chaeEunIm.jpg",
		"bio": null,
		"project": [
			19,
			48
		]
	},
	{
		"id": 41,
		"name_kr": "장용해",
		"name_en": "YongHae Jang",
		"contact": [
			"dydgo44@naver.com"
		],
		"image": "/data/profile/800/yongHaeJang.jpg",
		"bio": null,
		"project": [
			28,
			42
		]
	},
	{
		"id": 42,
		"name_kr": "정서연",
		"name_en": "SeoYeon Jeong",
		"contact": [
			"sally971202@gmail.com",
			"www.instagram.com/itsme.yonii"
		],
		"image": "/data/profile/800/seoYeonJeong.jpg",
		"bio": null,
		"project": [
			29,
			37
		]
	},
	{
		"id": 43,
		"name_kr": "정지원",
		"name_en": "Jiwon Ashley Chung",
		"contact": [
			"rhrh0203@gmail.com",
			"www.behance.net/rnldyal0203"
		],
		"image": "/data/profile/800/ashleyChung.jpg",
		"bio": null,
		"project": [
			5,
			63
		]
	},
	{
		"id": 44,
		"name_kr": "전소원",
		"name_en": "SoWon Jeon",
		"contact": [
			"wjsthdnjs9@gmail.com"
		],
		"image": "/data/profile/800/soWonJeon.jpg",
		"bio": null,
		"project": [
			11
		]
	},
	{
		"id": 45,
		"name_kr": "주방기",
		"name_en": "fangqi zhu",
		"contact": [
			"banggy97@naver.com",
			"www.instagram.com/tw_ty_020"
		],
		"image": "/data/profile/800/fangFiZhu.jpg",
		"bio": null,
		"project": [
			21,
			35
		]
	},
	{
		"id": 46,
		"name_kr": "최민주",
		"name_en": "MinJu Choi",
		"contact": [
			"minju_c0808@naver.com",
			"www.instagram.com/min_j0o/",
		],
		"image": "/data/profile/800/minJuChoi.jpg",
		"bio": null,
		"project": [
			23,
			36
		]
	},
	{
		"id": 47,
		"name_kr": "최찬희",
		"name_en": "ChanHee Choi",
		"contact": [
			"opqr9410@naver.com",
			"www.instagram.com/zzanzzani"
		],
		"image": "/data/profile/800/chanHeeChoi.jpg",
		"bio": null,
		"project": [
			17,
			40
		]
	},
	{
		"id": 48,
		"name_kr": "탁준성",
		"name_en": "JoonSung Tak",
		"contact": [
			"joonsungtak@gmail.com",
			"www.behance.net/joonsungtak"
		],
		"image": "/data/profile/800/joonSungTak.jpg",
		"bio": null,
		"project": [
			1,
			51
		]
	},
	{
		"id": 49,
		"name_kr": "하현아",
		"name_en": "HyunA Hah",
		"contact": [
			"phobiaxxgirl@gmail.com",
			"www.instagram.com/phobiaxxgirl"
		],
		"image": "/data/profile/800/hyunAHah.jpg",
		"bio": null,
		"project": [
			3,
			38
		]
	},
	{
		"id": 50,
		"name_kr": "허재훈",
		"name_en": "JaeHoon Heo",
		"contact": [
			"HEOJHH@gmail.com"
		],
		"image": "/data/profile/800/jaeHoonHeo.jpg",
		"bio": null,
		"project": [
			24,
			36
		]
	},
	{
		"id": 51,
		"name_kr": "이지원",
		"name_en": "JiWon Lee",
		"contact": [
			"eessy0023@gmail.com"
		],
		"image": "/data/profile/800/jiWonLee.jpg",
		"bio": null,
		"project": [
			47,
			9
		]
	},
	{
		"id": 52,
		"name_kr": "이준화",
		"name_en": "JunHua Li",
		"contact": [
			"787449833@qq.com"
		],
		"image": "/data/profile/800/junHuaLi.jpg",
		"bio": null,
		"project": [
			30,
			35
		]
	},
	{
		"id": 53,
		"name_kr": "김은오",
		"name_en": "EuNo Kim",
		"contact": [
			"kmi9311@gmail.com"
		],
		"image": "/data/profile/800/euNoKim.jpg",
		"bio": null,
		"project": [
			55, 74
		]
	}
]