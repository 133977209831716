import React from "react";
import AdminLayout from "../Components/AdminLayout";
import AdminTitle from "../Components/AdminTitle";
import InputPw from "../Components/Form/InputPw";
import AdminContainer from "../Components/AdminContainer";
import styled from "styled-components";
import { useInput } from "../Hooks/hooks";
import Submit from "../Components/Form/Submit";
import InputEmail from "../Components/Form/InputEmail";
import { fbSignin } from "../Firebase/firebase";

const SigninForm = styled.form`
	width: 100%;
`;

const Signin = () => {
	const id = useInput("");
	const pw = useInput("");

	const handleOnSubmit = async (e) => {
		e.preventDefault();
		const result = await fbSignin(id.value, pw.value);
		if (!result) {
			alert("아이디 / 비밀번호가 틀립니다.");
		}
	};

	return (
		<AdminLayout>
			<AdminTitle title={"로그인"} />

			<AdminContainer>
				<SigninForm onSubmit={handleOnSubmit}>
					<InputEmail label="아이디" value={id.value} placeholder="아이디" onChange={id.onChange} />
					<InputPw label="비밀번호" value={pw.value} placeholder="비밀번호" onChange={pw.onChange} />
					<Submit value={"확인"} />
				</SigninForm>
			</AdminContainer>
		</AdminLayout>
	);
};

export default Signin;
