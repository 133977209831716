import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Ionicons from "../../Assets/icon/Ionicons";
import Theme from "../../Styles/Theme";
import InputFileWrapper from "./InputFileWrapper";
import { getThumbnail } from "../../Firebase/firebase";
import { device } from "../../Styles/Media";

const Wrapper = styled(InputFileWrapper)`
	width: 50%;

	@media ${device.tablet} {
		width: 100%;
	}

	input {
		display: none;
	}
`;
const File = styled.label`
	border: 1px solid ${(props) => props.theme.color.input.default};
	border-radius: ${(props) => props.theme.input_borderRatio};
	width: 25%;
	min-width: 200px;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	&::after {
		content: "";
		display: block;
		padding-top: 100%;
	}

	@media ${device.hover} {
		&:hover {
			border-color: ${(props) => props.theme.color.input.active};
			svg {
				color: black;
			}
		}
	}

	@media ${device.mobileL} {
		width: 100%;
	}
`;

const FileInner = styled.div`
	${(props) => props.theme.fullAbs};
	${(props) => props.theme.centerFlex};
	${(props) => props.theme.bgFull};
	background-size: contain;
	background-image: ${(props) => props.bg && `url('${props.bg}')`};
`;
const InputFile = ({ name, label, nowFile, id, setFile }) => {
	const [isEnter, setIsEnter] = useState(false);
	const [nowImage, setNowImage] = useState(nowFile);

	useEffect(() => {
		setNowImage(nowFile);
	}, [nowFile]);

	const handleMouseEnter = () => {
		setIsEnter(true);
	};
	const handleMouseLeave = () => {
		setIsEnter(false);
	};

	const handleFileChange = (e) => {
		setFile(e.target.files[0]);
		getThumbnail(e.target, changeThumbnail);
	};

	const changeThumbnail = (_, url) => {
		setNowImage(url);
	};

	return (
		<Wrapper label={label} id={id}>
			<File onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
				<input name={name} type="file" id={id} onChange={handleFileChange} />
				<FileInner bg={nowImage}>
					<Ionicons name={"add"} fontSize="24px" color={!isEnter ? Theme.color.input.default : Theme.color.input.active} />
				</FileInner>
			</File>
		</Wrapper>
	);
};

export default InputFile;
