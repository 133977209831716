import React, { createContext, useState, useEffect } from "react";
import { Route, Switch, useLocation, useHistory, Redirect } from "react-router-dom";
import { Exhibition, Main, NotFound, Search, Project, ProfileDetail, NeedChrome } from "./Routes";
import Profile from "./Routes/Profile";

import Header from "Components/Header";
import { useSize } from "./Hooks/hooks";
import { size as sizeMedia } from "./Styles/Media";
import { makeFullHeight, preventBodyScroll, defaultBodyScroll, checkBrowser } from "./Utility";

import smoothscroll from "smoothscroll-polyfill";
import Footer from "./Components/Footer";
import SearchOverlay from "./Components/SearchOverlay";
import { AdminSignin, AdminMain, AdminAdd } from "./Admin";
import { fbGetData, fbAuthListener, fbAnalytics } from "./Firebase/firebase";

// context
export const AppContext = createContext();

// FIELD
export const COL = "exhibition";

export default () => {
	const { pathname } = useLocation();
	const { push } = useHistory();
	const [isSearchClick, setIsSearchClick] = useState(false);
	const [isPortrait, setIsPortrait] = useState(false);
	const [adminYear, setAdminYear] = useState();
	const [data, setData] = useState();
	const [auth, setAuth] = useState();
	// MEDIA
	const [nowMedia, setNowMedia] = useState(null);
	const size = useSize();
	const [nowYear, setNowYear] = useState();

	useEffect(() => {
		fbAnalytics();
		fbAuthListener(setAuth);
	}, []);

	// get data
	useEffect(() => {
		const getData = async () => {
			const db = await fbGetData(COL, "year", "desc");
			setData(db);
		};
		getData();
	}, []);

	// prevent window scroll when search
	useEffect(() => {
		if (isSearchClick) {
			preventBodyScroll();
		} else {
			defaultBodyScroll();
		}
	}, [isSearchClick]);

	// check borwser & redirect
	useEffect(() => {
		const check = async () => {
			const result = checkBrowser();
			if (result === "11.0" || result === "10.0" || result === "9.0") {
				push("/supported_browsers");
			}
		};
		check();
	}, [push]);
	// smooth scrol polyfill
	useEffect(() => {
		makeFullHeight();
		smoothscroll.polyfill();
	}, []);

	useEffect(() => {
		setNowYear(pathname.split("/")[1]);
	}, [pathname]);
	// scroll to top when page change
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	// set media
	useEffect(() => {
		if (sizeMedia.mobileL >= size[0]) {
			setNowMedia(sizeMedia.mobileL);
		} else if (sizeMedia.tablet >= size[0]) {
			setNowMedia(sizeMedia.tablet);
		} else if (sizeMedia.laptopL >= size[0]) {
			setNowMedia(sizeMedia.laptopL);
		} else {
			setNowMedia(null);
		}
	}, [size]);
	// check portrait or landscape
	useEffect(() => {
		if (size[0] > size[1]) {
			setIsPortrait(false);
		} else {
			setIsPortrait(true);
		}
	}, [size]);

	return (
		<>
			<AppContext.Provider value={{ data, setData, isPortrait, nowMedia, nowYear, setNowYear, isSearchClick, setIsSearchClick, adminYear, setAdminYear }}>
				{/* HEADER */}
				{pathname !== "/" && <Header />}

				{/* SEARCH */}
				{isSearchClick && <SearchOverlay />}

				{/* ROUTES */}
				<Switch>
					{/* etc */}
					<Route path="/notfound" component={NotFound} />
					<Route path="/supported_browsers" component={NeedChrome} />

					{/* admin */}
					<Redirect exact path="/admin" to="/admin/signin" />
					<Route exact path="/admin/signin" render={() => (!auth ? <AdminSignin /> : <Redirect to="/admin/main" />)} />
					<Route exact path="/admin/main" render={() => (auth ? <AdminMain /> : <Redirect to="/admin/signin" />)} />
					<Route exact path="/admin/form" render={() => (auth ? <AdminAdd /> : <Redirect to="/admin/signin" />)} />

					{/* router */}
					<Route exact path="/" component={Main} />
					<Route exact path="/species" component={Exhibition} />
					<Route path="/species/project" component={Project} />
					<Route path="/species/profile/:id" component={ProfileDetail} />
					<Route path="/species/profile" component={Profile} />
					<Route path="/species/search" component={Search} />
				</Switch>

				{/* FOOTER */}
				{pathname !== "/" && nowYear !== "admin" && <Footer />}
			</AppContext.Provider>
		</>
	);
};
