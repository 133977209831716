import React from "react";
import styled from "styled-components";
import { device } from "../../Styles/Media";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-end;

	> input {
		background-color: black;
		color: white;
		border-radius: ${(props) => props.theme.input_borderRatio};
		border: none;
		padding: 0.6rem 1rem;
		font-size: 16px;
		line-height: 1.4;
	}

	@media ${device.tablet} {
		position: fixed;
		bottom: 0;
		width: 100%;
		left: 0;
		> input {
			width: 100%;
			border-radius: 0;
			padding: 1.5rem;
		}
	}
`;
const Submit = ({ value }) => {
	return (
		<Wrapper>
			<input type="submit" value={value} />
		</Wrapper>
	);
};

export default Submit;
