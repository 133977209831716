import React from "react";
import styled from "styled-components";
import ListContainerDefulat from "../../Components/ListContainerDefulat";
import ProjectList from "../../Components/ProjectList";
import { device } from "../../Styles/Media";
import FullHeight from "../../Components/FullHeight";
import ProjectDetail from "../../Components/ProjectDetail";
import ProfileList from "../../Components/ProfileList";

const FullHeightInit = styled(FullHeight)`
	padding-top: ${(props) => props.theme.height_header_pc};
	@media ${device.laptopL} {
		padding-top: ${(props) => props.theme.height_header_laptopL};
	}
	@media ${device.tablet} {
		padding-top: ${(props) => props.theme.height_header_tablet};
	}
	@media ${device.mobileL} {
		padding-top: ${(props) => props.theme.height_header_mobileL};
	}
`;

const Search = styled(ListContainerDefulat)`
	padding-top: 6rem;
	min-height: ${(props) => `calc(100vh - ${props.theme.height_header_pc})`};
	display: flex;

	@media ${device.laptopL} {
		min-height: ${(props) => `calc(100vh - ${props.theme.height_header_laptopL})`};
		padding-top: 93px;
	}

	@media ${device.tablet} {
		min-height: ${(props) => `calc(100vh - ${props.theme.height_header_tablet})`};
		padding-top: 0;
	}

	@media ${device.mobileL} {
		min-height: ${(props) => `calc(100vh - ${props.theme.height_header_mobileL})`};
	}
`;

const SearchWrapper = styled.div`
	${(props) => props.theme.offset_default};
	width: 100%;

	display: flex;
	flex-direction: column;
`;

const SearchText = styled.div``;
const TextTerm = styled.p`
	${(props) => props.theme.terminaMedium};
	font-size: 1.875rem;
	line-height: 1.2;
	span {
		${(props) => props.theme.notoMedium};
		margin-left: 1.68rem;
	}
	@media ${device.laptopL} {
		font-size: 20px;
		span {
			margin-left: 1rem;
		}
	}
`;
const TextCount = styled.p`
	margin-top: 0.68rem;
	${(props) => props.theme.notoKrMedium};
	font-size: 1.25rem;
	line-height: 1.74;
	margin-bottom: 80px;
	span {
		${(props) => props.theme.notoKrBlack};
	}

	@media ${device.laptopL} {
		font-size: 15px;
	}

	@media ${device.tablet} {
		margin-bottom: 40px;
	}
`;

const SearchContents = styled.div`
	flex-grow: 1;
	position: relative;
`;

const DataFullWrapper = styled.div``;

const DataWrapper = styled.div`
	position: relative;
	&:not(:last-child) {
		margin-bottom: 80px;
		padding-bottom: 52px;
		border-bottom: 1px solid #bababa;

		@media ${device.tablet} {
			margin-bottom: 40px;
			padding-bottom: 12px;
		}
	}
`;

const SubTitle = styled.p`
	${(props) => props.theme.terminaMedium};
	width: 100%;
	margin-bottom: 80px;
	> span {
		margin-left: 16px;
	}

	@media ${device.tablet} {
		margin-bottom: 40px;
	}
`;

const ListWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const NoData = styled.p`
	${(props) => props.theme.terminaBold};
	font-size: 5rem;
	text-transform: capitalize;
	${(props) => props.theme.centerAbs};
	width: 100%;
	text-align: center;

	@media ${device.laptopL} {
		font-size: 2.5rem;
		top: 49px;
	}
	@media ${device.mobileL} {
		font-size: 2rem;
	}
`;

export default ({ term, data, profileData, onProjectClicked, onProfileClciked, nowProject, setNowProject, notFound }) => {
	return (
		<FullHeightInit>
			<Search>
				<SearchWrapper>
					{/* Search text of result */}
					<SearchText>
						<TextTerm>
							Results for<span className="term">'{term}'</span>
						</TextTerm>
						<TextCount>
							총 <span>{data && profileData && data.length + profileData.length}</span>개의 결과물
						</TextCount>
					</SearchText>

					{/* List of Project */}
					<SearchContents>
						{notFound ? (
							<NoData>nothing found</NoData>
						) : (
							<DataFullWrapper>
								{profileData && profileData[0] && (
									<DataWrapper>
										<SubTitle>
											Profile<span>{profileData && profileData[0] && profileData.length}개</span>
										</SubTitle>
										<ListWrapper>
											{profileData &&
												profileData[0] &&
												profileData.map((profile) => {
													return <ProfileList key={profile.id} profile={profile} onClick={onProfileClciked} />;
												})}
										</ListWrapper>
									</DataWrapper>
								)}

								{data && data[0] && (
									<DataWrapper>
										<SubTitle>
											Projects<span>{data && data[0] && data.length}개</span>
										</SubTitle>
										<ListWrapper>
											{data &&
												data[0] &&
												data.map((project) => {
													return <ProjectList key={project.id} project={project} onClick={() => onProjectClicked(project)} />;
												})}
										</ListWrapper>
									</DataWrapper>
								)}
							</DataFullWrapper>
						)}
					</SearchContents>

					{/* list of Profile */}
				</SearchWrapper>
			</Search>
			{nowProject && <ProjectDetail data={nowProject} setNowProject={setNowProject} />}
		</FullHeightInit>
	);
};
