import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	position: relative;
`;

const Label = styled.label`
	margin-bottom: 0.5rem;
	font-size: 14px;
	font-style: italic;
`;

const InputFileWrapper = ({ children, label, className, id }) => {
	return (
		<Wrapper className={className}>
			<Label htmlFor={id}>{label}</Label>

			{children}
		</Wrapper>
	);
};

export default InputFileWrapper;
