export default [{
    name: "future"
  },
  {
    name: "meta"
  },
  {
    name: "space"
  },
  {
    name: "ux"
  },
  {
    name: "profile"
  },
]