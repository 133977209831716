import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	width: 560px;
	margin: 0 auto;
	max-width: 90%;
`;
const AdminContainer = ({ children, className }) => {
	return <Wrapper className={className}>{children}</Wrapper>;
};

export default AdminContainer;
