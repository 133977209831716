import React from "react";
import styled from "styled-components";
import Ionicons from "Assets/icon/Ionicons";
import { device } from "../Styles/Media";

const AddIcon = styled(Ionicons).attrs({ color: "lightgray" })``;

const Wrapper = styled.div`
	width: calc(50% - 8px);
	min-height: 90px;
	border: 1px solid ${(props) => props.theme.color.input.default};
	border-radius: ${(props) => props.theme.input_borderRatio};
	margin-bottom: 16px;
	${(props) => props.theme.centerFlex};
	cursor: pointer;

	@media ${device.hover} {
		&:hover {
			border-color: ${(props) => props.theme.color.input.active};
			${AddIcon} {
				fill: black;
			}
		}
	}
`;

const BtnAddLabList = ({ onClick, className }) => {
	const handleClick = () => {
		onClick();
	};

	return (
		<Wrapper className={className} onClick={handleClick}>
			<AddIcon name="add" color="black" />
		</Wrapper>
	);
};

export default BtnAddLabList;
