import React from "react";
import IosArrowDown from "react-ionicons/lib/IosArrowDown";
import MdCheckmark from "react-ionicons/lib/MdCheckmark";
import MdClose from "react-ionicons/lib/MdClose";
import MdAdd from "react-ionicons/lib/MdAdd";
import MdTrash from "react-ionicons/lib/MdTrash";
import MdCreate from "react-ionicons/lib/MdCreate";

export default ({ name, fontSize, color, style, className, onClick }) => {
	switch (name) {
		case "arrow_down": {
			return <IosArrowDown className={className} onClick={onClick} style={style} fontSize={fontSize} color={color} />;
		}
		case "check": {
			return <MdCheckmark className={className} onClick={onClick} style={style} fontSize={fontSize} color={color} />;
		}
		case "add": {
			return <MdAdd onClick={onClick} style={style} className={className} fontSize={fontSize} color={color} />;
		}
		case "close": {
			return <MdClose className={className} onClick={onClick} style={style} fontSize={fontSize} color={color} />;
		}
		case "edit": {
			return <MdCreate className={className} onClick={onClick} style={style} fontSize={fontSize} color={color} />;
		}
		case "delete": {
			return <MdTrash className={className} onClick={onClick} style={style} fontSize={fontSize} color={color} />;
		}
		default:
			return <></>;
	}
};
