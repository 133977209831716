import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Thumbnail from "./Thumbnail";
import ListTitle from "./ListTitle";
import ListDefault from "./ListDefault";
import { useImageLoad } from "../Hooks/hooks";
import placeholder from "Assets/image/placeholder_list.png";

const ProjectList = styled(ListDefault)``;

const ListContainer = styled.div``;

export default ({ project, onClick }) => {
	const [image, setImage] = useState(`/static${project.thumbnail}`);

	useEffect(() => {
		if (!project.thumbnail) {
			setImage(placeholder);
		}
	}, [project]);

	const { load } = useImageLoad(image);

	return (
		<ProjectList onClick={onClick} active={load}>
			<ListContainer>
				<Thumbnail hover={true} src={image} title={project.descript} text={project.users} />
				<ListTitle text={project.name} />
			</ListContainer>
		</ProjectList>
	);
};
