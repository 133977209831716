import React from "react";
import styled from "styled-components";
const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 4rem;
`;
const Title = styled.h3`
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 24px;
	padding-bottom: 6px;
	width: 100%;
	border-bottom: 1px solid black;
`;
const AdminSection = ({ title, children, className }) => {
	return (
		<Wrapper className={className}>
			<Title>{title}</Title>
			{children}
		</Wrapper>
	);
};

export default AdminSection;
