import React from "react";
import styled from "styled-components";
import Thumbnail from "../../Components/Thumbnail";
import { device } from "../../Styles/Media";
import ProjectDetail from "../../Components/ProjectDetail";
import placeholder from "Assets/image/placeholder_list.png";

const ProfileDetail = styled.section`
	padding-top: ${(props) => `calc(${props.theme.height_header_pc} +  14.25rem)`};
	padding-bottom: 8.5rem;

	@media ${device.laptopL} {
		padding-top: ${(props) => `calc(${props.theme.height_header_laptopL} +  80px)`};
		padding-bottom: 180px;
	}

	@media ${device.tablet} {
		padding-top: ${(props) => props.theme.height_header_tablet};
	}

	@media ${device.mobileL} {
		padding-top: ${(props) => props.theme.height_header_mobileL};
	}
`;

const Container = styled.div`
	${(props) => props.theme.offset_default};
	display: flex;
	justify-content: space-between;

	@media ${device.tablet} {
		flex-direction: column;
	}
`;

const Col = styled.div`
	position: relative;
`;

const ColProfile = styled(Col)`
	width: 24%;

	@media ${device.tablet} {
		display: flex;
		width: 100%;
		align-items: center;
		padding-bottom: 2rem;
		margin-bottom: 3rem;
		border-bottom: 1px solid ${(props) => props.theme.colorPlacehoder};
	}
	@media ${device.mobileL} {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const ColProject = styled(Col)`
	width: 62%;

	@media ${device.tablet} {
		width: 100%;
	}
`;

// PROFILE
const ProfileText = styled.div``;

const ProfileImage = styled(Thumbnail)`
	@media ${device.tablet} {
		width: 160px;
		flex-shrink: 0;
		align-self: flex-start;
		flex-grow: 0;
		margin-right: 1rem;
	}

	@media ${device.mobileL} {
		width: 100%;
	}
`;

const ProfileInfo = styled.div`
	${(props) => props.theme.notoBold};
	font-size: 1.5625rem;
	line-height: 1.5;
	margin-top: 3.3rem;
	margin-bottom: 4.56rem;

	@media ${device.laptopL} {
		font-size: 15px;
		margin-top: 42px;
		margin-bottom: 42px;
	}

	@media ${device.tablet} {
		margin-top: 0.8rem;
		margin-bottom: 1.5rem;
	}
`;
const Name = styled.p`
	margin-bottom: 0.8rem;
	${(props) => props.theme.notoKrBold};
	span {
		margin-left: 1.375rem;
		${(props) => props.theme.notoBold};
	}

	@media ${device.tablet} {
		margin-bottom: 0.5rem;
		span {
			margin-left: 0.5rem;
		}
	}
`;
const Contacts = styled.ul``;
const Contact = styled.li`
	word-break: break-all;
	margin-bottom: 0.8rem;
	&:last-child {
		margin-bottom: 0;
	}
	a {
		word-break: inherit;
	}
`;

const ProfileBio = styled.p`
	font-size: 1.5625rem;
	line-height: 2;
	${(props) => props.theme.notoKrBold};

	@media ${device.laptopL} {
		font-size: 15px;
	}
	@media ${device.laptop} {
		line-height: 1.6;
	}
`;

// PROJECT

const Projects = styled.ul``;
const Project = styled.li`
	cursor: pointer;
	margin-bottom: 2.5rem;
	&:last-child {
		margin-bottom: 0;
	}

	@media ${device.laptopL} {
		margin-bottom: 25px;
	}
`;
const ProjectLap = styled.p`
	margin-top: 0.8rem;
	${(props) => props.theme.notoBold};
	font-size: 1.5625rem;
	text-transform: capitalize;
	line-height: 1.7;

	@media ${device.laptopL} {
		font-size: 15px;
	}

	@media ${device.tablet} {
		font-size: 1.4rem;
	}

	@media ${device.mobileL} {
		font-size: 1.2rem;
	}
`;
const ProjectName = styled.p`
	${(props) => props.theme.notoRegular};
	font-size: 1.4375rem;
	line-height: 1.7;

	@media ${device.laptopL} {
		font-size: 13px;
	}

	@media ${device.tablet} {
		font-size: 1.4rem;
	}

	@media ${device.mobileL} {
		font-size: 1.2rem;
	}
`;

export default ({ nowProfile, onProjectClicked, nowProject, setNowProject }) => {
	return (
		<ProfileDetail>
			<Container>
				{/* PROFILE */}
				<ColProfile>
					<ProfileImage src={`/static${nowProfile.image}`} hover={false} />

					<ProfileText>
						<ProfileInfo>
							<Name>
								{nowProfile.name_kr}
								<span>{nowProfile.name_en}</span>
							</Name>
							<Contacts>
								{nowProfile.contact.map((el, index) => {
									return (
										<Contact key={index}>
											{el.includes("http") || el.includes("www.") ? (
												<a target="_blank" href={el.includes("http") ? el : `https://${el}`} rel="noopener noreferrer">
													{el}
												</a>
											) : (
												el
											)}
										</Contact>
									);
								})}
							</Contacts>
						</ProfileInfo>
						<ProfileBio>{nowProfile.bio}</ProfileBio>
					</ProfileText>
				</ColProfile>

				<ColProject>
					<Projects>
						{nowProfile.project.map((el) => {
							// const thumbnail = `/static${el.thumbnail_larg ? el.thumbnail_larg : el.thumbnail }`
							const thumbnail = el.thumbnail_larg ? `/static${el.thumbnail_larg}` : el.thumbnail ? `/static${el.thumbnail}` : placeholder;
							return (
								<Project key={el.id} onClick={() => onProjectClicked(el.id)}>
									<Thumbnail src={thumbnail} hover={false} />
									<ProjectLap>{el.lab} design lab</ProjectLap>
									<ProjectName>{el.name}</ProjectName>
								</Project>
							);
						})}
					</Projects>
				</ColProject>
			</Container>

			{nowProject && <ProjectDetail data={nowProject} setNowProject={setNowProject} />}
		</ProfileDetail>
	);
};
