import { useState, useLayoutEffect, useEffect } from "react";

export const useSize = () => {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		const updateSize = () => {
			setSize([window.innerWidth, window.innerHeight]);
		};

		window.addEventListener("resize", updateSize);
		updateSize();

		return () => window.removeEventListener("resize", updateSize);
	}, []);

	return size;
};

export const useScrollDirection = () => {
	const [dir, setDir] = useState("");
	const [value, setValue] = useState(0);

	useEffect(() => {
		let lastScrollTop = 0;

		// when scroll device
		const checkDirection = (e) => {
			let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
			setValue(st);
			if (st > lastScrollTop) {
				setDir("down");
			} else {
				setDir("up");
			}

			lastScrollTop = st <= 0 ? 0 : st;
		};

		window.addEventListener("scroll", checkDirection);

		return () => window.removeEventListener("scroll", checkDirection);
	}, []);

	return { dir, value };
};

export const useInput = (defaultValue) => {
	const [value, setValue] = useState(defaultValue);

	const onChange = (e) => {
		const value = e.target.value;
		setValue(value);
	};

	return { value, onChange, setValue };
};

export const useFile = (defaultValue) => {
	const [value, setValue] = useState(defaultValue);

	const onChange = (e) => {
		const value = e.target.files[0];
		setValue(value);
	};

	return { value, onChange, setValue };
};

export const useImageLoad = (src) => {
	const [load, setLoad] = useState(false);

	useEffect(() => {
		const dom = document.createElement("img");
		dom.src = src;
		const setStateLoad = () => {
			setLoad(true);
		};
		dom.addEventListener("load", setStateLoad);
		return () => dom.removeEventListener("load", setStateLoad);
	}, [src]);

	return { load };
};
