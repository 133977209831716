import React from "react";
import styled from "styled-components";
import FullHeight from "../Components/FullHeight";
import img_chrome from "Assets/browser/chrome.png";
import img_edge from "Assets/browser/edge.png";
import img_firefox from "Assets/browser/firefox.png";
import img_opera from "Assets/browser/opera.png";

// FILED
const BROWSERLIST = [
	{
		name: "microsoft edge",
		image: img_edge,
		link: "https://www.microsoft.com/ko-kr/edge"
	},
	{
		name: "google chrome",
		image: img_chrome,
		link: "https://www.google.co.kr/chrome/?brand=CHBD&gclid=Cj0KCQjw6575BRCQARIsAMp-ksOFtXigc47_HvR4v4kvjPJkUIlMr4yigV3M__yJrMRs43n83KcxJFkaAs4FEALw_wcB&gclsrc=aw.ds"
	},
	{
		name: "opera",
		image: img_opera,
		link: "https://www.opera.com/ko/download#computer-browsers"
	},
	{
		name: "mozilla firefox",
		image: img_firefox,
		link: "https://www.mozilla.org/ko/firefox/new/?utm_campaign=supported-browser&utm_medium=referral&utm_source=youtube.com"
	}
];

// STYLED
const Wrapper = styled(FullHeight)`
	height: 100vh;
	background-color: #fff;
	z-index: ${(props) => props.theme.z_notfound};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const TextWrapper = styled.div`
	text-align: center;
	margin-bottom: 2rem;
`;
const Title = styled.h2`
	font-size: 2rem;
	font-weight: 600;
	margin-bottom: 1rem;
`;
const Text = styled.div`
	line-height: 1.6;
	span {
		display: block;
	}
`;
const BrowserWrapper = styled.ul`
	display: flex;
`;
const BrowserList = styled.li`
	text-align: center;
	width: 140px;
	height: 140px;
	position: relative;
	margin-right: 0.8rem;
	border-radius: 16px;
	transition: ${(props) => props.theme.transition_default};
	box-shadow: 0 3px 16px -6px rgba(0, 0, 0, 0.2);
	&:last-child {
		margin-right: 0;
	}
	&:hover {
		/* background-color: rgba(0, 0, 0, 0.08); */
		box-shadow: 0 3px 20px -2px rgba(0, 0, 0, 0.2);
	}
`;
const BrowserLink = styled.a`
	display: block;
	${(props) => props.theme.fullAbs};
	padding: 1rem;
`;

const BrowserImage = styled.img`
	width: 80px;
`;
const BrowserName = styled.p`
	line-height: 1.5;
	font-size: 0.8rem;
	margin-top: 0.2rem;
	color: gray;
`;

const NeedChrome = () => {
	return (
		<Wrapper>
			<TextWrapper>
				<Title>브라우저 지원 안내</Title>
				<Text>
					<span>해당 브라우저는 지원하지 않습니다.</span>
					<span>아래 브라우저를 이용해 보세요. </span>{" "}
				</Text>
			</TextWrapper>
			<BrowserWrapper>
				{BROWSERLIST.map((el, index) => {
					return (
						<BrowserList key={index}>
							<BrowserLink href={el.link}>
								<BrowserImage src={el.image} />
								<BrowserName>{el.name}</BrowserName>
							</BrowserLink>
						</BrowserList>
					);
				})}
			</BrowserWrapper>
		</Wrapper>
	);
};

export default NeedChrome;
