import React, { useRef, useEffect, useState } from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import { useSize } from "../Hooks/hooks";

const Wrapper = styled.div`
	display: flex;
	height: 100%;
	align-items: center;

	img {
		${(props) => props.theme.preventEvent};
		height: 100%;
		transform: translateX(0%);
		flex-shrink: 0;
		flex-grow: 0;
	}
`;

const MarqueeAnimation = ({ src }) => {
	const child = useRef();
	const wrapper = useRef();
	const [childWidth, setChildWidth] = useState();
	const [count, setCount] = useState();

	const size = useSize();

	useEffect(() => {
		document.onreadystatechange = () => {
			setChildWidth(child.current.getBoundingClientRect().width);
		};
	}, []);

	useEffect(() => {
		if (size && childWidth) {
			setCount(Math.ceil(size[0] / childWidth));
		}
	}, [size, childWidth]);

	const handleOnLoaded = (e) => {
		moveMarquee(e.target.getBoundingClientRect().width);
	};

	const moveMarquee = (maxValue) => {
		wrapper.current.animate(
			[
				{ transform: "translateX(0)" },
				{
					transform: `translateX(-${maxValue}px)`
				}
			],
			{
				duration: 50000,
				iterations: Infinity
			}
		);
	};

	return (
		<Wrapper move={childWidth} ref={wrapper}>
			<img src={src} alt="exhibition cover" ref={child} />
			{[...Array(count)].map((_, i) => (
				<img key={i} id="test" src={src} alt="exhibition cover" ref={child} onLoad={handleOnLoaded} />
			))}
		</Wrapper>
	);
};

MarqueeAnimation.propTypes = {
	src: Proptypes.string.isRequired
};

export default MarqueeAnimation;
