import React, { useEffect, useState, useContext } from "react";
import SearchPresentor from "./SearchPresentor";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { searchProject, searchProfile } from "../../Data";
import { preventBodyScroll } from "../../Utility";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../App";

const SearchContainer = withRouter(({ location }) => {
	const [term, setTerm] = useState("");
	const [data, setData] = useState();
	const [nowProject, setNowProject] = useState();
	const [profileData, setProfileData] = useState();
	const [notFount, setNotFount] = useState(false);
	const { push } = useHistory();
	const { nowYear } = useContext(AppContext);

	useEffect(() => {
		const { term } = queryString.parse(location.search);
		setTerm(term);
	}, [location]);

	useEffect(() => {
		setData(searchProject(term));
		setProfileData(searchProfile(term));
	}, [term]);

	const onProjectClicked = (data) => {
		preventBodyScroll();
		setNowProject(data);
	};

	const onProfileClciked = (id) => {
		push(`/${nowYear}/profile/${id}`);
	};

	useEffect(() => {
		if (data && profileData) {
			if (!data.length && !profileData.length) {
				setNotFount(true);
			} else {
				setNotFount(false);
			}
		}
	}, [data, profileData]);

	return (
		<SearchPresentor
			term={term}
			data={data}
			profileData={profileData}
			onProjectClicked={onProjectClicked}
			onProfileClciked={onProfileClciked}
			nowProject={nowProject}
			setNowProject={setNowProject}
			notFound={notFount}
		/>
	);
});

export default SearchContainer;
