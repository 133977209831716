import React, { useContext, useState, useEffect } from "react";
import AdminLayout from "../Components/AdminLayout";
import AdminTitle from "../Components/AdminTitle";
import { AppContext, COL } from "../App";
import styled from "styled-components";
import AdminContainer from "Components/AdminContainer";
import { Link } from "react-router-dom";
import Ionicons from "../Assets/icon/Ionicons";
import { device } from "../Styles/Media";
import { fbDeleteData, fbDeleteStorage } from "../Firebase/firebase";
import Loading from "../Components/Loading";

const Exhibition = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

const ListExhibition = styled.li`
	position: relative;
	width: calc(50% - 8px);
	${(props) => props.theme.afterRatio};
	${(props) => props.theme.bgFull};
	border-radius: ${(props) => props.theme.input_borderRatio};
	background-color: lightgray;
	background-image: ${(props) => `url('${props.src}')`};
	transition: ${(props) => props.theme.transition_default};
	${(props) => props.theme.shadow.default};
	overflow: hidden;
	margin-bottom: 16px;
`;

const LinkList = styled(Link)`
	${(props) => props.theme.fullAbs};
	padding: 16px;
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50%;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
		background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
	}
`;
const Year = styled.p`
	font-size: 24px;
	font-weight: 700;
	color: white;
	border-bottom: 1px solid white;
	padding-bottom: 6px;
	position: relative;
	z-index: 5;
`;

const DeleteBtn = styled(Ionicons)`
	position: absolute;
	top: 16px;
	right: 16px;
	z-index: 5;
	opacity: 0.5;
	@media ${device.hover} {
		&:hover {
			opacity: 1;
		}
	}
`;

const Main = () => {
	const { data, setData } = useContext(AppContext);
	const [loading, setLoading] = useState(true);

	const handleDeleteClick = async (e, el) => {
		e.preventDefault();
		e.stopPropagation();
		if (!window.confirm("정말 삭제 하시겠습니까?")) return;
		fbDeleteStorage(`${el.graphic_landscape.prevFile}`);
		fbDeleteStorage(`${el.graphic_portrait.prevFile}`);
		fbDeleteData(COL, el.id);
		setData(data.filter((exhibition) => exhibition.id !== el.id));
	};

	useEffect(() => {
		if (data) {
			setLoading(false);
		}
	}, [data]);

	return (
		<AdminLayout>
			<Loading active={loading} />
			<AdminTitle title={`졸업전시`} href="/admin/form?type=add" iconName="add" />

			<AdminContainer>
				<Exhibition>
					{data &&
						data.map((el) => {
							return (
								<ListExhibition key={el.id} src={el.graphic_landscape.fileUrl}>
									<LinkList to={`/admin/form?type=edit&year=${el.year}`}>
										<Year>{el.year}</Year>
										<DeleteBtn name="delete" color="white" onClick={(e) => handleDeleteClick(e, el)} />
									</LinkList>
								</ListExhibition>
							);
						})}
				</Exhibition>
			</AdminContainer>
		</AdminLayout>
	);
};

export default Main;
