import React from "react";
import styled from "styled-components";
import AdminContainer from "./AdminContainer";
import { Link } from "react-router-dom";
import { device } from "../Styles/Media";
import { fbSignout } from "../Firebase/firebase";
import { useLocation } from "react-router-dom";

// STYLED
const Wrapper = styled.nav`
	border-bottom: 1px solid black;
	height: 64px;
	margin-bottom: 4rem;
`;

const CustomeAdminContainer = styled(AdminContainer)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
`;
const LogoWrapper = styled(Link)`
	display: flex;
	align-items: center;
`;

const Text = styled.p`
	font-size: 1.2rem;
	font-weight: 600;
`;

const LogoutBtn = styled.p`
	padding: 8px 8px;
	cursor: pointer;
	color: ${(props) => props.theme.color.input.active};
	border-radius: ${(props) => props.theme.input_borderRatio};

	@media ${device.hover} {
		&:hover {
			background-color: lightgray;
		}
	}
`;

const AdminHeader = () => {
	const location = useLocation();
	const handleLogoutClick = () => {
		fbSignout();
	};
	return (
		<Wrapper>
			<CustomeAdminContainer>
				<LogoWrapper to="/admin/main">
					<Text>KUID | ADMIN</Text>
				</LogoWrapper>
				{location.pathname !== "/admin/signin" && <LogoutBtn onClick={handleLogoutClick}>로그아웃</LogoutBtn>}
			</CustomeAdminContainer>
		</Wrapper>
	);
};

export default AdminHeader;
