import React from "react";
import AdminLayout from "Components/AdminLayout";
import AdminTitle from "Components/AdminTitle";
import styled from "styled-components";
import Submit from "Components/Form/Submit";
import AdminContainer from "Components/AdminContainer";
import AdminSection from "Components/AdminSection";
import InputText from "Components/Form/InputText";
import InputTextarea from "Components/Form/InputTextarea";
import InputFile from "Components/Form/InputFile";
import InputTag from "Components/Form/InputTag";
import LabList from "Components/LabList";
import InputNumber from "Components/Form/InputNumber";
import PopupLabList from "Components/PopupLabList";
import BtnAddLabList from "../../Components/BtnAddLabList";
import { sortByOrder } from "../../Utility";
import Loading from "../../Components/Loading";

const Form = styled.form``;

const AdminSectionLab = styled(AdminSection)`
	justify-content: space-between;
`;

const AdminAddPresentor = ({
	loading,
	form,
	fileLandscape,
	filePortrait,
	handleOnSubmit,
	handleTextChange,
	handleTagAdd,
	handleTagDelete,
	nowLab,
	handleLabAddClick,
	handleLabEditClick,
	handleLabCancle,
	handleLabDelete,
	handleLabTextChange,
	handleLabAddSubmit,
	handleLabEditSubmit,
	labSubmitType
}) => {
	return (
		<AdminLayout>
			<Loading active={loading} />

			<AdminTitle title={`졸업전시 상세`} />

			{nowLab && (
				<PopupLabList lab={nowLab} onCancleClick={handleLabCancle} onChange={handleLabTextChange} submitType={labSubmitType} onAddSubmit={handleLabAddSubmit} onEditSubmit={handleLabEditSubmit} />
			)}

			<AdminContainer>
				<Form onSubmit={handleOnSubmit}>
					<AdminSection title="졸업전시 년도_">
						<InputNumber label="년도" placeholder="숫자만 입력해주세요. ex) 2020" value={form.year} onChange={(e) => handleTextChange(e, "year")} />
					</AdminSection>

					<AdminSection title="그래픽_">
						<InputFile name="graphic_landscape" label="가로 버젼 - pc" nowFile={!!form.graphic_landscape && form.graphic_landscape.fileUrl} setFile={fileLandscape.setValue} id={"poster-landscape"} />
						<InputFile name="graphic_portrait" label="세로 버젼 - mobile" nowFile={!!form.graphic_portrait && form.graphic_portrait.fileUrl} setFile={filePortrait.setValue} id={"poster-portrait"} />
					</AdminSection>

					<AdminSection title="전시_">
						<InputText label="제목" placeholder="제목" value={form.title} onChange={(e) => handleTextChange(e, "title")} />
						<InputTextarea label="내용" placeholder="내용" value={form.text} onChange={(e) => handleTextChange(e, "text")} />
						<InputText label="링크" placeholder="http:// 또는 https:// 를 포함해주세요. " value={form.link} onChange={(e) => handleTextChange(e, "link")} />
					</AdminSection>

					<AdminSectionLab title="랩_">
						{form.lab.sort(sortByOrder).map((el, index) => {
							return <LabList key={index} lab={el} onClick={() => handleLabEditClick(el)} onDeleteClick={() => handleLabDelete(index)} />;
						})}
						<BtnAddLabList onClick={handleLabAddClick} />
					</AdminSectionLab>

					<AdminSection title="지도 교수_">
						<InputTag label="지도 교수" list={form.professor} onEnter={handleTagAdd} onDelete={handleTagDelete} />
					</AdminSection>

					<Submit value={"확인"} />
				</Form>
			</AdminContainer>
		</AdminLayout>
	);
};

export default AdminAddPresentor;
