import React from "react";
import InputWrapper from "./InputWrapper";
import styled from "styled-components";
import Ionicons from "../../Assets/icon/Ionicons";
import { useInput } from "../../Hooks/hooks";

const Wrapper = styled(InputWrapper)`
	width: 100%;
	input {
		${(props) => props.theme.input_default};

		border: none;
		outline: none;
		border-bottom: 1px solid ${(props) => props.theme.color.input.default};
		padding: 0.6rem 0.6rem;
		width: 100%;
		position: relative;
	}
`;

const InputTextWrapper = styled.div`
	position: relative;
`;
const TagList = styled.ul`
	display: flex;
	margin-top: 6px;
	flex-wrap: wrap;
`;

const Tag = styled.li`
	background-color: black;
	color: white;
	padding: 6px;
	padding-left: 12px;
	border-radius: ${(props) => props.theme.input_borderRatio};
	margin-right: 6px;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 6px;
`;
const TagText = styled.p`
	margin-right: 4px;
`;

const AddBtn = styled(Ionicons)`
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	cursor: pointer;
	opacity: 0;
`;

const Input = styled.input`
	&:focus {
		~ ${AddBtn} {
			opacity: 1;
		}
	}
`;

const InputTag = ({ label, list, onEnter, onDelete }) => {
	const text = useInput("");

	const handleInputEnter = (e) => {};

	const handleKeyUp = (e) => {
		if (e.target.value === ",") {
			text.setValue("");
			return;
		}
		var keyCode = e.which ? e.which : e.keyCode;
		if (keyCode === 188) {
			onEnter(text.value.split(",")[0]);
			text.setValue("");
		}
	};

	const handleTagDelete = (text) => {
		onDelete(text);
	};

	return (
		<Wrapper label={label}>
			<InputTextWrapper>
				<Input type="text" onKeyPress={handleInputEnter} onKeyUp={handleKeyUp} value={text.value} placeholder=", 키를 이용해 태그를 추가하세요. " onChange={text.onChange} />
				<AddBtn name="add" color="black" onClick={() => onEnter(text.value)} />
			</InputTextWrapper>
			<TagList>
				{list.map((el, index) => {
					return (
						<Tag key={index} onClick={() => handleTagDelete(el)}>
							<TagText>{el}</TagText>
							<Ionicons name="close" color="white" />
						</Tag>
					);
				})}
			</TagList>
		</Wrapper>
	);
};

export default InputTag;
