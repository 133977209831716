import React from "react";
import styled from "styled-components";
import img_loading from "Assets/image/loading.png";
const Wrapper = styled.div`
	position: fixed;
	z-index: ${(props) => props.theme.z_loading};
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: white;
	${(props) => props.theme.centerFlex};
	flex-direction: column;
	${(props) => (props.active ? props.theme.activeEvent : props.theme.preventEvent)};
	opacity: ${(props) => (props.active ? "1" : "0")};
	transition: ${(props) => props.theme.transition_default};
`;
const LoadingImage = styled.img`
	mix-blend-mode: multiply;
	transform: translateX(-5%);
`;
const LoadingText = styled.p`
	font-size: 25px;
	text-align: center;
	${(props) => props.theme.terminaLight};
	letter-spacing: 6px;
	margin-top: 36px;
`;
const Loading = ({ active }) => {
	return (
		<Wrapper active={active}>
			<LoadingImage src={img_loading} />
			<LoadingText>Loading</LoadingText>
		</Wrapper>
	);
};

export default Loading;
