import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import ProjectPresenter from "./ProjectPresenter";
import { getProjectByLab } from "../../Data";
import { preventBodyScroll, scrollToTop } from "../../Utility";

export default withRouter(
	({
		location: { search },
		match: {
			params: { year }
		}
	}) => {
		const { lab } = queryString.parse(search);
		const [projects, setProjects] = useState();
		const [nowProject, setNowProject] = useState();

		useEffect(() => {
			const mkData = () => {
				getProjectByLab(lab);
				setProjects(() => getProjectByLab(lab));
			};
			mkData();
		}, [lab]);

		useEffect(() => {
			scrollToTop();
		}, []);

		useEffect(() => {
			scrollToTop();
		}, [search]);

		const onProjectClicked = (data) => {
			preventBodyScroll();
			setNowProject(data);
		};

		return <ProjectPresenter data={projects} nowProject={nowProject} onProjectClicked={onProjectClicked} setNowProject={setNowProject} />;
	}
);
