import React from "react";
import styled from "styled-components";
import AdminContainer from "./AdminContainer";
import Ionicons from "Assets/icon/Ionicons";
import { Link } from "react-router-dom";

const Title = styled.h2`
	font-size: 1.5rem;
	font-weight: 700;
	&::after {
		content: "";
		display: block;
		width: 20px;
		height: 3px;
		background-color: #000;
		margin-top: 1.8rem;
		margin-bottom: 2rem;
	}
`;

const BtnWrapper = styled(Link)`
	float: right;
	cursor: pointer;
	border-radius: 100%;
	width: 40px;
	height: 40px;
	${(props) => props.theme.centerFlex};
	&:hover {
		background-color: lightgray;
	}
`;

const EditBtn = styled(Ionicons)``;
const AdminTitle = ({ title, href, iconName }) => {
	return (
		<AdminContainer>
			<Title>
				{title}
				{href && (
					<BtnWrapper to={href}>
						<EditBtn name={iconName} />
					</BtnWrapper>
				)}
			</Title>
		</AdminContainer>
	);
};

export default AdminTitle;
