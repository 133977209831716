import React from "react";
import styled from "styled-components";
import { device } from "../Styles/Media";

const TextWrapper = styled.div`
	${(props) => props.theme.centerFlex};
	flex-direction: column;
	text-align: center;
	${(props) => props.theme.notoKrBold};
	position: absolute;
	color: white;
	line-height: 2;
	font-size: 1.56rem;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(2px);
	transition: ${(props) => props.theme.transition_list};

	@media ${device.laptopL} {
		font-size: 14px;
	}

	.descript {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 90%;
	}
`;

const Thumbnail = styled.div`
	${(props) => props.theme.centerFlex};
	${(props) => props.theme.afterRatio};
	${(props) => props.theme.bgFull};
	background-image: ${(props) => `url('${props.src}')`};
	position: relative;
	&:after {
		width: 100%;
		padding-top: ${(props) => props.theme.ratio_thumbnail};
	}
	background-color: ${(props) => props.theme.colorPlacehoder};
	${TextWrapper} {
		opacity: 0;
	}

	${(props) =>
		props.hover &&
		`
  @media ${device.hover} {
		&:hover {
			${TextWrapper} {
				opacity: 1;
			}
		}
	}`};

	${(props) =>
		props.hover &&
		`	@media ${device.touch} {
		${TextWrapper} {
			opacity: 1;
		}
	}`};
`;

const Users = styled.p`
	width: 75%;
`;

export default ({ src, title, text, hover, className }) => (
	<Thumbnail src={src} hover={hover} className={className}>
		<TextWrapper>
			{title && <p className="descript">{title}</p>}
			{text && (
				<Users>
					{Array.isArray(text) ? (
						text.map((user, index) => {
							return <span key={index}>{user.name_kr} </span>;
						})
					) : (
						<span>{text.name_kr} </span>
					)}
				</Users>
			)}
		</TextWrapper>
	</Thumbnail>
);
