import React from "react";
import styled from "styled-components";
import AdminHeader from "./AdminHeader";

const Wrapper = styled.div`
	min-height: 100vh;
	background-color: #fff;
	position: relative;
	z-index: 200;
	padding-bottom: 5rem;
`;
const AdminLayout = ({ children }) => {
	return (
		<Wrapper>
			<AdminHeader />
			{children}
		</Wrapper>
	);
};

export default AdminLayout;
