import React, { useContext, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { device } from "../Styles/Media";
import { AppContext } from "../App";
import queryString from "query-string";
import { gnb } from "../Data";

const Gnb = styled.ul`
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;

	@media ${device.tablet} {
		flex-direction: column;
		padding: ${(props) => props.theme.offset_screen_tablet};
		padding-top: ${(props) => `calc(${props.theme.height_header_tablet} + ${props.theme.offset_screen_tablet})`};
		align-items: flex-start;
	}

	@media ${device.mobileL} {
		padding: ${(props) => props.theme.offset_screen_mobileL};
		padding-top: ${(props) => `calc(${props.theme.height_header_mobileL} + ${props.theme.offset_screen_mobileL})`};
	}
`;

const GnbList = styled.li`
	position: relative;
	${(props) => props.theme.terminaMedium}
	cursor: pointer;
	text-transform: capitalize;

	&::after {
		content: "";
		display: block;
		position: absolute;
		top: calc(50% - 3px);
		left: 0;
		width: 100%;
		background-color: black;
		height: 3px;
		text-transform: capitalize;
		flex-grow: 0;
		flex-shrink: 0;
	}

	@media (hover: hover) {
		&:hover {
			&:not(:last-child)::after {
				top: 100%;
			}
		}
	}

	&:last-child {
		${(props) => props.theme.terminaLight}
		&::after {
			display: none;
			width: 75%;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&.active {
		${(props) => props.theme.terminaBold};
		&::after {
			display: block;
			top: 100% !important;
		}
	}

	@media ${device.laptopL} {
		&:not(:last-child)::after {
			height: 2px;
			top: calc(50% - 1px);
		}
	}

	@media ${device.tablet} {
		color: white;
		font-size: 3rem;
		margin-bottom: 3rem;
		&:not(:last-child)::after {
			background-color: #fff;
		}
	}

	@media ${device.mobileL} {
		font-size: 2rem;
	}
`;

const GnbNavLink = styled(Link)`
	display: block;
	padding: 0 2rem;
	font-size: 1.5rem;

	@media ${device.laptopL} {
		font-size: 14px;
		padding: 0 1rem;
	}

	@media ${device.mobileL} {
		padding: ${(props) => `0 ${props.theme.offset_screen_mobileL}`};
	}
`;

export default withRouter((props) => {
	const { nowYear } = useContext(AppContext);
	const [nowLab, setNowLab] = useState();

	const parsed = queryString.parse(props.location.search);

	useEffect(() => {
		if (parsed.lab) {
			setNowLab(parsed.lab);
		} else {
			setNowLab(props.location.pathname.split("/")[2]);
		}
	}, [parsed, props]);

	return (
		<Gnb>
			{gnb.map((el) => {
				if (el.name === "profile") {
					return (
						<GnbList key={el.name} className={nowLab === el.name && "active"}>
							<GnbNavLink type={"light"} to={`/${nowYear}/profile`}>
								{el.name}
							</GnbNavLink>
						</GnbList>
					);
				} else {
					return (
						<GnbList key={el.name} className={nowLab === el.name && "active"}>
							<GnbNavLink to={`/${nowYear}/project?lab=${el.name}`}>{el.name}</GnbNavLink>
						</GnbList>
					);
				}
			})}
		</Gnb>
	);
});
