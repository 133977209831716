import React, { useState, useEffect, useContext } from "react";
import AdminAddPresentor from "./AdminAddPresentor";
import { useLocation, useHistory } from "react-router-dom";
import { fbUploadStorage, fbUploadData, fbUpdateData, fbDeleteStorage } from "../../Firebase/firebase";
import { useFile } from "Hooks/hooks";
import { AppContext } from "../../App";
import queryString from "query-string";

const COL = "exhibition";
const LABINIT = {
	id: 0,
	title: "",
	subtitle: "",
	address: "",
	order: ""
};

const FORMINIT = {
	graphic_portrait: null,
	graphic_landscape: null,
	link: "",
	title: "",
	text: "",
	year: "",
	lab: [],
	professor: []
};

const AdminAddContainer = () => {
	const [form, setForm] = useState(FORMINIT);
	const [isEdit, setIsEdt] = useState(false);
	const [loading, setLoading] = useState(true);
	const { data, setData } = useContext(AppContext);
	const filePortrait = useFile();
	const fileLandscape = useFile();
	const [nowLab, setNowLab] = useState(null);
	const [labSubmitType, setLabSubmitType] = useState();
	const location = useLocation();
	const { push } = useHistory();

	useEffect(() => {
		const parsed = queryString.parse(location.search);

		if (parsed.type === "edit") {
			setIsEdt(true);
			if (data) {
				const nowData = data.find((el) => el.year === +parsed.year);
				if (nowData === undefined) {
					push("/admin/main");
				}
				setForm(nowData);
				setLoading(false);
			}
		} else if (parsed.type === "add") {
			setIsEdt(false);
			setForm(FORMINIT);
			setLoading(false);
		}
	}, [location, data, push]);

	const handleOnSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		let id;
		if (!isEdit) {
			id = await fbUploadData(COL, form);
		} else {
			id = form.id;
		}

		let file_portrait = form.graphic_portrait;
		let file_landscape = form.graphic_landscape;

		if (filePortrait && filePortrait.value) {
			if (file_portrait && file_portrait.prevFile) {
				fbDeleteStorage(file_portrait.prevFile);
			}
			file_portrait = await fbUploadStorage(`${COL}/${id}/portrait`, filePortrait.value.name, filePortrait.value);
		}
		if (fileLandscape && fileLandscape.value) {
			if (file_landscape && file_landscape.prevFile) {
				fbDeleteStorage(file_landscape.prevFile);
			}
			file_landscape = await fbUploadStorage(`${COL}/${id}/landscape`, fileLandscape.value.name, fileLandscape.value);
		}
		const newForm = { ...form, graphic_portrait: file_portrait, graphic_landscape: file_landscape };

		await fbUpdateData(COL, id, newForm);

		completeSubmit({ ...newForm, id });
	};

	const completeSubmit = (newData) => {
		setLoading(false);
		if (!isEdit) {
			setData([newData, ...data]);
		} else {
			setData(data.map((el) => (el.id === newData.id ? newData : el)));
		}
		push("/admin/main");
		alert("완료");
	};

	const handleTextChange = (e, key) => {
		let value = e.target.value;
		if (e.target.type === "number") {
			value = +value;
		}

		setForm({ ...form, [key]: value });
	};

	const handleTagAdd = (text) => {
		if (!form.professor.includes(text)) {
			setForm({ ...form, professor: [...form.professor, text] });
		}
	};

	const handleTagDelete = (text) => {
		setForm({ ...form, professor: form.professor.filter((el) => el !== text) });
	};

	// LAB
	const handleLabAddClick = () => {
		setNowLab(LABINIT);
		setLabSubmitType("add");
	};
	const handleLabEditClick = (lab) => {
		setNowLab(lab);
		setLabSubmitType("edit");
	};
	const handleLabCancle = () => {
		setNowLab(null);
		setLabSubmitType(null);
	};
	const handleLabDelete = (i) => {
		setForm({ ...form, lab: form.lab.filter((_, index) => index !== i) });
	};
	const handleLabTextChange = (key, e) => {
		let value = e.target.value;
		if (e.target.type === "number") {
			value = +value;
		}

		setNowLab({ ...nowLab, [key]: value });
	};

	const handleLabAddSubmit = (lab) => {
		setForm({ ...form, lab: form.lab.concat({ ...lab, id: form.lab.length }) });
		setNowLab(null);
	};

	const handleLabEditSubmit = (lab) => {
		setForm({ ...form, lab: form.lab.map((el) => (el.id === lab.id ? lab : el)) });
		setNowLab(null);
	};

	return (
		<AdminAddPresentor
			loading={loading}
			handleOnSubmit={handleOnSubmit}
			handleTextChange={handleTextChange}
			handleTagAdd={handleTagAdd}
			handleTagDelete={handleTagDelete}
			form={form}
			filePortrait={filePortrait}
			fileLandscape={fileLandscape}
			nowLab={nowLab}
			handleLabAddClick={handleLabAddClick}
			handleLabEditClick={handleLabEditClick}
			handleLabCancle={handleLabCancle}
			handleLabDelete={handleLabDelete}
			handleLabTextChange={handleLabTextChange}
			handleLabAddSubmit={handleLabAddSubmit}
			handleLabEditSubmit={handleLabEditSubmit}
			labSubmitType={labSubmitType}
		/>
	);
};

export default AdminAddContainer;
