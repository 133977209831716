import React from "react";
import InputWrapper from "./InputWrapper";
import styled from "styled-components";

const Wrapper = styled(InputWrapper)`
	width: 100%;
	input {
		${(props) => props.theme.input_default};

		border: none;
		outline: none;
		border-bottom: 1px solid ${(props) => props.theme.color.input.default};
		padding: 0.6rem 0.6rem;
		width: 100%;
		position: relative;
	}
`;

const InputText = ({ label, value, placeholder, onChange }) => {
	return (
		<Wrapper label={label}>
			<input type="text" value={value} placeholder={placeholder} onChange={onChange} />
		</Wrapper>
	);
};

export default InputText;
